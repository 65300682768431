/**
 * @fileoverview gRPC-Web generated client stub for account
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.3
// source: src/grpc/iuxprotos/account.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.account = require('./account_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.account.AccountClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.account.AccountPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.account.AccountData>}
 */
const methodDescriptor_Account_GetAccount = new grpc.web.MethodDescriptor(
  '/account.Account/GetAccount',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.account.AccountData,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.AccountData.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.account.AccountData>}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.getAccount =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/account.Account/GetAccount',
      request,
      metadata || {},
      methodDescriptor_Account_GetAccount);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.account.AccountData>}
 *     The XHR Node Readable Stream
 */
proto.account.AccountPromiseClient.prototype.getAccount =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/account.Account/GetAccount',
      request,
      metadata || {},
      methodDescriptor_Account_GetAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.account.AccountData>}
 */
const methodDescriptor_Account_Account = new grpc.web.MethodDescriptor(
  '/account.Account/Account',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.account.AccountData,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.AccountData.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.AccountData)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.AccountData>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.account =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/Account',
      request,
      metadata || {},
      methodDescriptor_Account_Account,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.AccountData>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.account =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/Account',
      request,
      metadata || {},
      methodDescriptor_Account_Account);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.UserHistoryRequest,
 *   !proto.account.UserHistoryReply>}
 */
const methodDescriptor_Account_GetUserHistory = new grpc.web.MethodDescriptor(
  '/account.Account/GetUserHistory',
  grpc.web.MethodType.UNARY,
  proto.account.UserHistoryRequest,
  proto.account.UserHistoryReply,
  /**
   * @param {!proto.account.UserHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.UserHistoryReply.deserializeBinary
);


/**
 * @param {!proto.account.UserHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.UserHistoryReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.UserHistoryReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.getUserHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/GetUserHistory',
      request,
      metadata || {},
      methodDescriptor_Account_GetUserHistory,
      callback);
};


/**
 * @param {!proto.account.UserHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.UserHistoryReply>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.getUserHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/GetUserHistory',
      request,
      metadata || {},
      methodDescriptor_Account_GetUserHistory);
};


module.exports = proto.account;

