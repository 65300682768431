import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  account: {
    login: 0,
    currency_digits: 0,
    balance: 0,
    equity: 0,
    margin: 0,
    margin_free: 0,
    margin_level: 0,
    margin_leverage: 0,
    profit: 0
  },
  isDrawer: false,
  balance: 0
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount(state, action) {
      state.account = action.payload;
    },
    setDrawerAccount(state, action) {
      state.isDrawer = action.payload;
    }
  }
});

export const { setAccount, setDrawerAccount } = accountSlice.actions;

const accountReducer = accountSlice.reducer;
export default accountReducer;
