const HISTORY_TYPE_HISTORY = 'HISTORY_TYPE_HISTORY';
const HISTORY_TYPE_DEALLOG = 'HISTORY_TYPE_DEALLOG';
const HISTORY_TYPE_ORDER = 'HISTORY_TYPE_ORDER';

const initialState = {
  histories: [],
  deals: [],
  orders: []
};

export const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case HISTORY_TYPE_HISTORY: {
      return {
        ...state,
        histories: action.payload
      };
    }
    case HISTORY_TYPE_DEALLOG: {
      return {
        ...state,
        deals: action.payload
      };
    }
    case HISTORY_TYPE_ORDER: {
      return {
        ...state,
        orders: action.payload
      };
    }
    default:
      return state;
  }
};

export const setHistories = (payload) => ({
  type: HISTORY_TYPE_HISTORY,
  payload
});
export const setDeals = (payload) => ({
  type: HISTORY_TYPE_DEALLOG,
  payload
});
export const setOrders = (payload) => ({
  type: HISTORY_TYPE_ORDER,
  payload
});
