import React, { createContext, useEffect, useMemo, useState } from 'react';
import SubscribeOnTick from '../../data/onTickSubscribe.js';
import { useSelector } from 'react-redux';
import { matchSorter } from 'match-sorter';
import { createSelector } from '@reduxjs/toolkit';
import emitter from '../../utilities/emitter.jsx';
import { metadata } from '../../data/metadata.js';

const { SymbolName } = require('../../grpc/generated/symbol_pb.js');
const { TickRequest } = require('../../grpc/generated/symbol_pb.js');

export const DropDownContext = createContext({});
const getInstrumentState = createSelector(
  (state) => state,
  ({ symbol }) => symbol
);

const getSymbolActiveState = createSelector(
  (state) => state,
  ({ symbol }) => symbol
);

const DropDownProvider = ({ children }) => {
  const open = useSelector((state) => state.dropdown?.open ?? false);
  const [category, setCategory] = useState('Forex Major');
  const { instruments } = useSelector((state) => getInstrumentState(state));
  const { active } = useSelector((state) => getSymbolActiveState(state));
  const [checkSubscribe, setCheckSubscribe] = useState([]);
  const [filterInstruments, setFilterInstruments] = useState([]);
  const [symbolObj, setSymbolObj] = useState({});

  useEffect(() => {
    if (instruments) {
      setFilterInstruments(instruments);
    }
  }, [instruments, open]);

  useEffect(() => {
    if (filterInstruments.length > 0) {
      const isObj = filterInstruments.reduce(
        (a, b) => ({
          ...a,
          [b.type]: [...(a[b.type] ?? []), b]
        }),
        {}
      );
      setSymbolObj(isObj);
    }
  }, [filterInstruments]);

  const fuzzyTextFilterFn = (filterValue) => {
    return matchSorter(instruments, filterValue, { keys: ['symbol'] });
  };

  const handleSearch = (value) => {
    if (value === '') {
      setFilterInstruments(instruments);
    } else {
      setFilterInstruments(fuzzyTextFilterFn(value));
      if (fuzzyTextFilterFn(value) && fuzzyTextFilterFn(value)[0] !== undefined) {
        setCategory(fuzzyTextFilterFn(value)[0].type);
      }
    }
  };

  const handleSubScribe = async (cate) => {
    if (!checkSubscribe.includes(cate)) {
      setCheckSubscribe((prev) => [...prev, cate]);
      const request = new TickRequest();
      const requestTick = new SubscribeOnTick();
      if (symbolObj[cate]) {
        for (let index = 0; index < symbolObj[cate].length; index++) {
          const element = symbolObj[cate][index].symbol;
          const symbol = new SymbolName();
          symbol.setName(element);
          request.addSymbols(symbol);
        }
        try {
          await requestTick.subScribeBidAsk(request, metadata());
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const handleUnsubscribe = async () => {
    try {
      const request = new TickRequest();
      if (checkSubscribe.length > 0) {
        const sym = checkSubscribe.map((e) => symbolObj[e]);
        for (let index = 0; index < sym.length; index++) {
          for (let i = 0; i < sym[index].length; i++) {
            const symbol = new SymbolName();
            if (sym[index][i].symbol !== active) {
              const el = sym[index][i].symbol;
              symbol.setName(el);
              request.addSymbols(symbol);
            }
          }
        }
        const requestTick = new SubscribeOnTick();
        await requestTick.unSubScribeBidAsk(request, metadata());
        setCheckSubscribe([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!open && checkSubscribe.length > 0) {
      handleUnsubscribe();
    }
    if (open) {
      handleSubScribe('Forex Major');
    }
  }, [open]);

  return (
    <DropDownContext.Provider
      value={{
        handleUnsubscribe,
        handleSubScribe,
        handleSearch,
        symbolObj,
        category,
        setCategory
      }}>
      {children}
    </DropDownContext.Provider>
  );
};

export default DropDownProvider;
