import React, { createContext, useEffect, useReducer, useState } from 'react';
import { signInWithCustomToken, signInWithEmailAndPassword } from 'firebase/auth';
import axios from 'axios';
import { accountTradeApi, firebaseCustomApi, getMe } from '../api/account';
import { serverApiConfig } from '../config';
import { AuthClient } from '../grpc/generated/auth_grpc_web_pb';
import { TokenRequest } from '../grpc/generated/auth_pb';
import { Notification } from './alert';
import { auth, logoutFirebase } from '../firebase';
import ListArray from '../data/listArray';

let google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');

const initialState = {
  isAuthenticated: false,
  isInitialized: true,
  user: null,
  metatrader: [],
  activeAccount: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, metatrader, isInitialized, activeAccount } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized,
      user,
      metatrader,
      activeAccount
    };
  },
  LOGIN: (state, action) => {
    const { user, metatrader, isInitialized, activeAccount } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isInitialized,
      user,
      metatrader,
      activeAccount
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    metatrader: null,
    activeAccount: null
  }),
  REGISTER: (state, action) => {
    const { user, metatrader } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      metatrader
    };
  },
  AUTHTOKEN: (state, action) => {
    const { user, metatrader, isInitialized, activeAccount, setToken } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isInitialized,
      user,
      metatrader,
      activeAccount,
      setToken
    };
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  authWithToken: () => Promise.resolve(),
  getTokenFromUrl: () => Promise.resolve(),
  getFirebaseCustom: () => Promise.resolve()
});

function AuthProvider({ children }) {
  const request = new google_protobuf_empty_pb.Empty();
  const lisArray = new ListArray();
  const tokenRequest = new TokenRequest();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [index, setIndex] = useState(0);

  const getFirebaseCustom = async () => {
    let token = null;
    try {
      const { data } = await firebaseCustomApi();
      token = data.data.token;
      return token;
    } catch (error) {
      return;
    }
  };

  const initialize = async (token) => {
    try {
      const tk = localStorage.getItem('trade.token') || window.electron?.store.get('trade.token');
      const server =
        localStorage.getItem('trade.server') || window.electron?.store.get('trade.server');
      const acc =
        localStorage.getItem('iuxmarkets.active.account') ||
        window.electron?.store.get('iuxmarkets.active.account');
      const { data } = await accountTradeApi();
      if (!server) {
        localStorage.setItem('trade.server', data.data[0].api_server);
        window.electron?.store.set('trade.server', data.data[0].api_server);
      }
      if (!acc) {
        localStorage.setItem('iuxmarkets.active.account', data.data[0].id);
        window.electron?.store.set('iuxmarkets.active.account', data.data[0].id);
      }
      if (tk) {
        const me = await getMe();
        await lisArray.getListArray(request, { Authorization: 'Bearer ' + tk });
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            isInitialized: false,
            metatrader: data.data,
            activeAccount: acc,
            user: me.data
          }
        });
      } else {
        let time;
        const working = data.data?.sort((a, b) => b.current_balance - a.current_balance) ?? [];
        if (working.length > 0) {
          const authClient = new AuthClient(
            serverApiConfig(working[index]?.api_server),
            null,
            null
          );
          tokenRequest.setMainToken(token);
          tokenRequest.setAccountTradeId(working[index].id);
          await authClient.getToken(tokenRequest, {}, (err, response_) => {
            setIndex((prev) => prev + 1);
            if (err) {
              if (working.length > index) {
                time = setTimeout(() => {
                  setIndex((prev) => prev + 1);
                  return () => {
                    clearTimeout(time);
                  };
                }, 1000);
              } else {
                Notification({
                  message: err.message,
                  type: 'danger'
                });
                dispatch({
                  type: 'INITIALIZE',
                  payload: {
                    isAuthenticated: false,
                    isInitialized: false,
                    metatrader: null,
                    activeAccount: null
                  }
                });
                logout();
                return;
              }
            }
            if (window.electron) {
              window.electron?.store.set('trade.server', working[index].api_server);
              window.electron?.store.set('iuxmarkets.active.account', working[index].id);
              window.electron?.store.set('trade.token', response_.array[0]);
              dispatch({
                type: 'INITIALIZE',
                payload: {
                  isAuthenticated: true,
                  isInitialized: false,
                  metatrader: data.data,
                  activeAccount: working[index].id
                }
              });
            } else {
              lisArray.getListArray(request, { Authorization: 'Bearer ' + response_.array[0] });
              localStorage.setItem('trade.token', response_.array[0]);
              localStorage.setItem('trade.server', working[index].api_server);
              localStorage.setItem('iuxmarkets.active.account', working[index].id);
              dispatch({
                type: 'INITIALIZE',
                payload: {
                  isAuthenticated: true,
                  isInitialized: false,
                  metatrader: data.data,
                  activeAccount: working[index].id
                }
              });
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              isInitialized: false,
              metatrader: [],
              activeAccount: null
            }
          });
        }
      }
    } catch (error) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          isInitialized: false,
          metatrader: null,
          activeAccount: null
        }
      });
    }
  };

  const authWithToken = async ({ token, loading }) => {
    loading(true);
    await signInWithCustomToken(auth, token)
      .then(async (userCredential) => {})
      .catch((error) => {
        console.error('Error signing in on domain B:', error);
        loading(false);
      });
  };

  const login = async ({ username, password, setLoading }) => {
    await signInWithEmailAndPassword(auth, username, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        const token = await user.getIdToken();
        window.electron?.store.set('token', token);
        setLoading(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        switch (errorCode) {
          case 'auth/user-not-found':
            Notification({
              message: 'Incorrect email or password. Please check your credentials and try again.',
              type: 'danger'
            });
            break;
          case 'auth/wrong-password':
            Notification({
              message: 'Incorrect email or password. Please check your credentials and try again.',
              type: 'danger'
            });
            break;
          case 'auth/invalid-email':
            Notification({
              message: 'Incorrect email or password. Please check your credentials and try again.',
              type: 'danger'
            });
            break;
          default:
            return Notification({
              message: 'Something went wrong',
              type: 'danger'
            });
        }
        setLoading(false);
      });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('', {
      email,
      password,
      firstName,
      lastName
    });
    const { user, metatrader } = response.data;

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
        metatrader
      }
    });
  };

  const logout = async () => {
    await logoutFirebase();
    localStorage.removeItem('trade.token');
    dispatch({ type: 'LOGOUT' });
    if (window.electron) {
      window.electron?.store.delete('token');
    }
  };

  useEffect(() => {
    // console.log(index)
    auth.onAuthStateChanged(async (user) => {
      if (user || window.electron?.store.get('token')) {
        await initialize(await user.getIdToken());
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            isInitialized: false,
            metatrader: [],
            activeAccount: null
          }
        });
      }
    });
  }, [index]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        authWithToken,
        getFirebaseCustom
      }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
