import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  instruments: [],
  instrumentInfo: {},
  categories: [],
  active: null,
  menu: []
};

const setStorage = (obj) => {
  localStorage.setItem('symbol_menu_list', JSON.stringify(obj));
};

const accountId =
  localStorage.getItem('iuxmarkets.active.account') ||
  window?.electron?.store.get('iuxmarkets.active.account');

const instrumentSlice = createSlice({
  name: 'instrument',
  initialState,
  reducers: {
    setInstrument(state, action) {
      state.instruments = action.payload;
    },
    setSymbolInfo(state, action) {
      state.instrumentInfo = state.instruments.find((e) => e.symbol === state.active);
    },
    setCategories(state, action) {
      state.categories = action.payload;
    },
    setSymbolActive(state, action) {
      const temp = {
        ...state.menu,
        [accountId]: {
          ...state.menu[accountId],
          active: action.payload
        }
      };
      setStorage(temp);
      state.active = action.payload;
      state.menu = temp;
    },
    setMenuSymbol(state, action) {
      const { type, data, active } = action.payload;
      if (type === 'new') {
        setStorage({
          ...state.menu,
          [accountId]: {
            menu: [...state.menu[accountId].menu, { symbol: data.symbol, logo: data.logo }],
            active: data.symbol
          }
        });
        state.menu = {
          ...state.menu,
          [accountId]: {
            menu: [...state.menu[accountId].menu, { symbol: data.symbol, logo: data.logo }],
            active: data.symbol
          }
        };
      }
      if (type === 'del') {
        const del = state.menu[accountId].menu.filter((e) => e.symbol !== data.symbol);
        setStorage({
          ...state.menu,
          [accountId]: {
            active: active,
            menu: del
          }
        });
        state.menu = {
          ...state.menu,
          [accountId]: { menu: del, active: active }
        };
        state.active = active;
      }
      if (type === 'first' || type === 'old') {
        setStorage(data);
        state.menu = data;
        state.active = data[action.payload.data.accountId]?.active;
      }
    }
  }
});

export const { setInstrument, setCategories, setSymbolActive, setMenuSymbol, setSymbolInfo } =
  instrumentSlice.actions;

const instrumentReducer = instrumentSlice.reducer;
export default instrumentReducer;
