import { getAccountTradeId } from '../config';

export const getDataFromLocalstorage = () => {
  const fromStorage = localStorage.getItem('symbols.trading.data');
  let data = JSON.parse(fromStorage) ?? [];
  return data;
};

export const getMenuFromLocalstorage = () => {
  try {
    let data = {};
    const fromStorage =
      localStorage.getItem('symbol_menu_list') || window.electron?.store.get('symbol_menu_list');
    if (!fromStorage || JSON.parse(fromStorage)[getAccountTradeId()].menu.length <= 0) {
      const dataJson = {
        [getAccountTradeId()]: {
          menu: [],
          active: ''
        }
      };
      localStorage.setItem('symbol_menu_list', JSON.stringify(dataJson));
      window.electron?.store.set('symbol_menu_list', JSON.stringify(dataJson));
      return dataJson;
    } else {
      const { menu, active } = JSON.parse(fromStorage)[getAccountTradeId()];
      data = { menu, active };
      return data;
    }
  } catch (e) {
    console.log(e);
  }
};
