import React, { memo, useContext } from 'react'
import List from './list'
import { DropDownContext } from './provider'
import { Each } from '../../utilities/Each'

const DropDownItem = memo(() => {
  const { handleSearch, symbolObj, category } = useContext(DropDownContext)

  return (
    <div className="h-[600px]">
      <div className="relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          fill="#fff"
          className="bi bi-search absolute-svg-search"
          viewBox="0 0 16 16"
        >
          <path
            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
        </svg>
        <input className="search-input" onChange={({ target: { value } }) => handleSearch(value)} />
      </div>
      <div className="flex items-center" style={{ padding: '14px 4px 4px 4px' }}>
        <div className="flex items-center w-100">
          <div className="text-xs font-bold" style={{ flex: '1', color: '#5b5b5b' }}>
            Symbol
          </div>
          <div className="text-xs font-bold" style={{ flex: '0.4', color: '#5b5b5b' }}>
            Open Price
          </div>
          <div className="text-xs font-bold" style={{ flex: '0.4', color: '#5b5b5b' }}>
            Change / Day.
          </div>
        </div>
      </div>
      <div id="overflow-auto" className={`w-100 overflow-auto h-[510px]`}>
        <Each
          of={symbolObj[category]}
          render={(item, i) => (
            <div
              key={i}
              style={{
                display: symbolObj[category] ? 'block' : 'none',
              }}
            >
              <List item={item} index={i} active={symbolObj[category]} />
            </div>
          )}
        />
      </div>
    </div>
  )
})

DropDownItem.displayName = 'DropDownItem'
export default DropDownItem
