import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../redux/app';
import { createSelector } from '@reduxjs/toolkit';

const makeAccountBalanceSelector = () =>
  createSelector(
    (state) => state.account?.account,
    (_) => null,
    (account) => {
      return account?.balance ?? 0;
    }
  );

const ProfitChange = memo(() => {
  const getAccountBalance = useMemo(makeAccountBalanceSelector, []);
  const balance = useAppSelector((state) => getAccountBalance(state));
  const { positions } = useAppSelector(({ positions }) => positions);
  const [equity, setEquity] = useState(0);

  useEffect(() => {
    let position = [];
    if (positions) {
      positions.forEach((element) => {
        const isPosition = {
          position: element[0],
          symbol: element[1],
          action: element[2] ?? 0,
          digits: element[3],
          digitsCurrency: element[4],
          contractSize: element[5],
          timeCreate: element[6],
          timeUpdate: element[7],
          priceOpen: element[8],
          priceCurrent: element[9],
          volume: element[12],
          profit: element[13] ?? 0,
          reason: element[14]
        };
        position.push(isPosition);
      });
      let eq = position.reduce((a, b) => a + b.profit, 0);
      setEquity(eq);
    }
  }, [positions]);

  const checkColor = (x) => {
    if (x === 0) return 'text-[#ffff]';
    else if (x > 0) return 'text-[#1F6FDE]';
    else if (x < 0) return 'text-[#BD5B44]';
    else return 'text-[#ffff]';
  };

  const getProfitPercent = (e, b) => {
    if (e && b) {
      return ((e / b) * 100).toFixed(2)
    }
    return '0.00'
  }
  
  return (
    <div>
      <div className="text-smalll">Profit</div>
      <div className={`text-profit-right ${checkColor((equity / balance) * 100)}`}>
        <span>{getProfitPercent(equity, balance) > 0 ? '+' : ''}</span>
        {isNaN((getProfitPercent(equity, balance))) ? 0.0 : getProfitPercent(equity, balance)}
        <span className={`${checkColor((equity / balance) * 100)}`}>{` `}%</span>
      </div>
      <div className={`text-profit-dollar ${checkColor((equity / balance) * 100)}`}>
        {equity.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        })}
      </div>
    </div>
  );
});

ProfitChange.displayName = 'ProfitChange';

export default ProfitChange;
