import { serverApiConfig } from '../config';
import emitter from '../utilities/emitter.jsx';
const { SymbolClient } = require('../grpc/generated/symbol_grpc_web_pb.js');
const { AccountClient } = require('../grpc/generated/account_grpc_web_pb');

export default class AccountSteam {
  stream = null;
  async subscribeAccount(request, metadata) {
    try {
      let accountClient = new AccountClient(
        serverApiConfig(localStorage.getItem('trade.server')),
        null,
        null
      );
      this.stream = accountClient.getAccount(request, metadata);
      this.stream.on('data', function (response) {
        let accountInfo = {
          login: response.array[0],
          currency_digits: response.array[1],
          balance: response.array[2],
          credit: response.array[3],
          equity: response.array[4],
          margin: response.array[5],
          margin_free: response.array[6],
          margin_level: response.array[7],
          margin_leverage: response.array[8],
          profit: response.array[9]
        };
        emitter.instance.emit('accountInfo', accountInfo);
      });
      this.stream.on('error', function (err) {
        this.stream?.cancel();
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      });
      this.stream.on('status', function (status) {});
      this.stream.on('end', function () {});
    } catch (e) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }
  async accountInfo(request, metadata) {
    try {
      let accountClient = new AccountClient(
        serverApiConfig(localStorage.getItem('trade.server')),
        null,
        null
      );
      this.call = accountClient.account(request, metadata, (err, response) => {
        let accountInfoData = {
          login: response.array[0],
          currency_digits: response.array[1],
          balance: response.array[2],
          credit: response.array[3],
          equity: response.array[4],
          margin: response.array[5],
          margin_free: response.array[6],
          margin_level: response.array[7],
          margin_leverage: response.array[8],
          profit: response.array[9]
        };
        emitter.instance.emit('accountInfo', accountInfoData);
      });
    } catch (e) {
      console.log(e);
    }
  }
}
