import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { Login } from '../components/Login';
import AuthGuard from '../guards/AuthGuard';
import Dashboard from '../components/Dashboard';
import GuestGuard from '../guards/GuestGuard';
import Register from '../components/Register';

export default function Router() {
  return useRoutes([
    {
      path: 'login',
      children: [
        {
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
          index: true
        },
        {
          path: ':token',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
      ]
    },
    {
      path: 'register',
      children: [
        {
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
          index: true
        }
      ]
    },
    {
      path: 'trade-panel',
      element: (
        <AuthGuard>
          <Dashboard />
        </AuthGuard>
      )
    },
    { path: '*', element: <Navigate to="/login" replace /> }
  ]);
}
