import { useEffect, useMemo, useState } from 'react'
import { makePositionNoReduceSelector } from '../../redux/selector'
import { useAppSelector } from '../../redux/app'
import { addWorkerMessageListener, initializeWorker, postMessageToWorker } from '../serviceWorker'

// eslint-disable-next-line no-undef

const WorkerController = () => {
  const getPositionState = useMemo(makePositionNoReduceSelector, [])
  const positions = useAppSelector((state) => getPositionState(state))

  const [workerData, setData] = useState([])
  
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const worker = initializeWorker();
    // Listen for messages from the worker
    addWorkerMessageListener((event) => {
      const { type, data } = event.data;
      if (type === 'position') {
        setData(data);
      }
    });

    postMessageToWorker({ type: 'position', payload: positions });
    // return () => worker.terminate();
  }, [positions])

  return { data: workerData }
}

export default WorkerController