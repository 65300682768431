import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Register = () => {
  const { t, i18n } = useTranslation();
  const [eye, setEye] = useState(false);
  return (
    <div className="w-full max-w-[450px] h-auto bg-[#2F2F2F] rounded-xl flex m-auto relative">
      <div className="px-10 py-10 w-full">
        <div className="flex flex-col items-center mb-4">
          <div className="w-[65px] h-[65px] mb-4">
            <a
              href="#"
              onClick={(_) => {
                const auth = {
                  username: 'worawuth',
                  password: 'AAbb12345'
                };
                window.electron?.writeFile.setFile(JSON.stringify(auth));
                // window.electron.store.set('foo', 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImFlYzU4NjcwNGNhOTZiZDcwMzZiMmYwZDI4MGY5NDlmM2E5NzZkMzgiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQWxleCBUdW5uZXIiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vaXV4bWFya2V0cyIsImF1ZCI6Iml1eG1hcmtldHMiLCJhdXRoX3RpbWUiOjE3MDg0MDcxNTMsInVzZXJfaWQiOiI4Y2QxYTJkNS04N2I2LTQ4YjItYTNjNi1iZTEwMWYzMTYxYzEiLCJzdWIiOiI4Y2QxYTJkNS04N2I2LTQ4YjItYTNjNi1iZTEwMWYzMTYxYzEiLCJpYXQiOjE3MDg0MDcxNTMsImV4cCI6MTcwODQxMDc1MywiZW1haWwiOiJ0aGFuYWRvbmpAa2t1bWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJ0aGFuYWRvbmpAa2t1bWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.QBI0qb6Oq0YML8ILKCK_jHFylwQp5MTxkXbP4rbybZ2S0cfGdBl1hgUrlvVS2Ph5hDRXPyCwBOLOTz-UNyK98LoOv2v4xuaRACzqb-I0GfgaXQ0ipbR8yLxgWt_tzNWKXjBiBaLOqTVOJq_yxM5xcTemm3b9G8WNT5aUru17OPTNAPZkENnUNH3bfb-eCzx28cEGWCQm9KkJokWYNUeT-2mzSQL20NEi5rwXbYUasdrUnJeBwOGMLlI5BmFZKLGrx-dImRnxwlU8-mGz5St1_ll5jGeIS4qIixiPDqQKsQbPXa6zHzFj-3iJ8salnEODUJqLp_Ax4-j2PmNNvoxtGQ');
                // console.log(window.electron.store.get('foo'));
              }}>
              <img
                className="rounded-lg"
                src="/images/iuxtrade/IUXTrade-icon.png"
                width="88px"
                height="auto"
              />
            </a>
          </div>
          <div className="font-bold text-center text-white">{t('register.title')}</div>
        </div>
        <label className="text-xs text-[#8d8d8d] mb-1 pl-1">{t('register.country')}</label>
        <div className="rounded-lg h-[40px] flex items-center gap-2 w-full px-4 mb-4 text-sm outline-none bg-transparent border border-[#696969] text-white">
          <img className="h-[16px]" src="/logo/Japan.png" alt="..." />
          <div>Japan</div>
        </div>
        <label className="text-xs text-[#8d8d8d] mb-1 pl-1">{t('login.email')}</label>
        <input
          type="text"
          placeholder={t('register.email')}
          className="rounded-lg h-[40px] w-full px-2 mb-4 text-sm outline-none bg-transparent border border-[#696969] text-white"
        />
        <label className="text-xs text-[#8d8d8d] mb-1 pl-1">{t('login.password')}</label>
        <div className="relative">
          <input
            type={eye ? 'text' : 'password'}
            placeholder={t('login.password')}
            className="rounded-lg h-[40px] w-full px-4 mb-6 text-sm outline-none bg-transparent border border-[#696969] text-white"
          />
          {!eye ? (
            <svg
              onClick={() => setEye(!eye)}
              width="20"
              height="20"
              className="absolute cursor-pointer right-0 top-0 -translate-x-[50%] translate-y-[50%]"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                {' '}
                <path
                  d="M14.83 9.17999C14.2706 8.61995 13.5576 8.23846 12.7813 8.08386C12.0049 7.92926 11.2002 8.00851 10.4689 8.31152C9.73758 8.61453 9.11264 9.12769 8.67316 9.78607C8.23367 10.4444 7.99938 11.2184 8 12.01C7.99916 13.0663 8.41619 14.08 9.16004 14.83"
                  stroke="#696969"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"></path>{' '}
                <path
                  d="M12 16.01C13.0609 16.01 14.0783 15.5886 14.8284 14.8384C15.5786 14.0883 16 13.0709 16 12.01"
                  stroke="#696969"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"></path>{' '}
                <path
                  d="M17.61 6.39004L6.38 17.62C4.6208 15.9966 3.14099 14.0944 2 11.99C6.71 3.76002 12.44 1.89004 17.61 6.39004Z"
                  stroke="#696969"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"></path>{' '}
                <path
                  d="M20.9994 3L17.6094 6.39"
                  stroke="#696969"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"></path>{' '}
                <path
                  d="M6.38 17.62L3 21"
                  stroke="#696969"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"></path>{' '}
                <path
                  d="M19.5695 8.42999C20.4801 9.55186 21.2931 10.7496 21.9995 12.01C17.9995 19.01 13.2695 21.4 8.76953 19.23"
                  stroke="#696969"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"></path>{' '}
              </g>
            </svg>
          ) : (
            <svg
              onClick={() => setEye(!eye)}
              className="absolute cursor-pointer right-0 top-0 -translate-x-[50%] translate-y-[50%]"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                {' '}
                <path
                  d="M12 16.01C14.2091 16.01 16 14.2191 16 12.01C16 9.80087 14.2091 8.01001 12 8.01001C9.79086 8.01001 8 9.80087 8 12.01C8 14.2191 9.79086 16.01 12 16.01Z"
                  stroke="#696969"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"></path>{' '}
                <path
                  d="M2 11.98C8.09 1.31996 15.91 1.32996 22 11.98"
                  stroke="#696969"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"></path>{' '}
                <path
                  d="M22 12.01C15.91 22.67 8.09 22.66 2 12.01"
                  stroke="#696969"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"></path>{' '}
              </g>
            </svg>
          )}
        </div>
        <div className="flex justify-center mb-6 gap-2">
          <div className="checkbox-wrapper-4">
            <input className="inp-cbx" id="morning" type="checkbox" />
            <label className="cbx" htmlFor="morning">
              <span>
                <svg width="12px" height="10px">
                  <use xlinkHref="#check-4"></use>
                </svg>
              </span>
            </label>
            <svg className="inline-svg">
              <symbol id="check-4" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
              </symbol>
            </svg>
          </div>
          <div className="text-white text-sm">
            <Trans
              t={t}
              i18nKey="register.term"
              components={{
                bi: <a href="" className="text-[#1CB894]" />
              }}
            />
            {/*You agree to the IUX <span className="text-[#1CB894]">Terms and policies</span>*/}
          </div>
        </div>
        <button className="h-[35px] rounded-md w-full bg-[#1cb894] text-white mb-6 disabled:bg-[#444444]">
          {t('register.submit')}
        </button>
        <div className="flex justify-between items-center">
          <div className="text-sm text-white">{t('register.hasAccount')}</div>
          <Link to="/login" className="text-[#1CB894] text-sm font-bold">
            {t('register.login')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Register;
