const APP_LOADING_TYPE = 'APP_LOADING_TYPE';

const initialState = false;

export const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOADING_TYPE: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const setAppLoading = (payload) => ({
  type: APP_LOADING_TYPE,
  payload
});
