import React, { useEffect, useRef, useState } from 'react';
import useAuth from '../hooks/useAuth';
import GoToApp from './GoToApp';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

export const origin = {
  'http://localhost:3000': 'https://staging.iux.com',
  'https://tradedev.iux.com': 'https://staging.iux.com',
  'https://trading.iux.com': 'https://portal.iux.com',
  'https://trading.iuxvn.com': 'https://portal.iuxvn.com',
  'https://trading.id-iux.com': 'https://portal.id-iux.com'
};

export const Login = ({ isMobile, noAcc }) => {
  const { t, i18n } = useTranslation();
  let { token = '' } = useParams();
  const buttonRef = useRef();
  const [eye, setEye] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { login, authWithToken, getFirebaseCustom, logout } = useAuth();
  const [payload, setPayload] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);

  const onLogin = async () => {
    try {
      setButtonLoading(true);
      await login({
        username: payload.email,
        password: payload.password,
        setLoading: setButtonLoading
      });
    } catch (e) {
      const { message, code } = Error(e);
    }
  };

  const onChange = (value, key) => {
    setPayload((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const Base64Decoder = (textToEncode) => {
    const decoded = atob(textToEncode);
    return decoded;
  };

  useEffect(() => {
    if (token) {
      authWithToken({ token: token, loading: setLoading });
    }
  }, [token, setLoading]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
  };

  if (!isMobile) {
    return (
      <div className="w-full flex mb-10">
        {!noAcc ? (
          <div className="w-full max-w-[450px] h-auto bg-[#2F2F2F] rounded-xl flex m-auto relative">
            <div className="px-10 py-10">
              <div className="flex flex-col items-center mb-4">
                <div className="w-[65px] h-[65px] mb-4">
                  <a
                    href="#"
                    onClick={(_) => {
                      const auth = {
                        username: 'worawuth',
                        password: 'AAbb12345'
                      };
                      window.electron?.writeFile.setFile(JSON.stringify(auth));
                      // window.electron.store.set('foo', 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImFlYzU4NjcwNGNhOTZiZDcwMzZiMmYwZDI4MGY5NDlmM2E5NzZkMzgiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQWxleCBUdW5uZXIiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vaXV4bWFya2V0cyIsImF1ZCI6Iml1eG1hcmtldHMiLCJhdXRoX3RpbWUiOjE3MDg0MDcxNTMsInVzZXJfaWQiOiI4Y2QxYTJkNS04N2I2LTQ4YjItYTNjNi1iZTEwMWYzMTYxYzEiLCJzdWIiOiI4Y2QxYTJkNS04N2I2LTQ4YjItYTNjNi1iZTEwMWYzMTYxYzEiLCJpYXQiOjE3MDg0MDcxNTMsImV4cCI6MTcwODQxMDc1MywiZW1haWwiOiJ0aGFuYWRvbmpAa2t1bWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJ0aGFuYWRvbmpAa2t1bWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.QBI0qb6Oq0YML8ILKCK_jHFylwQp5MTxkXbP4rbybZ2S0cfGdBl1hgUrlvVS2Ph5hDRXPyCwBOLOTz-UNyK98LoOv2v4xuaRACzqb-I0GfgaXQ0ipbR8yLxgWt_tzNWKXjBiBaLOqTVOJq_yxM5xcTemm3b9G8WNT5aUru17OPTNAPZkENnUNH3bfb-eCzx28cEGWCQm9KkJokWYNUeT-2mzSQL20NEi5rwXbYUasdrUnJeBwOGMLlI5BmFZKLGrx-dImRnxwlU8-mGz5St1_ll5jGeIS4qIixiPDqQKsQbPXa6zHzFj-3iJ8salnEODUJqLp_Ax4-j2PmNNvoxtGQ');
                      // console.log(window.electron.store.get('foo'));
                    }}>
                    <img
                      className="rounded-lg"
                      src="/images/iuxtrade/IUXTrade-icon.png"
                      width="88px"
                      height="auto"
                    />
                  </a>
                </div>
                <div className="font-bold text-center text-white">{t('login.title')}</div>
              </div>
              <div className="flex justify-between mb-4">
                <div className="text-white text-xs">{t('login.subtitle')}</div>
                <div className="text-[#169376] font-bold text-xs">
                  <Link to="/register" relative="path">
                    {t('login.register')}
                  </Link>
                </div>
              </div>
              <label className="text-xs text-[#8d8d8d] mb-1 pl-1">{t('login.email')}</label>
              <input
                type="text"
                placeholder={t('login.email')}
                className="rounded-lg h-[40px] w-full px-2 mb-4 text-sm outline-none bg-transparent border border-[#696969] text-white"
                onChange={({ target: { value } }) => onChange(value, Object.keys(payload)[0])}
                onKeyPress={(e) => {
                  if (e.code === 'Enter') {
                    buttonRef.current.click();
                  }
                }}
              />
              <label className="text-xs text-[#8d8d8d] mb-1 pl-1">{t('login.password')}</label>
              <div className="relative">
                <input
                  type={eye ? 'text' : 'password'}
                  placeholder={t('login.password')}
                  className="rounded-lg h-[40px] w-full px-2 mb-5 text-sm outline-none bg-transparent border border-[#696969] text-white"
                  onChange={({ target: { value } }) => onChange(value, Object.keys(payload)[1])}
                  onKeyPress={(e) => {
                    if (e.code === 'Enter') {
                      buttonRef.current.click();
                    }
                  }}
                />
                {!eye ? (
                  <svg
                    onClick={() => setEye(!eye)}
                    width="20"
                    height="20"
                    className="absolute cursor-pointer right-0 top-0 -translate-x-[50%] translate-y-[50%]"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      {' '}
                      <path
                        d="M14.83 9.17999C14.2706 8.61995 13.5576 8.23846 12.7813 8.08386C12.0049 7.92926 11.2002 8.00851 10.4689 8.31152C9.73758 8.61453 9.11264 9.12769 8.67316 9.78607C8.23367 10.4444 7.99938 11.2184 8 12.01C7.99916 13.0663 8.41619 14.08 9.16004 14.83"
                        stroke="#696969"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"></path>{' '}
                      <path
                        d="M12 16.01C13.0609 16.01 14.0783 15.5886 14.8284 14.8384C15.5786 14.0883 16 13.0709 16 12.01"
                        stroke="#696969"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"></path>{' '}
                      <path
                        d="M17.61 6.39004L6.38 17.62C4.6208 15.9966 3.14099 14.0944 2 11.99C6.71 3.76002 12.44 1.89004 17.61 6.39004Z"
                        stroke="#696969"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"></path>{' '}
                      <path
                        d="M20.9994 3L17.6094 6.39"
                        stroke="#696969"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"></path>{' '}
                      <path
                        d="M6.38 17.62L3 21"
                        stroke="#696969"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"></path>{' '}
                      <path
                        d="M19.5695 8.42999C20.4801 9.55186 21.2931 10.7496 21.9995 12.01C17.9995 19.01 13.2695 21.4 8.76953 19.23"
                        stroke="#696969"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"></path>{' '}
                    </g>
                  </svg>
                ) : (
                  <svg
                    onClick={() => setEye(!eye)}
                    className="absolute cursor-pointer right-0 top-0 -translate-x-[50%] translate-y-[50%]"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      {' '}
                      <path
                        d="M12 16.01C14.2091 16.01 16 14.2191 16 12.01C16 9.80087 14.2091 8.01001 12 8.01001C9.79086 8.01001 8 9.80087 8 12.01C8 14.2191 9.79086 16.01 12 16.01Z"
                        stroke="#696969"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"></path>{' '}
                      <path
                        d="M2 11.98C8.09 1.31996 15.91 1.32996 22 11.98"
                        stroke="#696969"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"></path>{' '}
                      <path
                        d="M22 12.01C15.91 22.67 8.09 22.66 2 12.01"
                        stroke="#696969"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"></path>{' '}
                    </g>
                  </svg>
                )}
              </div>
              <div className="flex justify-between mb-8">
                <div></div>
                <div className="text-[#169376] font-bold text-xs">
                  <Link to="/forgot-password" relative="path">
                    {t('login.forgotPassword')}
                  </Link>
                </div>
              </div>
              <button
                ref={buttonRef}
                className="h-[35px] rounded-md w-full bg-[#1cb894] text-white mb-5 disabled:bg-[#444444]"
                onClick={() => onLogin()}
                disabled={buttonLoading}>
                {t('login.login')}
              </button>
              <p className="text-[#8d8d8d] text-center leading-4">{t('login.desc')}</p>
            </div>
          </div>
        ) : (
          <div className="m-auto min-h-[400px] flex flex-col">
            <div className="m-auto">
              <img className="m-auto" src="/images/none-account.svg" />
              <p className="text-center my-4">You don’t have any accounts</p>
              <div className="text-center">
                <a
                  onClick={() => {
                    // getFirebaseCustom().then((token) => {
                    //   window.location.href = `${
                    //     origin[window.location.origin]
                    //   }/en/wallet/account/${token}`;
                    // });
                  }}
                  href={`#`}>
                  <button className="transition-all bg-[#1cb894] hover:bg-[#098a6c] rounded-lg border-none text-white px-3 py-2 font-[600] uppercase text-sm">
                    Go to Dashbaord
                  </button>
                </a>
                <div className="mt-4">
                  <a
                    href="#"
                    onClick={() => logout()}
                    className="text-[#1cb894] text-sm hover:underline cursor-pointer">
                    Login another account
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return <GoToApp />;
  }
};
