// source: src/grpc/iuxprotos/position.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.position.OrderData', null, global);
goog.exportSymbol('proto.position.PositionData', null, global);
goog.exportSymbol('proto.position.PositionReply', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.position.PositionReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.position.PositionReply.repeatedFields_, null);
};
goog.inherits(proto.position.PositionReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.position.PositionReply.displayName = 'proto.position.PositionReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.position.PositionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.position.PositionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.position.PositionData.displayName = 'proto.position.PositionData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.position.OrderData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.position.OrderData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.position.OrderData.displayName = 'proto.position.OrderData';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.position.PositionReply.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.position.PositionReply.prototype.toObject = function(opt_includeInstance) {
  return proto.position.PositionReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.position.PositionReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.position.PositionReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionsList: jspb.Message.toObjectList(msg.getPositionsList(),
    proto.position.PositionData.toObject, includeInstance),
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto.position.OrderData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.position.PositionReply}
 */
proto.position.PositionReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.position.PositionReply;
  return proto.position.PositionReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.position.PositionReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.position.PositionReply}
 */
proto.position.PositionReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.position.PositionData;
      reader.readMessage(value,proto.position.PositionData.deserializeBinaryFromReader);
      msg.addPositions(value);
      break;
    case 2:
      var value = new proto.position.OrderData;
      reader.readMessage(value,proto.position.OrderData.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.position.PositionReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.position.PositionReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.position.PositionReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.position.PositionReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.position.PositionData.serializeBinaryToWriter
    );
  }
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.position.OrderData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PositionData positions = 1;
 * @return {!Array<!proto.position.PositionData>}
 */
proto.position.PositionReply.prototype.getPositionsList = function() {
  return /** @type{!Array<!proto.position.PositionData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.position.PositionData, 1));
};


/**
 * @param {!Array<!proto.position.PositionData>} value
 * @return {!proto.position.PositionReply} returns this
*/
proto.position.PositionReply.prototype.setPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.position.PositionData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.position.PositionData}
 */
proto.position.PositionReply.prototype.addPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.position.PositionData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.position.PositionReply} returns this
 */
proto.position.PositionReply.prototype.clearPositionsList = function() {
  return this.setPositionsList([]);
};


/**
 * repeated OrderData orders = 2;
 * @return {!Array<!proto.position.OrderData>}
 */
proto.position.PositionReply.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.position.OrderData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.position.OrderData, 2));
};


/**
 * @param {!Array<!proto.position.OrderData>} value
 * @return {!proto.position.PositionReply} returns this
*/
proto.position.PositionReply.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.position.OrderData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.position.OrderData}
 */
proto.position.PositionReply.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.position.OrderData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.position.PositionReply} returns this
 */
proto.position.PositionReply.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.position.PositionData.prototype.toObject = function(opt_includeInstance) {
  return proto.position.PositionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.position.PositionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.position.PositionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: jspb.Message.getFieldWithDefault(msg, 1, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 2, ""),
    action: jspb.Message.getFieldWithDefault(msg, 3, 0),
    digits: jspb.Message.getFieldWithDefault(msg, 4, 0),
    digitsCurrency: jspb.Message.getFieldWithDefault(msg, 5, 0),
    contractSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    timeCreate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    timeUpdate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    priceOpen: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    priceCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    priceSl: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    priceTp: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    volume: jspb.Message.getFieldWithDefault(msg, 13, 0),
    profit: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    reason: jspb.Message.getFieldWithDefault(msg, 15, 0),
    swap: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.position.PositionData}
 */
proto.position.PositionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.position.PositionData;
  return proto.position.PositionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.position.PositionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.position.PositionData}
 */
proto.position.PositionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPosition(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAction(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDigits(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDigitsCurrency(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setContractSize(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeCreate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeUpdate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceOpen(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceCurrent(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceSl(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceTp(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolume(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfit(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReason(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSwap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.position.PositionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.position.PositionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.position.PositionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.position.PositionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAction();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getDigits();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getDigitsCurrency();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getContractSize();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTimeCreate();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getTimeUpdate();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getPriceOpen();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getPriceCurrent();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getPriceSl();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getPriceTp();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getVolume();
  if (f !== 0) {
    writer.writeUint64(
      13,
      f
    );
  }
  f = message.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getReason();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getSwap();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
};


/**
 * optional uint64 position = 1;
 * @return {number}
 */
proto.position.PositionData.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.PositionData} returns this
 */
proto.position.PositionData.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string symbol = 2;
 * @return {string}
 */
proto.position.PositionData.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.position.PositionData} returns this
 */
proto.position.PositionData.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 action = 3;
 * @return {number}
 */
proto.position.PositionData.prototype.getAction = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.PositionData} returns this
 */
proto.position.PositionData.prototype.setAction = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 digits = 4;
 * @return {number}
 */
proto.position.PositionData.prototype.getDigits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.PositionData} returns this
 */
proto.position.PositionData.prototype.setDigits = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 digits_currency = 5;
 * @return {number}
 */
proto.position.PositionData.prototype.getDigitsCurrency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.PositionData} returns this
 */
proto.position.PositionData.prototype.setDigitsCurrency = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double contract_size = 6;
 * @return {number}
 */
proto.position.PositionData.prototype.getContractSize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.position.PositionData} returns this
 */
proto.position.PositionData.prototype.setContractSize = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional int64 time_create = 7;
 * @return {number}
 */
proto.position.PositionData.prototype.getTimeCreate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.PositionData} returns this
 */
proto.position.PositionData.prototype.setTimeCreate = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 time_update = 8;
 * @return {number}
 */
proto.position.PositionData.prototype.getTimeUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.PositionData} returns this
 */
proto.position.PositionData.prototype.setTimeUpdate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional double price_open = 9;
 * @return {number}
 */
proto.position.PositionData.prototype.getPriceOpen = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.position.PositionData} returns this
 */
proto.position.PositionData.prototype.setPriceOpen = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double price_current = 10;
 * @return {number}
 */
proto.position.PositionData.prototype.getPriceCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.position.PositionData} returns this
 */
proto.position.PositionData.prototype.setPriceCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double price_sl = 11;
 * @return {number}
 */
proto.position.PositionData.prototype.getPriceSl = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.position.PositionData} returns this
 */
proto.position.PositionData.prototype.setPriceSl = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double price_tp = 12;
 * @return {number}
 */
proto.position.PositionData.prototype.getPriceTp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.position.PositionData} returns this
 */
proto.position.PositionData.prototype.setPriceTp = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional uint64 volume = 13;
 * @return {number}
 */
proto.position.PositionData.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.PositionData} returns this
 */
proto.position.PositionData.prototype.setVolume = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional double profit = 14;
 * @return {number}
 */
proto.position.PositionData.prototype.getProfit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.position.PositionData} returns this
 */
proto.position.PositionData.prototype.setProfit = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional uint32 reason = 15;
 * @return {number}
 */
proto.position.PositionData.prototype.getReason = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.PositionData} returns this
 */
proto.position.PositionData.prototype.setReason = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional double swap = 16;
 * @return {number}
 */
proto.position.PositionData.prototype.getSwap = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.position.PositionData} returns this
 */
proto.position.PositionData.prototype.setSwap = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.position.OrderData.prototype.toObject = function(opt_includeInstance) {
  return proto.position.OrderData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.position.OrderData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.position.OrderData.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0),
    login: jspb.Message.getFieldWithDefault(msg, 3, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 4, ""),
    digits: jspb.Message.getFieldWithDefault(msg, 5, 0),
    digisCurrency: jspb.Message.getFieldWithDefault(msg, 31, 0),
    contractSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    state: jspb.Message.getFieldWithDefault(msg, 7, 0),
    reason: jspb.Message.getFieldWithDefault(msg, 8, 0),
    timeSetup: jspb.Message.getFieldWithDefault(msg, 9, 0),
    timeSetupMsc: jspb.Message.getFieldWithDefault(msg, 10, 0),
    timeExpiration: jspb.Message.getFieldWithDefault(msg, 11, 0),
    timeDone: jspb.Message.getFieldWithDefault(msg, 12, 0),
    timeDoneMsc: jspb.Message.getFieldWithDefault(msg, 13, 0),
    type: jspb.Message.getFieldWithDefault(msg, 14, 0),
    typeFill: jspb.Message.getFieldWithDefault(msg, 15, 0),
    typeTime: jspb.Message.getFieldWithDefault(msg, 16, 0),
    priceOrder: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    priceTrigger: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    priceCurrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    priceSl: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    priceTp: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    volumeInitial: jspb.Message.getFieldWithDefault(msg, 22, 0),
    volumeInitialExt: jspb.Message.getFieldWithDefault(msg, 23, 0),
    volumeCurrent: jspb.Message.getFieldWithDefault(msg, 24, 0),
    volumeCurrentExt: jspb.Message.getFieldWithDefault(msg, 25, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 26, ""),
    activationMode: jspb.Message.getFieldWithDefault(msg, 27, 0),
    activationTime: jspb.Message.getFieldWithDefault(msg, 28, 0),
    activationPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
    activationFlags: jspb.Message.getFieldWithDefault(msg, 30, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.position.OrderData}
 */
proto.position.OrderData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.position.OrderData;
  return proto.position.OrderData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.position.OrderData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.position.OrderData}
 */
proto.position.OrderData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPositionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLogin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDigits(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDigisCurrency(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setContractSize(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setState(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReason(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeSetup(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeSetupMsc(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeExpiration(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeDone(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeDoneMsc(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setType(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTypeFill(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTypeTime(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceOrder(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceTrigger(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceCurrent(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceSl(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceTp(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeInitial(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeInitialExt(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeCurrent(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeCurrentExt(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivationMode(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActivationTime(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActivationPrice(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivationFlags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.position.OrderData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.position.OrderData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.position.OrderData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.position.OrderData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getLogin();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDigits();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getDigisCurrency();
  if (f !== 0) {
    writer.writeUint32(
      31,
      f
    );
  }
  f = message.getContractSize();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getState();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getReason();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getTimeSetup();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getTimeSetupMsc();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getTimeExpiration();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getTimeDone();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getTimeDoneMsc();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getTypeFill();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getTypeTime();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getPriceOrder();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getPriceTrigger();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getPriceCurrent();
  if (f !== 0.0) {
    writer.writeDouble(
      19,
      f
    );
  }
  f = message.getPriceSl();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
  f = message.getPriceTp();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getVolumeInitial();
  if (f !== 0) {
    writer.writeUint64(
      22,
      f
    );
  }
  f = message.getVolumeInitialExt();
  if (f !== 0) {
    writer.writeUint64(
      23,
      f
    );
  }
  f = message.getVolumeCurrent();
  if (f !== 0) {
    writer.writeUint64(
      24,
      f
    );
  }
  f = message.getVolumeCurrentExt();
  if (f !== 0) {
    writer.writeUint64(
      25,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getActivationMode();
  if (f !== 0) {
    writer.writeUint32(
      27,
      f
    );
  }
  f = message.getActivationTime();
  if (f !== 0) {
    writer.writeInt64(
      28,
      f
    );
  }
  f = message.getActivationPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
  f = message.getActivationFlags();
  if (f !== 0) {
    writer.writeUint32(
      30,
      f
    );
  }
};


/**
 * optional uint64 position_id = 1;
 * @return {number}
 */
proto.position.OrderData.prototype.getPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 order = 2;
 * @return {number}
 */
proto.position.OrderData.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 login = 3;
 * @return {number}
 */
proto.position.OrderData.prototype.getLogin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setLogin = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string symbol = 4;
 * @return {string}
 */
proto.position.OrderData.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 digits = 5;
 * @return {number}
 */
proto.position.OrderData.prototype.getDigits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setDigits = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 digis_currency = 31;
 * @return {number}
 */
proto.position.OrderData.prototype.getDigisCurrency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setDigisCurrency = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};


/**
 * optional double contract_size = 6;
 * @return {number}
 */
proto.position.OrderData.prototype.getContractSize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setContractSize = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional uint32 state = 7;
 * @return {number}
 */
proto.position.OrderData.prototype.getState = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setState = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 reason = 8;
 * @return {number}
 */
proto.position.OrderData.prototype.getReason = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setReason = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 time_setup = 9;
 * @return {number}
 */
proto.position.OrderData.prototype.getTimeSetup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setTimeSetup = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 time_setup_msc = 10;
 * @return {number}
 */
proto.position.OrderData.prototype.getTimeSetupMsc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setTimeSetupMsc = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 time_expiration = 11;
 * @return {number}
 */
proto.position.OrderData.prototype.getTimeExpiration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setTimeExpiration = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 time_done = 12;
 * @return {number}
 */
proto.position.OrderData.prototype.getTimeDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setTimeDone = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 time_done_msc = 13;
 * @return {number}
 */
proto.position.OrderData.prototype.getTimeDoneMsc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setTimeDoneMsc = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint32 type = 14;
 * @return {number}
 */
proto.position.OrderData.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint32 type_fill = 15;
 * @return {number}
 */
proto.position.OrderData.prototype.getTypeFill = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setTypeFill = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint32 type_time = 16;
 * @return {number}
 */
proto.position.OrderData.prototype.getTypeTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setTypeTime = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional double price_order = 17;
 * @return {number}
 */
proto.position.OrderData.prototype.getPriceOrder = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setPriceOrder = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double price_trigger = 18;
 * @return {number}
 */
proto.position.OrderData.prototype.getPriceTrigger = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setPriceTrigger = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional double price_current = 19;
 * @return {number}
 */
proto.position.OrderData.prototype.getPriceCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setPriceCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional double price_sl = 20;
 * @return {number}
 */
proto.position.OrderData.prototype.getPriceSl = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setPriceSl = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional double price_tp = 21;
 * @return {number}
 */
proto.position.OrderData.prototype.getPriceTp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setPriceTp = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional uint64 volume_initial = 22;
 * @return {number}
 */
proto.position.OrderData.prototype.getVolumeInitial = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setVolumeInitial = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional uint64 volume_initial_ext = 23;
 * @return {number}
 */
proto.position.OrderData.prototype.getVolumeInitialExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setVolumeInitialExt = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional uint64 volume_current = 24;
 * @return {number}
 */
proto.position.OrderData.prototype.getVolumeCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setVolumeCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional uint64 volume_current_ext = 25;
 * @return {number}
 */
proto.position.OrderData.prototype.getVolumeCurrentExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setVolumeCurrentExt = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional string comment = 26;
 * @return {string}
 */
proto.position.OrderData.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional uint32 activation_mode = 27;
 * @return {number}
 */
proto.position.OrderData.prototype.getActivationMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setActivationMode = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional int64 activation_time = 28;
 * @return {number}
 */
proto.position.OrderData.prototype.getActivationTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setActivationTime = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional double activation_price = 29;
 * @return {number}
 */
proto.position.OrderData.prototype.getActivationPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setActivationPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional uint32 activation_flags = 30;
 * @return {number}
 */
proto.position.OrderData.prototype.getActivationFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.position.OrderData} returns this
 */
proto.position.OrderData.prototype.setActivationFlags = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


goog.object.extend(exports, proto.position);
