import React, { memo, useContext } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/app'
import { setPayload } from '../../redux/reducer/payload'
import StopLossAndTakeProfit from './StopLossAndTakeProfit'
import BuySellButton from './ActionButton'
import { TradeContext } from './TradeContextProvider'

export const typeExec = {
  0: 'Buy',
  1: 'Sell',
  2: 'Buy Limit',
  3: 'Sell Limit',
  4: 'Buy Stop',
  5: 'Sell Stop',
  6: 'Buy Stop Limit',
  7: 'Sell Stop Limit',
}

const TradeFunction = memo(({ lotRef }) => {
  const payload = useAppSelector((state) => state.payload?.payload)
  const dispatch = useAppDispatch()
  const { instrumentInfo, lotSize, handleLotSize, inputLotSize, validateLotSize } =
    useContext(TradeContext)

  return (
    <div>
      <div className="flex justify-between mt-[10px] px-[12px]">
        <div className="text-white text-sm">Volume</div>
        <div className="text-white text-sm">
          {(+lotSize * instrumentInfo?.contract_size).toFixed(2)} {instrumentInfo?.currency_margin}
        </div>
      </div>
      <div className="px-[12px]">
        <div className="box-input-lotsize mt-2">
          <div>
            <button className="dec" onClick={() => handleLotSize('inc')}>
              -
            </button>
          </div>
          <input
            onChange={({ target: { value } }) => inputLotSize(value)}
            onBlur={({ target: { value } }) => validateLotSize(value)}
            type="number"
            value={lotSize.toLocaleString()}
            ref={lotRef}
            step={(instrumentInfo?.volume_min / 10000).toString()}
            className="input-left-lotsize m-[10px]"
          />
          <div>
            <button className="inc" onClick={() => handleLotSize('dec')}>
              +
            </button>
          </div>
        </div>
        <StopLossAndTakeProfit />
      </div>
      <div className="mt-4 px-[12px]">
        <div className="text-sm text-white">Fill Policy</div>
        <div className="mt-2">
          <div className="flex w-100 justify-between">
            <div className="flex items-center">
              <input
                className="custom-radio"
                checked={payload?.type_fill === 0}
                onChange={({ target: { value } }) => {
                  dispatch(setPayload({ ...payload, type_fill: 0 }))
                }}
                type="checkbox"
                value={0}
              />
              <div>
                <p>Fill or Kill</p>
                <p>(FOK)</p>
              </div>
            </div>
            <div className="flex items-center">
              <input
                className="custom-radio"
                checked={payload?.type_fill === 1}
                onChange={({ target: { value } }) => {
                  dispatch(setPayload({ ...payload, type_fill: 1 }))
                }}
                type="checkbox"
                value={1}
              />
              <div>
                <p>
                  Immediate <br />
                  or Cancel
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BuySellButton typeBuy={0} />
    </div>
  )
})

TradeFunction.displayName = 'TradeFunction'

export default TradeFunction
