import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tick: [],
  stat: []
};

const statSlice = createSlice({
  name: 'stat',
  initialState,
  reducers: {
    setStat(state, action) {
      state.stat = action.payload.stat
      state.tick = action.payload.tick
    }
  }
});

export const { setStat } = statSlice.actions;

const statReducer = statSlice.reducer;
export default statReducer;
