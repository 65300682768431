import React, { useState } from 'react'
import BuySellButton from '../ActionButton'
import LotSizeController from '../../../utilities/LotSizeController'
import StopLossAndTakeProfit from '../StopLossAndTakeProfit'
import InputPrice from '../InputPrice'
import InputTriggerPrice from '../InputTriggerPrice'
import ExpirationDate from '../ExpirationDate'
import { useAppDispatch, useAppSelector } from '../../../redux/app'
import { setPayload } from '../../../redux/reducer/payload'
import Expiration from '../Expiration'

const BuyStopLimitFunction = ({ isBuyStopLimit }) => {
  const [error, setError] = useState(false)
  const payload = useAppSelector((state) => state.payload?.payload)

  return (
    <div>
      <LotSizeController setError={setError} />
      <div className="flex justify-between mt-4 px-[12px]">
        <div className="text-white text-sm">Price</div>
        <div></div>
      </div>
      <InputPrice validate={isBuyStopLimit ? 6 : 7} className="mx-[10px] mt-2" />
      <div className="flex justify-between mt-4 px-[12px]">
        <div className="text-sm text-white">Stop Limit Price</div>
        <div></div>
      </div>
      <InputTriggerPrice validate={isBuyStopLimit ? 6 : 7} className="mx-[10px] mt-2" />
      <div className="px-[12px]">
        <StopLossAndTakeProfit />
      </div>
      <div className="flex justify-between mt-4 px-[12px]">
        <div className="text-white text-sm">Expiration</div>
        <div></div>
      </div>
      <div className="m-[10px]">
        <Expiration />
      </div>
      {payload.type_time >= 2 ? (
        <ExpirationDate inputClass="mt-2" />
      ) : null}
      <BuySellButton typeBuy={isBuyStopLimit ? 6 : 7} />
    </div>
  )
}

export default BuyStopLimitFunction
