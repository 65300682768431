const SET_SESSION_TYPE = 'SET_SESSION_TYPE';

const initialState = null;

export const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION_TYPE: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const setSession = (payload) => ({
  type: SET_SESSION_TYPE,
  payload
});
