import React, { createContext } from 'react';
import SubscribeOnTick from '../data/onTickSubscribe.js';
import { metadata } from '../data/metadata.js';
const { SymbolName } = require('../grpc/generated/symbol_pb.js');
export const SubSettingContext = createContext('');

const SubscribeSettingProvider = ({ children }) => {
  const subscribe = new SubscribeOnTick();

  const onSubscribe = (symbol) => {
    const newRequest = new SymbolName();
    newRequest.setName(symbol);
    subscribe.singleSubScribe(newRequest, metadata(), (err, response_) => {
      if (response_ == null) {
        return err;
      }
    });
  };

  const unSubscribe = (symbol) => {
    const newRequest = new SymbolName();
    newRequest.setName(symbol);
    subscribe.singleUnSubScribe(newRequest, metadata(), (err, response_) => {
      if (response_ == null) {
        console.log(err);
        return err;
      }
    });
  };

  return (
    <SubSettingContext.Provider value={{ onSubscribe, unSubscribe }}>
      {children}
    </SubSettingContext.Provider>
  );
};

export default SubscribeSettingProvider;
