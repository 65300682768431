import { mapActionType, mapColor } from './actions';

let orderList = [];
let orderLine = [];

function drawOrderLine(price, tvWidget, digit, action) {
  let order = `${mapActionType(action)} ${price.toFixed(digit)}`;
  return tvWidget.activeChart().createShape(
    { price: price },
    {
      shape: 'horizontal_line',
      disableSelection: true,
      disableSave: true,
      disableUndo: true,
      showInObjectsTree: false,
      text: order,
      zOrder: 'top',
      lock: true,
      overrides: {
        showLabel: true,
        showTime: false,
        linestyle: 0,
        linecolor: mapColor(action),
        horzLabelsAlign: 'left',
        vertLabelsAlign: 'top',
        textcolor: mapColor(action),
        showPrice: true
      }
    }
  );
}

export function CreateOrdersLine(orders, tvWidget) {
  try {
    let activeSymbol = tvWidget.activeChart().symbolExt()?.symbol;
    let previousSymbol = activeSymbol;
    let symbolExt = tvWidget.activeChart().symbolExt();

    if (symbolExt != null) {
      activeSymbol = symbolExt['description'];
    }

    let result = orders
      .filter((item) => item[13] !== 0 && item[13] !== 1)
      .filter((obj) => {
        return obj[3] === activeSymbol;
      });

    if (activeSymbol !== previousSymbol) {
      previousSymbol = activeSymbol;
      if (result.length > 0) {
        for (let i = 0; i < result.length; i++) {
          let newPositionResult = orderList.filter((obj) => {
            return obj.id === result[i][1];
          });

          if (newPositionResult.length == 0) {
            const order = {
              id: result[i][1] ?? 0,
              order: result[i][2] ?? 0,
              symbol: result[i][3] ?? '',
              digits: result[i][4] ?? 0,
              digisCurrency: result[i][30] ?? 0,
              contractSize: result[i][5] ?? 0,
              type: result[i][13],
              priceOrder: result[i][16],
              priceTrigger: result[i][17] ?? 0,
              priceCurrent: result[i][18],
              priceSL: result[i][19] ?? 0,
              priceTP: result[i][20] ?? 0,
              volumeInitial: result[i][21] ?? 0,
              volumeCurrent: result[i][23],
              comment: result[i][25] ?? 'No Comment'
            };
            orderList.push(order);
          }
        }
      }
    } else {
      if (orderList.length > 0) {
        if (result.length !== orderList.length) {
          if (result.length > orderList.length) {
            let order = {};
            for (let i = 0; i < result.length; i++) {
              let newOrderResult = orderList.filter((obj) => {
                return obj.id === result[i][1];
              });
              if (newOrderResult.length == 0) {
                order = {
                  id: result[i][1] ?? 0,
                  order: result[i][2] ?? 0,
                  symbol: result[i][3] ?? '',
                  digits: result[i][4] ?? 0,
                  digisCurrency: result[i][30] ?? 0,
                  contractSize: result[i][5] ?? 0,
                  type: result[i][13],
                  priceOrder: result[i][16],
                  priceTrigger: result[i][17] ?? 0,
                  priceCurrent: result[i][18],
                  priceSL: result[i][19] ?? 0,
                  priceTP: result[i][20] ?? 0,
                  volumeInitial: result[i][21] ?? 0,
                  volumeCurrent: result[i][23],
                  comment: result[i][25] ?? 'No Comment'
                };
                orderList.push(order);
              }
            }
          } else if (result.length < orderList.length) {
            for (let i = 0; i < orderList.length; i++) {
              let positionResult = result.filter((obj) => {
                return obj[1] === orderList[i].id;
              });
              if (positionResult.length === 0) {
                const indexOfLine = orderLine.findIndex((object) => {
                  return object.id === orderList[i].id;
                });
                if (indexOfLine !== -1) {
                  let orderLineData = orderLine[indexOfLine].orderLineChart;
                  tvWidget.activeChart().removeEntity(orderLineData);
                }
              }
            }
          }
        } else {
          for (let i = 0; i < result.length; i++) {
            let ord = null;
            if (orderList[i].priceOrder !== result[i][16]) {
              const indexOfLine = orderLine.findIndex((object) => {
                return object.id === orderList[i].id;
              });
              if (orderLine.length > 0) {
                let orderLData = orderLine[indexOfLine]?.orderLineChart;
                tvWidget.activeChart().removeEntity(orderLData);
                if (result[i][16] != undefined) {
                  if (result[i][13] !== 0 && result[i][13] !== 1) {
                    ord = drawOrderLine(result[i][16], tvWidget, result[i][4], result[i][13]);
                  }
                }
                orderList[i].priceOrder = result[i][16];
                orderLine[indexOfLine].orderLineChart = ord;
              }
            }
          }
        }
      } else {
        if (result.length > 0) {
          let order = {};
          for (let i = 0; i < result.length; i++) {
            order = {
              id: result[i][1] ?? 0,
              order: result[i][2] ?? 0,
              symbol: result[i][3] ?? '',
              digits: result[i][4] ?? 0,
              digisCurrency: result[i][30] ?? 0,
              contractSize: result[i][5] ?? 0,
              type: result[i][13],
              priceOrder: result[i][16],
              priceTrigger: result[i][17] ?? 0,
              priceCurrent: result[i][18],
              priceSL: result[i][19] ?? 0,
              priceTP: result[i][20] ?? 0,
              volumeInitial: result[i][21] ?? 0,
              volumeCurrent: result[i][23],
              comment: result[i][25] ?? 'No Comment'
            };
            orderList.push(order);
          }
        }
      }

      if (orderList.length > 0) {
        let ord = null;
        for (let i = 0; i < orderList.length; i++) {
          let result = orderLine.filter((obj) => {
            return obj.id === orderList[i].id;
          });
          if (result.length === 0) {
            try {
              if (orderList[i].priceOrder != undefined) {
                if (orderList[i].type !== 0 && orderList[i].type !== 1) {
                  ord = drawOrderLine(
                    orderList[i].priceOrder,
                    tvWidget,
                    orderList[i].digits,
                    orderList[i].type
                  );
                }
              }
              let data = {
                id: orderList[i].id,
                volume: orderList[i].volumeCurrent,
                orderLineChart: ord
              };
              orderLine.push(data);
            } catch (e) {
              console.log(e);
            }
          }
        }
      }
    }
  } catch (error) {
    return;
  }
}
