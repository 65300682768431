import { setPayload } from '../redux/reducer/payload'

const UseStopLossTakeProfit = () => {
  const handlePrice = (type, price, isTp, digit, dispatch, payload, bid) => {
    const digitOfSymbol = `${(0).toFixed(digit).substring(0, (0).toFixed(digit).length - 1)}1`
    switch (price) {
      case 0 < Number:
        return dispatch(
          setPayload({
            ...payload,
            [isTp]: bid.toFixed(digit),
          }),
        )
      default:
        switch (type) {
          case 'dec':
            return dispatch(
              setPayload({
                ...payload,
                [isTp]: (parseFloat(price) - parseFloat(digitOfSymbol)).toFixed(digit),
              }),
            )
          case 'inc':
            return dispatch(
              setPayload({
                ...payload,
                [isTp]: (parseFloat(price) + parseFloat(digitOfSymbol)).toFixed(digit),
              }),
            )
        }
    }
  }

  return { handlePrice }
}

export default UseStopLossTakeProfit