import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './reducer/login';
import { loadingReducer } from './reducer/AppLoading';
import { historyReducer } from './reducer/history';
import { switchReducer } from './reducer/switch';
import { sessionReducer } from './reducer/session';
import instrumentReducer from './reducer/instruments';
import accountReducer from './reducer/account';
import bidaskReducer from './reducer/bidask';
import dialogReducer from './reducer/dialog';
import dropdownReducer from './reducer/dropdown';
import positionReducer from './reducer/positions';
import editDialogReducer from './reducer/edit-dialog';
import payloadReducer from './reducer/payload';
import statReducer from './reducer/stat';
import tvWidgetReducer from './reducer/tvWidget';
import orderReducer from './reducer/orders';
import accountTradeReducer from './reducer/account-trade';
// import { instrumentReducer } from './reducer/instruments';

export default configureStore({
  reducer: {
    symbol: instrumentReducer,
    positions: positionReducer,
    orders: orderReducer,
    auth: authReducer,
    account: accountReducer,
    tradeDialog: dialogReducer,
    bidask: bidaskReducer,
    appLoading: loadingReducer,
    histories: historyReducer,
    tvWidget: tvWidgetReducer,
    isSwitch: switchReducer,
    editDialog: editDialogReducer,
    stat: statReducer,
    sesstion: sessionReducer,
    dropdown: dropdownReducer,
    payload: payloadReducer,
    accountTrade: accountTradeReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ immutableCheck: false })
});
