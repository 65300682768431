const SET_SWITCH_TYPE = 'SET_SWITCH_TYPE';

const initialState = null;

export const switchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SWITCH_TYPE: {
      return {
        ...state,
        switch: action.payload
      };
    }
    default:
      return state;
  }
};

export const setSwitch = (payload) => ({
  type: SET_SWITCH_TYPE,
  payload
});
