import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  type: null,
  data: null
};

const editDialogSlice = createSlice({
  name: 'editDialog',
  initialState,
  reducers: {
    setEditDialog(state, action) {
      state.isOpen = action.payload.isOpen;
      state.type = action.payload.type;
      state.data = action.payload.data;
    }
  }
});

export const { setEditDialog } = editDialogSlice.actions;

const editDialogReducer = editDialogSlice.reducer;
export default editDialogReducer;
