import Axios from 'axios';
import { getuser } from '../firebase';

export const get = async (url) => {
  const headers = {
    headers: {
      'Auth-Provider': ['firebase'],
      'Auth-Type': ['client'],
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await getuser()) || window.electron?.store.get('token')}`
    }
  };
  return Axios.get(url, headers);
};

export const post = async (url, data = {}, withAuth = true) => {
  const headers = {
    headers: {
      'Auth-Provider': ['firebase'],
      'Auth-Type': ['client'],
      'Content-Type': 'application/json',
      Authorization: withAuth
        ? `Bearer ${(await getuser()) || window.electron?.store.get('token')}`
        : null
    }
  };
  return Axios.post(url, data, headers);
};

export const patch = async (url, data = {}, withAuth = true) => {
  const headers = {
    headers: {
      'Auth-Provider': ['firebase'],
      'Auth-Type': ['client'],
      'Content-Type': 'application/json',
      Authorization: withAuth
        ? `Bearer ${(await getuser()) || window.electron?.store.get('token')}`
        : null
    }
  };
  return Axios.patch(url, data, headers);
};

export const put = async (url, data = {}, withAuth = true) => {
  const headers = withAuth
    ? {
        'Auth-Provider': ['firebase'],
        'Auth-Type': ['client'],
        'Content-Type': 'application/json',
        Authorization: withAuth
          ? `Bearer ${(await getuser()) || window.electron?.store.get('token')}`
          : null
      }
    : null;
  return Axios.put(url, data, headers);
};

export const del = async (url, withAuth = true) => {
  const headers = withAuth
    ? {
        headers: {
          'Auth-Provider': ['firebase'],
          'Auth-Type': ['client'],
          'Content-Type': 'application/json',
          Authorization: withAuth
            ? `Bearer ${(await getuser()) || window.electron?.store.get('token')}`
            : null
        }
      }
    : null;
  return Axios.delete(url, headers);
};
