import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accounts: []
};

const accountTradeSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccounts(state, action) {
      state.accounts = action.payload;
    }
  }
});

export const { setAccounts } = accountTradeSlice.actions;

const accountTradeReducer = accountTradeSlice.reducer;
export default accountTradeReducer;
