import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  payload: {
    action: 200,
    digits: 0,
    price_sl: 0,
    price_tp: 0,
    symbol: null,
    type: 0,
    type_fill: 0,
    volume: 100,
    price_order: 0,
    price_trigger: 0,
    time_expiration: 0,
    account_trade_id: null
  }
};

const payloadSlice = createSlice({
  name: 'payload',
  initialState,
  reducers: {
    setPayload(state, action) {
      state.payload = {
        ...state.payload,
        ...action.payload,
      };
    }
  }
});

export const { setPayload } = payloadSlice.actions;

const payloadReducer = payloadSlice.reducer;
export default payloadReducer;
