import React, { memo, useState } from 'react';
import { RightButtonAction } from './RightButtonAction';
import QuickLotSize from './QuickLotSize';
import ProfitChange from './ProfitChange';

const RightPanel = memo(() => {
  return (
    <div className="menu-right">
      <div>
        <QuickLotSize />
        <div style={{ height: '20px' }}></div>
        <ProfitChange />
        <RightButtonAction />
      </div>
      <div className="h-[100px]" />
      {/* <CountDownTimer /> */}
    </div>
  );
});
RightPanel.displayName = 'RightPanel';

export default RightPanel;
