import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  orders: []
};
const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders(state, action) {
      state.orders = action.payload;
    }
  }
});

export const { setOrders } = orderSlice.actions;

const orderReducer = orderSlice.reducer;
export default orderReducer;
