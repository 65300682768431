const AUTH_TYPE = 'AUTH_TYPE';

const initialState = {
  auth: false
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_TYPE: {
      return {
        ...state,
        auth: action.payload
      };
    }
    default:
      return state;
  }
};

export const setAuth = (payload) => ({
  type: AUTH_TYPE,
  payload
});
