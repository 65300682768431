export const instrumentInfo = ({ instruments, symbolName }) => {
  let activeInfo = {
    symbol: '',
    full_name: '',
    exchange: '',
    description: '',
    type: '',
    digit: 0,
    logo: '',
    currency_margin: '',
    contract_size: 0,
    volume_min: 0,
    volume_max: 0,
    stops_level: 0,
    stat: {
      price_open: 0,
      bid_high: 0
    }
  };
  activeInfo = instruments.find((e) => e.symbol === symbolName);
  return activeInfo;
};

export const checkMinMaxVolume = (min, value, volume) => {
  let check = true;
  if (min === 'min') check = true;
  else check = false;
  let a = {
    con: false,
    value: 0
  };
  if (check) {
    a = {
      con: value <= volume / 10000,
      value: volume / 10000
    };
  } else {
    a = {
      con: value >= volume / 10000,
      value: volume / 10000
    };
  }
  return a;
};

export const splitValueDecimal = (a) => {
  return `${a.toString().split('.')[0]}.${a.toString().split('.')[1].slice(0, 2)}`;
};
