export function mapActionType(action) {
  switch (action) {
    case 0:
      return 'BUY';
    case 1:
      return 'SELL';
    case 2:
      return 'BUY LIMIT';
    case 3:
      return 'SELL LIMIT';
    case 4:
      return 'BUY STOP';
    case 5:
      return 'SELL STOP';
    case 6:
      return 'BUY STOP LIMIT';
    case 7:
      return 'SELL STOP LIMIT';
    default:
      break;
  }
}

export function mapColor(action) {
  switch (action) {
    case 0:
      return '#1f6fde'; //363c4c
    case 1:
      return '#C94644';
    case 2:
      return '#6b6e76'; //1f6fde
    case 3:
      return '#6b6e76'; //C94644
    case 4:
      return '#6b6e76';
    case 5:
      return '#6b6e76';
    case 6:
      return '#6b6e76';
    case 7:
      return '#6b6e76';
    default:
      return '';
  }
}
