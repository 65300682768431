import React, { memo } from 'react'

const SymbolItem = memo(function SymbolItem(props) {
  return (
    <div
      style={{
        transition: 'all 0.3s ease-in',
        filter: props.maxW ? (props.active ? '' : 'drop-shadow(-3mm 0mm 4mm rgb(25 23 23))') : '',
      }}
      className={`relative symbol-flex max-w-[150px] ${props.active ? 'active' : ''} ${
        props.maxW
          ? 'animation-symbol-list hover:bg-[#20242f] hover:-left-2 left-0'
          : 'animation-symbol-list-nomal'
      }`}
    >
      <div className="div-flex-center" onClick={() =>
        props.handleClick('select', props.keyIndex, props.name, {
          symbol: props.symbol,
          logo: props.logo,
        })
      }>
        <img
          className="-ml-[5px] rounded-[50%]"
          src={`https://storage.googleapis.com/iux-storage/symbol/${props.symbol}.png`}
          width="20px"
          height="20px"
          onError={({ currentTarget }) => {
            currentTarget.src = 'globe.svg'
          }}
        />
        <div
          className="symbol-name"
          // style={{ marginLeft: props.length > 6 ? '0px' : '4px' }}
        >
          {props.symbol}
        </div>
      </div>
      {!props.lastIndex ? (
        <div
          className="close-symbol relative z-10"
          onClick={() => props.handleClick('close', props.keyIndex, props.symbol)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            fill="rgb(142	145	152)"
            className="bi bi-x"
            viewBox="0 0 16 16"
            id="IconChangeColor"
          >
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              id="mainIconPathAttribute"
              strokeWidth="1"
              stroke="rgb(142	145	152)"
              fill="rgb(142	145	152)"
            ></path>
          </svg>
        </div>
      ) : (
        <div />
      )}
    </div>
  )
})

export { SymbolItem }
