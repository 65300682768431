import moment from 'moment';
import { serverApiConfig } from '../config';
import EventEmitter from '../utilities/service';
const { AccountClient } = require('../grpc/generated/account_grpc_web_pb');

export default class UserHistory {
  async userHistory(request, metadata, mode) {
    let accountClient = new AccountClient(
      serverApiConfig(localStorage.getItem('trade.server')),
      null,
      null
    );
    request.setFrom(0);
    request.setTo(moment().valueOf());
    request.setMode(mode);
    request.setSymbol('');
    await accountClient.getUserHistory(request, metadata, (err, response_) => {
      if (response_ !== null) {
        EventEmitter.dispatch('history', {
          mode: mode,
          data: response_
        });
      }
    });
  }
  // stream = null;
  // async OHLCStream(request, metadata) {
  //   try {
  //     let historyClient = new HistoryClient(
  //       serverApiConfig(localStorage.getItem('trade.server')),
  //       null,
  //       null
  //     );
  //     this.stream = historyClient.getOHLCStream(request, metadata);
  //     this.stream.on('data', function (response) {
  //       console.log('response', response);
  //     });
  //     this.stream.on('error', function (err) {
  //       console.log('Account error =>>', err);
  //       this.stream?.cancel();
  //       setTimeout(() => {
  //         console.log('Reconect Position');
  //         // window.location.reload();
  //       }, 5000);
  //     });
  //     this.stream.on('status', function (status) {
  //       console.log('Account status', status);
  //     });
  //     this.stream.on('end', function () {
  //       console.log('end');
  //     });
  //   } catch (e) {
  //     console.log(e);
  //     setTimeout(() => {
  //       console.log('Reconect Position');
  //       // window.location.reload();
  //     }, 5000);
  //   }
  // }
}
