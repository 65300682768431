import React, { memo, useCallback, useContext, useMemo } from 'react'
import { useAppSelector } from '../../redux/app'
import { TradeContext } from './TradeContextProvider'
import { makeBidAskBySymbolSelector } from '../../redux/selector'

const PlaceButton = memo(({ type }) => {
  const { instrumentInfo, handleExecute } = useContext(TradeContext)
  const payload = useAppSelector((state) => state.payload?.payload)
  const getBidAsk = useMemo(makeBidAskBySymbolSelector, [])

  const bidAsk = useAppSelector((state) => getBidAsk(state, instrumentInfo?.symbol))

  const disabled = useCallback(
    ({ type, digit, stops_level, ask, bid }) => {
      let a = true
      const trigger = +payload.price_trigger
      const price_order = +payload.price_order
      if (type === 2) {
        if (price_order <= 0) {
          a = true
        } else {
          a = !(price_order < ask - (stops_level > 0 ? (stops_level * Math.pow(10, -digit)) : 0))
        }
        return a
      }
      if (type === 3) {
        if (price_order <= 0) {
          a = true
        } else {
          a = !(price_order > bid + (stops_level > 0 ? (stops_level * Math.pow(10, -digit)) : 0))
        }
        return a
      }
      if (type === 4) {
        if (price_order <= 0) {
          a = true
        } else {
          a = !(price_order >= ask + (stops_level > 0 ? (stops_level * Math.pow(10, -digit)) : 0))
        }
        return a
      }
      if (type === 5) {
        if (price_order <= 0) {
          a = true
        } else {
          a = !(price_order < bid - (stops_level > 0 ? (stops_level * Math.pow(10, -digit)) : 0))
        }
        return a
      }
      if (type === 6) {
        if (price_order <= 0 || trigger <= 0) {
          a = true
        } else {
          return !(price_order > ask + (stops_level > 0 ? (stops_level * Math.pow(10, -digit)) : 0) && trigger < price_order - (stops_level > 0 ? (stops_level * Math.pow(10, -digit)) : 0))
        }
        return a
      }
      if (type === 7) {
        if (price_order <= 0 || trigger <= 0) {
          a = true
        } else {
          return !(price_order < bid - (stops_level > 0 ? (stops_level * Math.pow(10, -digit)) : 0) && trigger > price_order + (stops_level > 0 ? (stops_level * Math.pow(10, -digit)) : 0))
        }
        return a
      }
    },
    [payload],
  )

  return (
    <div className="mt-4">
      <button
        id="entry"
        className="button-outline-entry"
        disabled={disabled({
          type,
          ask: bidAsk?.ask ?? 0,
          bid: bidAsk?.bid ?? 0,
          stops_level: instrumentInfo.stops_level,
          digit: instrumentInfo.digit,
        })}
        onClick={() => handleExecute(type, 201, 2)}>
        Place
      </button>
    </div>
  )
})

PlaceButton.displayName = 'PlaceButton'

const Bid = memo(() => {
  const { instrumentInfo } = useContext(TradeContext)
  const getBidAsk = useMemo(makeBidAskBySymbolSelector, [])
  const ask = useAppSelector((state) => getBidAsk(state, instrumentInfo?.symbol))
  return <div className="bid">{ask ? ask?.ask.toFixed(instrumentInfo?.digit ?? 0) : (0).toFixed(instrumentInfo?.digit ?? 0)}</div>
})

Bid.displayName = 'Bid'

const Ask = memo(() => {
  const { instrumentInfo } = useContext(TradeContext)
  const getBidAsk = useMemo(makeBidAskBySymbolSelector, [])
  const bid = useAppSelector((state) => getBidAsk(state, instrumentInfo?.symbol))
  return <div className="ask">{bid ? bid?.bid.toFixed(instrumentInfo?.digit ?? 0) : (0).toFixed(instrumentInfo?.digit ?? 0)}</div>
})

Ask.displayName = 'Ask'

const BuySellButton = memo(({ typeBuy }) => {
  const { handleExecute } = useContext(TradeContext)

  const buttonType = (type, openType) => {
    switch (type) {
      case 0:
        return (
          <div className="flex button-buy-sell">
            <button
              className="sell"
              onClick={async () => {
                await handleExecute(1, 200, 0)
              }}>
              Sell at Market
            </button>
            <div />
            <button
              className="buy"
              onClick={async () => {
                await handleExecute(0, 200, 0)
              }}>
              Buy at Market
            </button>
          </div>
        )
      case 1:
        return <PlaceButton type={typeBuy} />
      default:
        break
    }
  }

  return (
    <div className="mt-4" style={{ padding: '0 12px' }}>
      <div className="flex at-market">
        <Ask />
        <div />
        <Bid />
      </div>
      {buttonType(typeBuy === 0 || typeBuy === 1 ? 0 : 1, typeBuy)}
    </div>
  )
})

BuySellButton.displayName = 'BuySellButton'

export default BuySellButton
