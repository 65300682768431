import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import './animation.css';
import './СomponentStyle.styl';
import 'react-notifications-component/dist/theme.css';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import { AuthProvider } from './utilities/JWTContext';
import Router from './routes';
import './i18n';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className="App">
    <AuthProvider>
      <ReactNotifications />
      <Provider store={store}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </Provider>
    </AuthProvider>
  </div>
);

serviceWorker.unregister();
