import { Store } from 'react-notifications-component';
const Notification = ({ title, message, type, insert = 'top' }) => {
  Store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: insert,
    container: 'bottom-left',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 3000,
      onScreen: true,
      pauseOnHover: true
    },
    slidingExit: {
      duration: 800,
      timingFunction: 'ease-out',
      delay: 0
    }
  });
};

export {
  Notification
}