import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LeftPanel from './LeftPanel';
import TradeDialog from './trad-dialog/trade';
import ChartContainer from './TVChartContainer/ChartContainer';
import DrawerAccount from './accounts/drawer';
import HistoryPanel from './position-order-history/HistoryPanel';
import StatsData from '../data/stats';
import TopPanel from './TopPanel.js';
import GoToApp from './GoToApp.js';
import RightPanel from './RightPanel.js';
import SubscribeSettingProvider from './SubscribeSettingProvider.js';
import useAuth from '../hooks/useAuth.js';
import EventEmitter from '../utilities/service.js';
import { useAppDispatch, useAppSelector } from '../redux/app.js';
import { setCategories, setInstrument, setMenuSymbol } from '../redux/reducer/instruments.js';
import { metadata } from '../data/metadata.js';
import EditPosition from './position-order-history/EditPosition.js';
import TradeContextProvider from './trad-dialog/TradeContextProvider';
import useLastTick from '../data/useLastTick';
import Position from './position-order-history/position';
import Deals from './position-order-history/history/deals';
import Orders from './position-order-history/history/orders';
import Positions from './position-order-history/history/positions';

let google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');

const Dashboard = () => {
  const { isAuthenticated, activeAccount } = useAuth();
  const { getAllLastTick } = useLastTick();
  const edit = useAppSelector((state) => state.editDialog);
  const dispatch = useAppDispatch();
  const { subscribeStat } = StatsData();
  const tradeDialog = useSelector(({ tradeDialog }) => tradeDialog);
  const active = useSelector((state) => state.symbol?.active);
  const [loading, setLoading] = useState(true);
  const [activeSymbol, setActive] = useState(null);
  const [historyType, setType] = useState(2);
  const [dialog, setDialog] = useState(false);
  const [screen, setScreen] = useState(false);

  const request = new google_protobuf_empty_pb.Empty();

  useEffect(() => {
    // subscribeStat(request, metadata());
    getAllLastTick();
  }, []);

  useEffect(() => {
    if (!screen && isAuthenticated) {
      (async () => {
        await EventEmitter.subscribe('listArray', (event) => {
          const categories = event.reduce((a, b) => ({ ...a, [b.type]: b.type }), {});
          const firstSymbolData = event.find((e) => e.symbol.includes('XAUUSD'));
          dispatch(setInstrument(event));
          dispatch(setCategories(categories));
          const prevMenu = localStorage.getItem('symbol_menu_list');
          let prev = {};
          if (activeAccount) {
            if (prevMenu !== null) {
              const prevJson = JSON.parse(prevMenu);
              // ถ้ามี menu ใน storage
              if (!prevJson[activeAccount]) {
                // ถ้าไม่เจอ account id
                prev = {
                  ...prevJson, // โยนค่าเก่าเข้าไปเก็บ
                  [activeAccount]: {
                    // เพิ่มค่าตัวใหม่เข้าไป
                    menu: [
                      {
                        symbol: firstSymbolData.symbol,
                        logo: firstSymbolData.logo
                      }
                    ],
                    active: firstSymbolData.symbol
                  },
                  accountId: activeAccount
                };
                dispatch(setMenuSymbol({ type: 'old', data: prev }));
                window.localStorage.setItem('symbol_menu_list', JSON.stringify(prev));
              } else {
                // ถ้า login แล้วเจอ account id ใน stroage จะดึงมาใช้เลย
                dispatch(
                  setMenuSymbol({ type: 'old', data: { ...prevJson, accountId: activeAccount } })
                );
              }
            } else {
              // ถ้าไม่มี menu ใน storage
              dispatch(
                setMenuSymbol({
                  type: 'first',
                  data: {
                    [activeAccount]: {
                      menu: [
                        {
                          symbol: firstSymbolData.symbol,
                          logo: firstSymbolData.logo
                        }
                      ],
                      active: firstSymbolData.symbol
                    },
                    accountId: activeAccount
                  }
                })
              );
            }
          }
        });
        setLoading(false);
      })();
    }
  }, [screen, isAuthenticated]);

  useEffect(() => {
    setActive(active);
  }, [active]);

  useEffect(() => {
    const body = document.getElementsByTagName('body');
    if (body.length > 0) {
      // eslint-disable-next-line no-undef
      const resizeObserver = new ResizeObserver((e) => {
        if (e.length > 0) {
          const width = e[0].contentRect.width;
          setScreen(width <= 1024);
        }
      });
      resizeObserver.observe(body[0]);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  if (!screen) {
    if (!loading) {
      return (
        <div className="prevent-select">
          <TopPanel />
          <div className="flex h-[100vh]">
            <TradeContextProvider>
              <div className="flex-[1] max-w-[70px] h-full">
                <LeftPanel />
                <TradeDialog type={tradeDialog.type} />
                <Position />
              </div>
              <div className="hidden md:block flex-[1] h-full w-[185px] fixed top-0 right-0 z-[1] pt-[65px]">
                <RightPanel />
              </div>
              <SubscribeSettingProvider>
                {edit?.type ? <EditPosition /> : <div />}
              </SubscribeSettingProvider>
            </TradeContextProvider>
            <div
              className={`flex-[2] mr-0 md:mr-[185px] bg-black ${
                tradeDialog?.isOpen ? 'ml-[400px]' : ''
              }`}>
              {activeSymbol && <ChartContainer symbol={activeSymbol} />}
            </div>
          </div>
          <HistoryPanel setType={setType} setDialog={setDialog}>
            {historyType === 2 && <Positions dialog={dialog} setDialog={setDialog} />}
            {historyType === 3 && <Orders dialog={dialog} setDialog={setDialog} />}
            {historyType === 1 && <Deals dialog={dialog} setDialog={setDialog} />}
          </HistoryPanel>
          <DrawerAccount />
        </div>
      );
    }
    return <div />;
  } else {
    return <GoToApp />;
  }
};

export default Dashboard;
