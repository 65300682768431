import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { setEditDialog } from '../../redux/reducer/edit-dialog';
import { editOrderApi, editPositionApi } from '../../api/trade';
import { Notification } from '../../utilities/alert';
import { getAccountTradeId } from '../../config';
import { SubSettingContext } from '../SubscribeSettingProvider';
import emitter from '../../utilities/emitter';
import useGetLastTick from '../../data/useLastTick';
import { useAppDispatch, useAppSelector } from '../../redux/app';
import UseStopLossTakeProfit from '../../utilities/useStopLossTakeProfit';
import { setPayload } from '../../redux/reducer/payload';
import InputPrice from '../trad-dialog/InputPrice';
import InputTriggerPrice from '../trad-dialog/InputTriggerPrice';
import { type } from '../../constant';
import ExpirationDate from '../trad-dialog/ExpirationDate';
import { TradeContext } from '../trad-dialog/TradeContextProvider';
import moment from 'moment';
import { makeInstrumentInfoSelector } from '../../redux/selector';

const EditPosition = memo(() => {
  const edit = useSelector((state) => state.editDialog);
  const { onSubscribe, unSubscribe } = useContext(SubSettingContext);

  const getInstrumentInfo = useMemo(makeInstrumentInfoSelector, [])
  const instrumentInfo = useAppSelector((state) => getInstrumentInfo(state, edit?.data.symbol))

  const dispatch = useAppDispatch();
  const payload = useAppSelector((state) => state.payload?.payload);
  const { handlePrice } = UseStopLossTakeProfit();

  const { active } = useSelector(({ symbol }) => symbol);
  const [loading, setLoading] = useState(false);

  const { getLastTick } = useGetLastTick();

  const [bidAsk, setBidAsk] = useState({
    bid: 0,
    ask: 0,
    symbol: ''
  });

  const [error, setError] = useState(false);

  useEffect(() => {
    if (edit?.data) {
      getLastTick(edit.data.symbol).then(() => {
        onSubscribe(edit.data.symbol);
      });
      let payloadData = {
        price_sl: edit.data.priceSL,
        price_tp: edit.data.priceTP
      };
      if (edit?.type === 'editLimit') {
        payloadData.price_order = edit.data.priceOrder;
      }
      if (edit?.type === 'editStopLimit') {
        payloadData.price_order = edit.data.priceOrder;
        payloadData.price_trigger = edit.data.priceTrigger;
      }
      dispatch(
        setPayload({
          ...payload,
          ...payloadData
        })
      );
    }
  }, [edit]);

  const onClose = (symbol) => {
    if (symbol !== active) {
      unSubscribe(edit?.data?.symbol);
    }
    dispatch(
      setPayload({
        ...payload,
        time_expiration: 0,
        type_time: 0,
        price_order: 0,
        price_trigger: 0
      })
    );
  };

  useEffect(() => {
    emitter.instance.on('TickData', (event) => {
      if (event[edit?.data.symbol]) {
        setBidAsk({
          symbol: edit?.data.symbol,
          bid: event[edit?.data.symbol].bid,
          ask: event[edit?.data.symbol].ask
        });
      }
    });
  }, [edit]);

  const onEdit = async (payload, type) => {
    try {
      setLoading(true);
      let payloadData = {
        account_trade_id: getAccountTradeId(),
        ticket: edit?.data.position,
        price_order: payload.price_order,
        price_sl: payload.price_sl,
        price_tp: payload.price_tp,
        price_trigger: payload.price_trigger ?? 0
      };

      if (payload.time_expiration <= 0)
        payloadData.time_expiration = moment.tz('Europe/London').isDST()
          ? moment().utc().unix() + 3600
          : moment().utc().unix();
      else payloadData.time_expiration = payload.time_expiration;
      if (type === 'edit') payloadData.symbol = edit.data.symbol;
      if (type === 'buy' || type === 'sell') await editPositionApi(payloadData);
      if (type.startsWith('edit')) await editOrderApi(payloadData);

      Notification({ title: 'Success', type: 'success', message: 'Edit Success' });
      dispatch(setEditDialog({ isOpen: false, type: '', data: null }));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      Notification({
        title: 'Invalid',
        type: 'danger',
        message: e?.response?.data?.error?.message
      });
    }
  };

  const typeEdit = (type) => {
    let a = type;
    if (
      a === 'editBuyStop' ||
      a === 'editSellStop' ||
      a === 'editBuyLimit' ||
      a === 'editSellLimit'
    ) {
      a = 'edit';
    } else if (a === 'editBuyStopLimit' || a === 'editSellStopLimit') {
      a = 'editStopLimit';
    }

    switch (a) {
      case 'edit':
        return (
          <div className="px-[20px]">
            <div className="text-white text-sm">Price</div>
            <div className="mt-2">
              <InputPrice
                validate={edit?.type === 'editLimit' ? 2 : 3}
                className="rounded-lg"
                editBidAsk={{ ...bidAsk, digit: edit?.data?.digits ?? 0 }}
              />
            </div>
          </div>
        );
      case 'editStopLimit':
        return (
          <>
            <div className="text-white text-sm px-[20px]">Price</div>
            <div className="px-[10px]">
              <InputPrice
                validate={edit?.type === 'editStopLimit' ? 2 : 3}
                editBidAsk={{ ...bidAsk, digit: edit?.data?.digits ?? 0 }}
                className="rounded-lg mt-0"
              />
            </div>
            <div className="text-white text-sm px-[20px] mt-4">Price Ticker</div>
            <div className="px-[10px]">
              <InputTriggerPrice
                validate={edit?.type === 'editStopLimit' ? 6 : 7}
                className="rounded-lg mt-0"
              />
            </div>
          </>
        );
      default:
        break;
    }
  };

  const editTitle = (type) => {
    let isType = type;
    if (type !== 'buy' && type !== 'sell') isType = 'order';

    switch (isType) {
      case 'order':
        return 'Order';
      default:
        return 'Position';
    }
  };

  return (
    <div
      className="backdrop"
      style={{
        transition: 'all 0.3s'
      }}>
      <div className="max-w-[500px] max-h-max m-auto bg-[#0f1018] rounded-[20px] border border-[#232323]">
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '12px 20px 0px 20px',
              alignItems: 'center'
            }}>
            <div></div>
            <div className="text-center text-white text-sm" style={{ fontWeight: 'bold' }}>
              {`${editTitle(edit?.type)}: #${edit?.data?.position} ${type.str[edit?.type]} ${
                ((edit?.data?.volume / 100) * 1) / 100
              } ${edit?.data?.symbol} ${edit?.data?.priceOpen?.toFixed(edit?.data.digits)}`}
            </div>
            <div>
              <button
                className="defult"
                onClick={() => {
                  dispatch(setEditDialog({ isOpen: false, type: null, data: null }));
                  onClose(edit?.data?.symbol);
                }}>
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M16 8L8 16M8 8L16 16"
                      stroke="#fff"
                      strokeWidth="2"
                      strokeLinecap="round"></path>{' '}
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <hr className="border border-[#393939] my-3" />
          <div className="edit-position mt-2 px-[20px]">
            <p>Symbol</p>
            <input disabled className="symbol-info text-sm" defaultValue={`${payload.symbol}`} />
            <div style={{ height: '10px' }} />
            <p>Type</p>
            <input
              disabled
              className="symbol-info text-sm"
              value={!edit?.type.startsWith('edit') ? 'Modify Position' : 'Modify Order'}
            />
          </div>
          {edit?.type.startsWith('edit') && <div className="h-[10px]" />}
          {typeEdit(edit?.type)}
          <div className="h-[10px]" />
          <div className="flex">
            <div className="edit_position_T">
              <p className="px-[20px]">Stop Loss</p>
              <div className="box-input-lotsize">
                <div>
                  <button
                    disabled={+payload.price_sl === 0}
                    className="dec"
                    onClick={() =>
                      handlePrice(
                        'dec',
                        payload.price_sl,
                        'price_sl',
                        edit?.data?.digits,
                        dispatch,
                        payload,
                        bidAsk.bid
                      )
                    }>
                    -
                  </button>
                </div>
                <input
                  className={`edit_position_T input-left-lotsize ${
                    payload.price_sl !== 0 ? (error ? 'errors' : '') : ''
                  }`}
                  placeholder="0000.00"
                  type="number"
                  onChange={({ target: { value } }) => {
                    dispatch(
                      setPayload({
                        ...payload,
                        price_sl: +value
                      })
                    );
                  }}
                  value={payload.price_sl <= 0 ? '' : payload.price_sl}
                  onMouseDown={() => {
                    if (payload.price_sl === 0 || payload.price_sl === '') {
                      dispatch(
                        setPayload({
                          ...payload,
                          price_sl: bidAsk.ask.toFixed(edit?.data.digits)
                        })
                      );
                    }
                  }}
                />
                <div>
                  <button
                    disabled={+payload.price_sl === 0}
                    className="inc"
                    onClick={() =>
                      handlePrice(
                        'inc',
                        payload.price_sl,
                        'price_sl',
                        edit?.data?.digits,
                        dispatch,
                        payload,
                        bidAsk.bid
                      )
                    }>
                    +
                  </button>
                </div>
              </div>
            </div>
            {/* ============ */}
            <div className="edit_position_T">
              <p style={{ padding: '0 20px' }}>Take Profit</p>
              <div className="box-input-lotsize">
                <div>
                  <button
                    disabled={+payload.price_tp === 0}
                    className="dec"
                    onClick={() =>
                      handlePrice(
                        'dec',
                        payload.price_tp,
                        'price_tp',
                        edit?.data?.digits,
                        dispatch,
                        payload,
                        bidAsk.bid
                      )
                    }>
                    -
                  </button>
                </div>
                <input
                  className={`edit_position_T input-left-lotsize ${
                    +payload?.price_tp !== 0 ? (error ? 'errors' : '') : ''
                  }`}
                  placeholder="0000.00"
                  type="number"
                  onChange={({ target: { value } }) => {
                    dispatch(
                      setPayload({
                        ...payload,
                        price_tp: +value
                      })
                    );
                  }}
                  value={payload.price_tp <= 0 ? '' : payload.price_tp}
                  onMouseDown={() => {
                    if (payload.price_tp === 0 || payload.price_tp === '') {
                      dispatch(
                        setPayload({
                          ...payload,
                          price_tp: bidAsk.ask.toFixed(edit?.data.digits)
                        })
                      );
                    }
                  }}
                />
                <div>
                  <button
                    disabled={+payload.price_tp === 0}
                    className="inc"
                    onClick={() =>
                      handlePrice(
                        'inc',
                        payload.price_tp,
                        'price_tp',
                        edit?.data?.digits,
                        dispatch,
                        payload,
                        bidAsk.bid
                      )
                    }>
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
          {edit?.type.startsWith('edit') && (
            <div className="px-[20px] mt-2">
              <div>
                <div className="text-white mb-1 text-[13px]">Expiration</div>
                <div className="select-expire rounded-lg">
                  <select
                    className=""
                    onChange={({ target: { value } }) => {
                      if (+value < 2) {
                        dispatch(
                          setPayload({
                            ...payload,
                            type_time: 0,
                            time_expiration: 0
                          })
                        );
                      } else {
                        dispatch(setPayload({ ...payload, type_time: +value }));
                      }
                    }}>
                    <option value={0} selected={payload.type_time === 0}>
                      GTC
                    </option>
                    <option value={1} selected={payload.type_time === 1}>
                      Today
                    </option>
                    <option value={2} selected={payload.type_time === 2}>
                      Specified
                    </option>
                    <option value={3} selected={payload.type_time === 3}>
                      Specified Day
                    </option>
                  </select>
                </div>
              </div>
              {payload.type_time >= 2 ? (
                <ExpirationDate
                  className="px-0 rounded-lg mt-2"
                  labelClass="mt-2 text-[13px]"
                  inputClass="mt-1 rounded-lg"
                  iconClass="top-4"
                />
              ) : null}
            </div>
          )}
          <div className="exit_price_bid_ask p-[20px]">
            <div className="bid-edit">{bidAsk?.bid.toFixed(edit?.data.digits)}</div>
            <div className="center">/</div>
            <div className="ask">{bidAsk?.ask.toFixed(edit?.data.digits)}</div>
          </div>
          <div style={{ padding: '0px 20px 20px', display: 'flex', justifyContent: 'center' }}>
            <button
              className="button_modify"
              onClick={() => {
                onEdit(payload, edit?.type);
              }}
              disabled={loading}>
              {`Modify #${edit?.data.position} buy ${((edit?.data?.volume / 100) * 1) / 100}
                ${edit?.data?.symbol} ${edit?.data?.priceOpen?.toFixed(edit?.data.digits)}`}
            </button>
          </div>
          <div className="modify_text">
            Stop Loss or Take Profit levels must differ from the market price by at least{' '}
            {instrumentInfo.stops_level ?? 0} points. Stop processing is performed on the broker’s
            side.
          </div>
        </>
      </div>
    </div>
  );
});

EditPosition.displayName = 'EditPosition';

export default EditPosition;
