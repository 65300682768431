import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

const tvWidgetSlice = createSlice({
  name: 'payload',
  initialState,
  reducers: {
    setTvWidget(state, action) {
      state = action.payload;
    }
  }
});

export const { setTvWidget } = tvWidgetSlice.actions;

const tvWidgetReducer = tvWidgetSlice.reducer;
export default tvWidgetReducer;
