// source: src/grpc/iuxprotos/history.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.history.OHLCData', null, global);
goog.exportSymbol('proto.history.OHLCReply', null, global);
goog.exportSymbol('proto.history.OHLCRequest', null, global);
goog.exportSymbol('proto.history.OHLCV2Request', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.history.OHLCV2Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.history.OHLCV2Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.history.OHLCV2Request.displayName = 'proto.history.OHLCV2Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.history.OHLCRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.history.OHLCRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.history.OHLCRequest.displayName = 'proto.history.OHLCRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.history.OHLCReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.history.OHLCReply.repeatedFields_, null);
};
goog.inherits(proto.history.OHLCReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.history.OHLCReply.displayName = 'proto.history.OHLCReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.history.OHLCData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.history.OHLCData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.history.OHLCData.displayName = 'proto.history.OHLCData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.history.OHLCV2Request.prototype.toObject = function(opt_includeInstance) {
  return proto.history.OHLCV2Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.history.OHLCV2Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.OHLCV2Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    from: jspb.Message.getFieldWithDefault(msg, 2, 0),
    to: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timeFrame: jspb.Message.getFieldWithDefault(msg, 4, 0),
    period: jspb.Message.getFieldWithDefault(msg, 5, 0),
    countBack: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.history.OHLCV2Request}
 */
proto.history.OHLCV2Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.history.OHLCV2Request;
  return proto.history.OHLCV2Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.history.OHLCV2Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.history.OHLCV2Request}
 */
proto.history.OHLCV2Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrom(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTo(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeFrame(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPeriod(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountBack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.history.OHLCV2Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.history.OHLCV2Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.history.OHLCV2Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.OHLCV2Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrom();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTimeFrame();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPeriod();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCountBack();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.history.OHLCV2Request.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.OHLCV2Request} returns this
 */
proto.history.OHLCV2Request.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 from = 2;
 * @return {number}
 */
proto.history.OHLCV2Request.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.history.OHLCV2Request} returns this
 */
proto.history.OHLCV2Request.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 to = 3;
 * @return {number}
 */
proto.history.OHLCV2Request.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.history.OHLCV2Request} returns this
 */
proto.history.OHLCV2Request.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 time_frame = 4;
 * @return {number}
 */
proto.history.OHLCV2Request.prototype.getTimeFrame = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.history.OHLCV2Request} returns this
 */
proto.history.OHLCV2Request.prototype.setTimeFrame = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 period = 5;
 * @return {number}
 */
proto.history.OHLCV2Request.prototype.getPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.history.OHLCV2Request} returns this
 */
proto.history.OHLCV2Request.prototype.setPeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 count_back = 6;
 * @return {number}
 */
proto.history.OHLCV2Request.prototype.getCountBack = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.history.OHLCV2Request} returns this
 */
proto.history.OHLCV2Request.prototype.setCountBack = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.history.OHLCRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.history.OHLCRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.history.OHLCRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.OHLCRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    from: jspb.Message.getFieldWithDefault(msg, 2, 0),
    to: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timeFrame: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.history.OHLCRequest}
 */
proto.history.OHLCRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.history.OHLCRequest;
  return proto.history.OHLCRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.history.OHLCRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.history.OHLCRequest}
 */
proto.history.OHLCRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrom(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTo(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeFrame(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.history.OHLCRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.history.OHLCRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.history.OHLCRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.OHLCRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrom();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTimeFrame();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.history.OHLCRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.OHLCRequest} returns this
 */
proto.history.OHLCRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 from = 2;
 * @return {number}
 */
proto.history.OHLCRequest.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.history.OHLCRequest} returns this
 */
proto.history.OHLCRequest.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 to = 3;
 * @return {number}
 */
proto.history.OHLCRequest.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.history.OHLCRequest} returns this
 */
proto.history.OHLCRequest.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 time_frame = 4;
 * @return {number}
 */
proto.history.OHLCRequest.prototype.getTimeFrame = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.history.OHLCRequest} returns this
 */
proto.history.OHLCRequest.prototype.setTimeFrame = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.history.OHLCReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.history.OHLCReply.prototype.toObject = function(opt_includeInstance) {
  return proto.history.OHLCReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.history.OHLCReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.OHLCReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    historiesList: jspb.Message.toObjectList(msg.getHistoriesList(),
    proto.history.OHLCData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.history.OHLCReply}
 */
proto.history.OHLCReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.history.OHLCReply;
  return proto.history.OHLCReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.history.OHLCReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.history.OHLCReply}
 */
proto.history.OHLCReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.history.OHLCData;
      reader.readMessage(value,proto.history.OHLCData.deserializeBinaryFromReader);
      msg.addHistories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.history.OHLCReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.history.OHLCReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.history.OHLCReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.OHLCReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHistoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.history.OHLCData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OHLCData histories = 1;
 * @return {!Array<!proto.history.OHLCData>}
 */
proto.history.OHLCReply.prototype.getHistoriesList = function() {
  return /** @type{!Array<!proto.history.OHLCData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.history.OHLCData, 1));
};


/**
 * @param {!Array<!proto.history.OHLCData>} value
 * @return {!proto.history.OHLCReply} returns this
*/
proto.history.OHLCReply.prototype.setHistoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.history.OHLCData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.history.OHLCData}
 */
proto.history.OHLCReply.prototype.addHistories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.history.OHLCData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.history.OHLCReply} returns this
 */
proto.history.OHLCReply.prototype.clearHistoriesList = function() {
  return this.setHistoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.history.OHLCData.prototype.toObject = function(opt_includeInstance) {
  return proto.history.OHLCData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.history.OHLCData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.OHLCData.toObject = function(includeInstance, msg) {
  var f, obj = {
    datetime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    open: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    high: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    low: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    close: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    tickVolume: jspb.Message.getFieldWithDefault(msg, 6, 0),
    spread: jspb.Message.getFieldWithDefault(msg, 7, 0),
    volume: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.history.OHLCData}
 */
proto.history.OHLCData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.history.OHLCData;
  return proto.history.OHLCData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.history.OHLCData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.history.OHLCData}
 */
proto.history.OHLCData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDatetime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpen(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHigh(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLow(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClose(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTickVolume(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpread(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolume(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.history.OHLCData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.history.OHLCData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.history.OHLCData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.OHLCData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatetime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOpen();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getHigh();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLow();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getClose();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getTickVolume();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getSpread();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getVolume();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
};


/**
 * optional int64 datetime = 1;
 * @return {number}
 */
proto.history.OHLCData.prototype.getDatetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.history.OHLCData} returns this
 */
proto.history.OHLCData.prototype.setDatetime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double open = 2;
 * @return {number}
 */
proto.history.OHLCData.prototype.getOpen = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.history.OHLCData} returns this
 */
proto.history.OHLCData.prototype.setOpen = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double high = 3;
 * @return {number}
 */
proto.history.OHLCData.prototype.getHigh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.history.OHLCData} returns this
 */
proto.history.OHLCData.prototype.setHigh = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double low = 4;
 * @return {number}
 */
proto.history.OHLCData.prototype.getLow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.history.OHLCData} returns this
 */
proto.history.OHLCData.prototype.setLow = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double close = 5;
 * @return {number}
 */
proto.history.OHLCData.prototype.getClose = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.history.OHLCData} returns this
 */
proto.history.OHLCData.prototype.setClose = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional uint64 tick_volume = 6;
 * @return {number}
 */
proto.history.OHLCData.prototype.getTickVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.history.OHLCData} returns this
 */
proto.history.OHLCData.prototype.setTickVolume = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 spread = 7;
 * @return {number}
 */
proto.history.OHLCData.prototype.getSpread = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.history.OHLCData} returns this
 */
proto.history.OHLCData.prototype.setSpread = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 volume = 8;
 * @return {number}
 */
proto.history.OHLCData.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.history.OHLCData} returns this
 */
proto.history.OHLCData.prototype.setVolume = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


goog.object.extend(exports, proto.history);
