import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false
};

const dropdownSlice = createSlice({
  name: 'dropdown',
  initialState,
  reducers: {
    setDropdown(state, action) {
      state.open = action.payload;
    }
  }
});

export const { setDropdown } = dropdownSlice.actions;

const dropdownReducer = dropdownSlice.reducer;
export default dropdownReducer;
