import React, { memo, useContext, useMemo, useRef } from 'react';
import { usePriceTringgerChange } from './handle-call';
import { useAppDispatch, useAppSelector } from '../../redux/app';
import { TradeContext } from './TradeContextProvider'
import { setPayload } from '../../redux/reducer/payload';
import { makeBidAskSelector } from '../../redux/selector';
import cn from '../../utilities/cn';

const InputTriggerPrice = memo(({ validate, className }) => {
  const priceRef = useRef(null);

  const getBidAsk = useMemo(makeBidAskSelector, []);

  const ask = useAppSelector((state) => getBidAsk(state, 'ask'));
  const bid = useAppSelector((state) => getBidAsk(state, 'bid'));

  const data = useAppSelector((state) => state.payload?.payload);

  const { instrumentInfo } = useContext(TradeContext);
  const dispatch = useAppDispatch();

  const handlePrice = (type) => {
    const price = usePriceTringgerChange({
      type: type,
      price_trigger: data.price_trigger,
      ask,
      digits: instrumentInfo?.digit
    });
    dispatch(
      setPayload({
        ...data,
        price_trigger: price
      })
    );
  };

  const validatePrice = (key, pOder = 0) => {
    // const dfp = pOder - instrumentInfo?.stops_level * Math.pow(10, -instrumentInfo?.digit);
    let price_trigger = 0;
    switch (key) {
      case 6:
        price_trigger = ask ?? 0;
        break;
      case 7:
        price_trigger = bid ?? 0;
        break;
      default:
        price_trigger = 0;
        break;
    }
    return dispatch(
      setPayload({
        ...data,
        price_trigger
      })
    );
  };
  
  return (
    <div className={cn('box-input-lotsize', className)}>
      <div>
        <button className="dec" onClick={() => handlePrice('dec')}>
          -
        </button>
      </div>
      <input
        onChange={({ target: { value } }) => {
          if (value !== '') {
            if (value.startsWith('-')) {
              priceRef.current.value = data.price_trigger;
            } else {
              dispatch(
                setPayload({
                  ...data,
                  price_trigger: +value
                })
              );
            }
          } else {
            dispatch(
              setPayload({
                ...data,
                price_trigger: 0
              })
            );
          }
        }}
        type="number"
        ref={priceRef}
        value={data.price_trigger <= 0 ? '' : data.price_trigger}
        placeholder="No Set"
        className="input-left-lotsize m-[10px]"
        onMouseDown={() => {
          if (data.price_trigger === 0 || data.price_trigger === '') {
            validatePrice(validate)
          }
        }}
      />
      <div>
        <button className="inc" onClick={() => handlePrice('inc')}>
          +
        </button>
      </div>
    </div>
  );
});

InputTriggerPrice.displayName = 'InputTriggerPrice';

export default InputTriggerPrice;
