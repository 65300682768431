import React, { useRef } from 'react'

const DateFilter = ({ from, to, submitDate, handleCustom }) => {
  const dateFromRef = useRef(null)
  const dateToRef = useRef(null)
  return (
    <div className="px-5 pb-5 pt-4 border-t border-t-[#2f2f2f]">
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
        <div>
          <div className="text-left text-[#999999] mb-2 text-sm">From</div>
          <div className="relative">
            <input
              ref={dateFromRef}
              onChange={({ target: { value } }) => handleCustom(value, true)}
              className="w-full h-[40px] rounded-md px-2 text-[#c7c8c9] bg-[#0000] border border-[#2e2e2e] text-sm"
              type="datetime-local"
            />
            <div className="absolute right-[15px] top-[12px] cursor-pointer"
                 onClick={() => dateFromRef.current.showPicker()}>
              <svg width="15" height="15" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M3 10H21M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
                    stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div>
          <div className="text-left text-[#999999] mb-2 text-sm">To</div>
          <div className="relative">
            <input
              ref={dateToRef}
              onChange={({ target: { value } }) => handleCustom(value, false)}
              className="w-full h-[40px] rounded-md px-2 text-[#c7c8c9] bg-[#0000] border border-[#2e2e2e] text-sm"
              type="datetime-local"
            />
            <div className="absolute right-[15px] top-[12px] cursor-pointer"
                 onClick={() => dateToRef.current.showPicker()}>
              <svg width="15" height="15" viewBox="0 0 24 24" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M3 10H21M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
                    stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-5">
        <button
          disabled={from === '' || to === ''}
          onClick={submitDate}
          className="px-5 py-2 w-[100px] rounded-md bg-[#169376] disabled:bg-[#262626] disabled:text-[#595959] text-white">
          Submit
        </button>
      </div>
    </div>
  )
}

export default DateFilter