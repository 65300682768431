export const usePriceChange = ({ type, price_order, ask, digits }) => {
  const digitsOfSymbol = `${(0).toFixed(digits).substring(0, (0).toFixed(digits).length - 1)}1`;
  switch (price_order) {
    case 0 <= Number:
      return parseFloat(ask.toFixed(digits));
    default:
      switch (type) {
        case 'dec':
          return (+price_order - parseFloat(digitsOfSymbol)).toFixed(digits);
        case 'inc':
          return (+price_order + parseFloat(digitsOfSymbol)).toFixed(digits);
      }
  }
};

export const usePriceTringgerChange = ({ type, price_trigger, ask, digits }) => {
  const digitsOfSymbol = `${(0).toFixed(digits).substring(0, (0).toFixed(digits).length - 1)}1`;
  switch (price_trigger) {
    case 0 <= Number:
      return parseFloat(ask.toFixed(digits));
    default:
      switch (type) {
        case 'dec':
          return (+price_trigger - parseFloat(digitsOfSymbol)).toFixed(digits);
        case 'inc':
          return (+price_trigger + parseFloat(digitsOfSymbol)).toFixed(digits);
      }
  }
};


export const handlePrice = ({ type, index, payload, bidAsk, setPayload, digits }) => {
  if (index === 0) {
    if (payload.price_order === 0) {
      setPayload((prev) => ({
        ...prev,
        price_order: parseFloat(bidAsk.ask.toFixed(digits))
      }));
    } else {
      let a = 0;
      const calcDigit = `${a.toFixed(digits).substring(0, a.toFixed(digits).length - 1)}1`;
      if (type === 'dec') {
        setPayload((prev) => ({
          ...prev,
          price_order: (+payload.price_order - parseFloat(calcDigit)).toFixed(digits)
        }));
      } else {
        setPayload((prev) => ({
          ...prev,
          price_order: (+payload.price_order + parseFloat(calcDigit)).toFixed(digits)
        }));
      }
    }
  } else {
    if (payload.price_trigger === 0) {
      setPayload((prev) => ({
        ...prev,
        price_trigger: parseFloat(bidAsk.ask.toFixed(digits))
      }));
    } else {
      let a = 0;
      const calcDigit = `${a.toFixed(digits).substring(0, a.toFixed(digits).length - 1)}1`;
      if (type === 'dec') {
        setPayload((prev) => ({
          ...prev,
          price_trigger: (+payload.price_trigger - parseFloat(calcDigit)).toFixed(digits)
        }));
      } else {
        setPayload((prev) => ({
          ...prev,
          price_trigger: (+payload.price_trigger + parseFloat(calcDigit)).toFixed(digits)
        }));
      }
    }
  }
};
