import { mapActionType, mapColor } from './actions';

let positionList = [];
let positionLine = [];

export function CreatePolisionLine(positions, tvWidget) {
  try {
    let activeSymbol = tvWidget.activeChart().symbolExt()?.symbol;

    let previousSymbol = activeSymbol;

    let symbolExt = tvWidget.activeChart().symbolExt();

    if (symbolExt != null) {
      activeSymbol = symbolExt['description'];
    }

    let result = positions.filter((obj) => {
      // กรอง Position ของ Symbol ที่เลือก
      return obj[1] === activeSymbol;
    });

    if (activeSymbol !== previousSymbol) {
      previousSymbol = activeSymbol;

      if (result.length > 0) {
        let position = {};
        for (let i = 0; i < result.length; i++) {
          let newPositionResult = positionList.filter((obj) => {
            // หาว่ามี position ไหนที่ยังไม่มีใน chart
            return obj.position === result[i][0];
          });

          if (newPositionResult.length == 0) {
            position = {
              position: result[i][0],
              symbol: result[i][1],
              action: result[i][2] ?? 0,
              digits: result[i][3],
              digitsCurrency: result[i][4],
              contractSize: result[i][5],
              timeCreate: result[i][6],
              timeUpdate: result[i][7],
              priceOpen: result[i][8],
              priceCurrent: result[i][9],
              priceSL: result[i][10],
              priceTP: result[i][11],
              volume: result[i][12],
              profit: result[i][13],
              reason: result[i][14]
            };
            positionList.push(position);
          }
        }
      }
    } else {
      if (positionList.length > 0) {
        if (result.length != positionList.length) {
          // ตรวจสอบว่าจำนวน position มีการเปลี่ยนแปลงหรือไม่
          if (result.length > positionList.length) {
            // มีการเปิด Position เข้ามาใหม่
            let position = {};
            for (let i = 0; i < result.length; i++) {
              let newPositionResult = positionList.filter((obj) => {
                // หาว่ามี position ไหนที่ยังไม่มีใน chart
                return obj.position === result[i][0];
              });
              if (newPositionResult.length == 0) {
                // ถ้าไม่มีให้เพิ่มเส้น position
                position = {
                  position: result[i][0],
                  symbol: result[i][1],
                  action: result[i][2] ?? 0,
                  digits: result[i][3],
                  digitsCurrency: result[i][4],
                  contractSize: result[i][5],
                  timeCreate: result[i][6],
                  timeUpdate: result[i][7],
                  priceOpen: result[i][8],
                  priceCurrent: result[i][9],
                  priceSL: result[i][10],
                  priceTP: result[i][11],
                  volume: result[i][12],
                  profit: result[i][13],
                  reason: result[i][14]
                };

                if (position.priceSL != undefined) {
                  drawSLLine(position.priceSL, tvWidget, position.digits);
                }

                if (position.priceTP != undefined) {
                  drawTPLine(position.priceTP, tvWidget, position.digits);
                }

                positionList.push(position);
              }
            }
          } else if (result.length < positionList.length) {
            for (let i = 0; i < positionList.length; i++) {
              let positionResult = result.filter((obj) => {
                return obj[0] === positionList[i].position;
              });

              if (positionResult.length == 0) {
                const indexOfLine = positionLine.findIndex((object) => {
                  return object.position === positionList[i].position;
                });
                if (indexOfLine !== -1) {
                  let positionLineData = positionLine[indexOfLine].pLine;
                  let slLineData = positionLine[indexOfLine].lineSl;
                  let tpLineData = positionLine[indexOfLine].lineTp;
                  if (slLineData !== null) {
                    tvWidget.activeChart().removeEntity(slLineData);
                  }

                  if (tpLineData !== null) {
                    tvWidget.activeChart().removeEntity(tpLineData);
                  }
                  positionLineData.remove();
                  positionLine.splice(indexOfLine, 1);
                  positionList.splice(i, 1);
                }
              }
            }
          }
        } else {
          for (let i = 0; i < result.length; i++) {
            if (positionList[i].volume != result[i][12]) {
              positionLine[i].volume = result[i][12];
              const indexOfLine = positionLine.findIndex((object) => {
                return object.position === positionList[i].position;
              });

              if (indexOfLine != -1) {
                let positionLineData = positionLine[indexOfLine].pLine;
                let quantity = mapText(positionList[i].priceOpen, result[i][12]);
                positionLineData.setQuantity(quantity);
              }
            }

            let sl = null;
            let tp = null;

            if (positionList[i].priceSL !== result[i][10]) {
              const indexOfLine = positionLine.findIndex((object) => {
                return object.position === positionList[i].position;
              });

              let slLineData = positionLine[indexOfLine].lineSl;
              tvWidget.activeChart().removeEntity(slLineData);
              if (result[i][10] != undefined) {
                sl = drawSLLine(result[i][10], tvWidget, result[i][3]);
              }
              positionList[i].priceSL = result[i][10];
              positionLine[indexOfLine].lineSl = sl;
            }

            if (positionList[i].priceTP != result[i][11]) {
              const indexOfLine = positionLine.findIndex((object) => {
                return object.position === positionList[i].position;
              });
              let tpLineData = positionLine[indexOfLine].lineTp;
              tvWidget.activeChart().removeEntity(tpLineData);
              if (result[i][11] != undefined) {
                tp = drawTPLine(result[i][11], tvWidget, result[i][3]);
              }
              positionList[i].priceTP = result[i][11];
              positionLine[indexOfLine].lineTp = tp;
            }
          }
        }
      } else {
        // ดึง position มาเก็บในตัวแปร
        if (result.length > 0) {
          let position = {};
          for (let i = 0; i < result.length; i++) {
            position = {
              position: result[i][0],
              symbol: result[i][1],
              action: result[i][2] ?? 0,
              digits: result[i][3],
              digitsCurrency: result[i][4],
              contractSize: result[i][5],
              timeCreate: result[i][6],
              timeUpdate: result[i][7],
              priceOpen: result[i][8],
              priceCurrent: result[i][9],
              priceSL: result[i][10],
              priceTP: result[i][11],
              volume: result[i][12],
              profit: result[i][13],
              reason: result[i][14]
            };
            positionList.push(position);
          }
        }
      }

      if (positionList.length > 0) {
        // วาดเส้น position
        for (let i = 0; i < positionList.length; i++) {
          let result = positionLine.filter((obj) => {
            return obj.position === positionList[i].position;
          });

          let quantity = mapText(positionList[i].priceOpen, positionList[i].volume);
          let text = mapActionType(positionList[i].action);

          if (result.length == 0) {
            try {
              let sl = null;
              let tp = null;
              let position = tvWidget
                .activeChart()
                .createPositionLine(false)
                .setText(text)
                .setQuantity(quantity)
                .setPrice(positionList[i].priceOpen)
                .setExtendLeft(false)
                .setLineStyle(0)
                .setLineLength(100)
                .setLineColor(mapColor(positionList[i].action))
                .setBodyBorderColor('rgba(255, 255, 255, 0.95)')
                .setQuantityBorderColor(mapColor(positionList[i].action))
                .setQuantityBackgroundColor(mapColor(positionList[i].action));

              if (positionList[i].priceSL != undefined) {
                sl = drawSLLine(positionList[i].priceSL, tvWidget, positionList[i].digits);
              }

              if (positionList[i].priceTP != undefined) {
                tp = drawTPLine(positionList[i].priceTP, tvWidget, positionList[i].digits);
              }

              let data = {
                position: positionList[i].position,
                volume: positionList[i].volume,
                pLine: position,
                lineSl: sl,
                lineTp: tp
              };

              positionLine.push(data);
            } catch (err) {
              return;
            }
          }
        }
      }
    }
  } catch (error) {
    return;
  }
}

// Draw SL line
function drawSLLine(price, tvWidget, digit) {
  let sl = 'SL at ' + price.toFixed(digit);
  return tvWidget.activeChart().createShape(
    { price: price },
    {
      shape: 'horizontal_line',
      disableSelection: true,
      disableSave: true,
      disableUndo: true,
      showInObjectsTree: false,
      text: sl,
      lock: true,
      zOrder: 'bottom',
      overrides: {
        showLabel: true,
        showTime: false,
        linestyle: 2,
        linecolor: '#C94644',
        horzLabelsAlign: 'left',
        vertLabelsAlign: 'bottom',
        textcolor: '#C94644',
        showPrice: false
      }
    }
  );
}

// Draw TP line
function drawTPLine(price, tvWidget, digit) {
  let tp = 'TP at ' + price.toFixed(digit);
  return tvWidget.activeChart().createShape(
    { price: price },
    {
      shape: 'horizontal_line',
      disableSelection: true,
      disableSave: true,
      disableUndo: true,
      showInObjectsTree: false,
      text: tp,
      lock: true,
      zOrder: 'bottom',
      overrides: {
        showLabel: true,
        showTime: false,
        linestyle: 2,
        linecolor: '#039981',
        horzLabelsAlign: 'left',
        vertLabelsAlign: 'bottom',
        textcolor: '#039981',
        showPrice: false
      }
    }
  );
}

function mapText(priceOpen, volume) {
  let text = '';
  let lot = 0;
  lot = volume / 10000;

  text = lot + ' Lot at ' + priceOpen;

  return text;
}
