import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bidask: {
    ['']: {
      bid: 0,
      ask: 0
    }
  }
};

const bidaskSlice = createSlice({
  name: 'bidask',
  initialState,
  reducers: {
    setBidAsk(state, action) {
      state.bidask = {
        ...state.bidask,
        [action.payload.symbol]: {
          bid: action.payload.bid,
          ask: action.payload.ask
        }
      };
    }
  }
});

export const { setBidAsk } = bidaskSlice.actions;

const bidaskReducer = bidaskSlice.reducer;
export default bidaskReducer;
