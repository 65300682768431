import React, { memo, useEffect, useMemo, useState } from 'react'
import {
  makeMenuSelector,
  makePictureSelector, makePositionNoReduceSelector,
  makePositionSelector,
  makeTradeDialogSelector,
} from '../../../redux/selector'
import { useAppDispatch, useAppSelector } from '../../../redux/app'
import useAuth from '../../../hooks/useAuth'
import { closePositionApi } from '../../../api/trade'
import { Notification } from '../../../utilities/alert'
import $ from 'jquery'
import { setMenuSymbol, setSymbolActive } from '../../../redux/reducer/instruments'
import { setEditDialog } from '../../../redux/reducer/edit-dialog'
import moment from 'moment'
import { actionsTypes, getAccountTradeId } from '../../../config'
import WorkerController from '../../../worker/position/position-worker'

const List = memo(({ item, index }) => {

  const getTradeDialog = useMemo(makeTradeDialogSelector, [])
  const tradeDialog = useAppSelector((state) => getTradeDialog(state))

  const getMenu = useMemo(makeMenuSelector, [])
  const menu = useAppSelector((state) => getMenu(state))
  
  const dispatch = useAppDispatch()
  const { activeAccount } = useAuth()

  const [loadingTransition, setLoadingTransition] = useState(null)
  const [loading, setLoading] = useState(null)
  const [explan, setExplan] = useState(null)

  useEffect(() => {
    if (explan !== null) {
      setTimeout(() => {
        setLoadingTransition(explan)
      }, 200)
    } else {
      setLoadingTransition(null)
    }
  }, [explan])


  const deletePosition = async (item, index) => {
    try {
      setLoading(index)
      const { data } = await closePositionApi({
        account_trade_id: getAccountTradeId(), ticket: item.position, volume: item.volume,
      })
      Notification({
        title: 'Close position', message: data.data.message, type: 'success',
      })
      setLoading(null)
    } catch ({ response }) {
      Notification({
        message: response.data.error.message, type: 'danger',
      })
      setLoading(null)
    }
  }

  const getContain = (key, item) => {
    if (item.action === 1) {
      if (item.priceSL !== 0) {
        const slPercent = item.priceSL - item.priceOpen
        // const slCurrent = 0 //bidAsk?.bid - item.priceOpen;
        const price = (parseFloat(0).toFixed(item.digits) / parseFloat(slPercent).toFixed(item.digits)) * 100
        if (loadingTransition !== null) {
          if (price > 0) {
            $('.progress-sl-tp').css('--sl-tp-position', `${price.toFixed(0)}px`)
          } else {
            $('.progress-sl-tp').css('--sl-tp-position', `100px`)
          }
        }
      } else {
        $('.progress-sl-tp').css('--sl-tp-position', `0px`)
      }
    }
    if (item.action === 0) {
      if (item.priceTP !== 0) {
        const slPercent = item.priceTP - item.priceOpen
        // const slCurrent = 0
        const percetage = (parseFloat(0).toFixed(item.digits) / parseFloat(slPercent).toFixed(item.digits)) * 100
        if (loadingTransition !== null) {
          if (percetage > 0) {
            $('.progress-sl-tp').css('--sl-tp-position', `${percetage.toFixed(0)}px`)
          } else {
            $('.progress-sl-tp').css('--sl-tp-position', `100px`)
          }
        }
      } else {
        $('.progress-sl-tp').css('--sl-tp-position', `0px`)
      }
    }
  }

  return (
    <div key={index}>
      <div className={`table-body h-[84px] ${explan === index ? 'table-body-open' : ''}`}>
        <div
          className="h-[84px]"
          style={{
            padding: '12px 8px',
            transformOrigin: 'top',
            transition: tradeDialog.type === 'port' ? `transform ${index < 15 ? 0 : 60 * index}ms ease-in` : 'all 0.3ms ease-in',
          }}>
          <div className="h-full">
            <div className="grid grid-cols-[20%_55%_25%] h-full">
              <div>
                <div className="w-10">
                  <img
                    src={`https://storage.googleapis.com/iux-storage/symbol/${item.symbol}.png`}
                    alt="..."
                    onError={({ currentTarget }) => {
                      currentTarget.src = 'globe.svg'
                    }}
                    className="w-full rounded-[50%]"
                  />
                </div>
              </div>
              <div
                className="pointer center-position-ele"
                onClick={(e) => {
                  if (menu.menu[activeAccount].menu?.find((e) => e.symbol === item.symbol)) {
                    dispatch(setSymbolActive(item.symbol))
                  } else {
                    const obj = { symbol: item.symbol, logo: '' }
                    dispatch(setMenuSymbol({ type: 'new', data: obj }))
                  }
                }}>
                <div className="flex items-center gap-2">
                  <div className="text-sm text-white">{item.symbol}</div>
                  <div className="flex items-center gap-2">
                    <div
                      className="text-sm font-bold"
                      style={{
                        color: actionsTypes[item.action]?.color,
                      }}
                    >
                      {actionsTypes[item.action]?.name}
                    </div>
                    <div className="text-sm text-white font-bold ml-2">{((item.volume / 100) * 1) / 100}</div>
                  </div>
                </div>
                <div>
                  <div className="flex w-100 gap-4">
                    <p className="text-xs">
                      {item.priceOpen.toFixed(item.digits)}
                    </p>
                    <p
                      className="flex items-center justify-center">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_2255_631)">
                          <path
                            d="M15.8167 7.55759C15.8165 7.5574 15.8163 7.55719 15.8161 7.557L12.5504 4.307C12.3057 4.06353 11.91 4.06444 11.6665 4.30912C11.423 4.55378 11.4239 4.9495 11.6686 5.193L13.8612 7.375H0.625C0.279813 7.375 0 7.65481 0 8C0 8.34519 0.279813 8.625 0.625 8.625H13.8612L11.6686 10.807C11.4239 11.0505 11.423 11.4462 11.6665 11.6909C11.91 11.9356 12.3058 11.9364 12.5504 11.693L15.8162 8.443C15.8163 8.44281 15.8165 8.44259 15.8167 8.4424C16.0615 8.19809 16.0607 7.80109 15.8167 7.55759Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2255_631">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </p>
                    <p className="text-xs">
                      {item.priceCurrent.toFixed(item.digits)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="right-position-ele">
                <div className="text-end">
                  <p
                    style={{
                      color: item.profit < 0 ? '#BD5B44' : '#1F6FDE'
                    }}>
                    {item.profit?.toFixed(2)}
                  </p>
                </div>
                <div className="action-order flex items-center justify-end">
                  <button
                    className="outline-custom"
                    onClick={() => {
                      if (explan === index) {
                        setExplan(null)
                      } else {
                        setExplan(index)
                      }
                    }}>
                    <svg
                      className={`m-auto ${explan === index ? 'relate-180' : 'rotate-0'}`}
                      width="10"
                      height="10"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.531198 0.528268C0.270851 0.788621 0.270851 1.21073 0.531198 1.47108L4.5312 5.47108C4.79154 5.73141 5.21367 5.73141 5.474 5.47108L9.474 1.47108C9.73434 1.21073 9.73434 0.788621 9.474 0.528268C9.21367 0.267921 8.79154 0.267921 8.5312 0.528268L5.0026 4.05687L1.47401 0.528268C1.21366 0.267921 0.791551 0.267921 0.531198 0.528268Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                  <button
                    className="outline-custom"
                    onClick={() => {
                      dispatch(setEditDialog({ isOpen: true, type: item.action === 0 ? 'buy' : 'sell', data: item }))
                    }}>
                    <svg
                      className="m-auto"
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        {' '}
                        <path
                          d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                          stroke="#fff"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"></path>
                        {' '}
                        <path
                          d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                          stroke="#fff"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"></path>
                        {' '}
                      </g>
                    </svg>
                  </button>
                  <button
                    className="outline-custom"
                    onClick={() => deletePosition(item, index)}
                  >
                    {loading === index ? (<div className="loader-css m-auto"></div>) : (<svg
                      className="m-auto"
                      width="10"
                      height="10"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.586047 12.0001C0.470144 12.0001 0.356837 11.9657 0.26046 11.9014C0.164083 11.837 0.0889654 11.7455 0.0446082 11.6384C0.000250996 11.5313 -0.0113527 11.4135 0.0112649 11.2998C0.0338824 11.1861 0.0897052 11.0817 0.171672 10.9998L10.9998 0.17164C11.1097 0.0617407 11.2588 0 11.4142 0C11.5696 0 11.7186 0.0617407 11.8285 0.17164C11.9384 0.281539 12.0002 0.430594 12.0002 0.586015C12.0002 0.741436 11.9384 0.890491 11.8285 1.00039L1.00042 11.8285C0.946056 11.883 0.881462 11.9262 0.810351 11.9556C0.739239 11.9851 0.663012 12.0002 0.586047 12.0001Z"
                        fill="#BD5B44"
                      />
                      <path
                        d="M11.4141 12.0001C11.3372 12.0002 11.2609 11.9851 11.1898 11.9556C11.1187 11.9262 11.0541 11.883 10.9998 11.8285L0.17164 1.00039C0.0617407 0.890491 0 0.741436 0 0.586015C0 0.430594 0.0617407 0.281539 0.17164 0.17164C0.281539 0.0617407 0.430594 0 0.586015 0C0.741436 0 0.890491 0.0617407 1.00039 0.17164L11.8285 10.9998C11.9105 11.0817 11.9663 11.1861 11.9889 11.2998C12.0115 11.4135 11.9999 11.5313 11.9556 11.6384C11.9112 11.7455 11.8361 11.837 11.7397 11.9014C11.6433 11.9657 11.53 12.0001 11.4141 12.0001Z"
                        fill="#BD5B44"
                      />
                    </svg>)}
                  </button>
                </div>
              </div>
            </div>
            {explan === index && (<>
              <div className="h-[16px]"></div>
              <div
                className={`explan-setting ${explan === loadingTransition ? 'open' : 'close'}`}>
                <div className="flex text-white setting">
                  <div className="tp">
                    <div>TP</div>
                    <div>{item.priceTP !== 0 ? item.priceTP : 'No TP'}</div>
                  </div>
                  <div className="progress">
                    <div className="progress-sl-tp"></div>
                    {getContain(index, item)}
                    <div className="bg-progress-sltp"></div>
                  </div>
                  <div className="sl">
                    <div>SL</div>
                    <div>{item.priceSL !== 0 ? item.priceSL : 'No SL'}</div>
                  </div>
                </div>
                <div className="flex justify-between position-detail-setting">
                  <div className="open-time">Open Time</div>
                  <div className="time-value">
                    {moment.unix(item.timeCreate).utc().format('YYYY.MM.DD HH:MM:SS')}
                  </div>
                </div>
                <div className="flex justify-between position-detail-swap">
                  <div className="open-time">Swap</div>
                  <div className="time-value">0</div>
                </div>
              </div>
            </>)}
          </div>
        </div>
      </div>
    </div>)
})

List.displayName = 'List'

const Position = memo(() => {
  const getPositionState = useMemo(makePositionNoReduceSelector, [])
  const positions = useAppSelector((state) => getPositionState(state))

  const { data } = WorkerController()
  
  useEffect(() => {
    // useWorker({ type: 'position', data: positions })
  }, [positions])
  
  return (
    <div>
      {data.map((ele, i) => (<List item={ele} index={i} key={i} />))}
    </div>
  )
})

Position.displayName = 'Position'

export default Position
