const events = {
  ADD_COMMENTS: ''
};

const EventEmitter = {
  dispatch: function (event, data) {
    if (!events[event]) return;
    events[event].forEach((callback) => callback(data));
  },
  subscribe: function (event, callback) {
    if (!events[event]) events[event] = [];
    events[event].push(callback);
  },
  unsubscribe: function (event) {
    events[event] = [];
    return;
  }
};

export default EventEmitter;
