let worker;

export const initializeWorker = () => {
  if (!worker) {
    // eslint-disable-next-line no-undef
    worker = new Worker(new URL('./worker.js', import.meta.url));  // Webpack setup for worker
  }
  return worker;
};

export const postMessageToWorker = (message) => {
  if (worker) {
    worker.postMessage(message);
  }
};

export const addWorkerMessageListener = (callback) => {
  if (worker) {
    worker.onmessage = callback;
  }
};

export const terminateWorker = () => {
  if (worker) {
    worker.terminate();
    worker = null;
  }
};