import React, { memo, useState } from 'react'
import BuySellButton from '../ActionButton'
import LotSizeController from '../../../utilities/LotSizeController'
import StopLossAndTakeProfit from '../StopLossAndTakeProfit'
import { useAppDispatch, useAppSelector } from '../../../redux/app'
import { setPayload } from '../../../redux/reducer/payload'
import InputPrice from '../InputPrice'
import ExpirationDate from '../ExpirationDate'
import Expiration from '../Expiration'

const BuyLimitFunction = memo(({ isBuyLimit }) => {
  const payload = useAppSelector((state) => state.payload?.payload)
  const [error, setError] = useState(false)

  return (
    <div>
      <LotSizeController setError={setError} />
      <div className="flex justify-between mt-4 px-[12px]">
        <div className="text-white text-sm">Price</div>
        <div></div>
      </div>
      <InputPrice validate={isBuyLimit ? 2 : 3} className="mx-[10px] mt-2" />
      <div className="px-[12px]">
        <StopLossAndTakeProfit />
      </div>
      <div className="flex justify-between mt-4 px-[12px]">
        <div className="text-white text-sm">Expiration</div>
        <div></div>
      </div>
      <div style={{ margin: 10 }}>
        <Expiration />
      </div>
      {payload.type_time >= 2 ? (
        <ExpirationDate inputClass="mt-2" />
      ) : null}
      <BuySellButton typeBuy={isBuyLimit ? 2 : 3} />
    </div>
  )
})

BuyLimitFunction.displayName = 'BuyLimitFunction'

export default BuyLimitFunction
