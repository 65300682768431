import { post } from '../utilities/http';
const main = process.env.REACT_APP_API_URL;

export const openPosition = async (data) => {
  return post(`${main}/v2/trade/open-position`, data);
};

export const closePositionApi = async (data) => {
  return post(`${main}/v2/trade/close-position`, data);
};

export const deleteOrderApi = async (data) => {
  return post(`${main}/v2/trade/delete-order`, data);
};

export const editPositionApi = async (data) => {
  return post(`${main}/v2/trade/edit-position`, data);
};

export const editOrderApi = async (data) => {
  return post(`${main}/v2/trade/edit-order`, data);
};
