import React, { memo, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { svgEle } from '../svg.js';
import DropDownItem from './item.js';
import { DropDownContext } from './provider.js';
import { createSelector } from '@reduxjs/toolkit';

const getInstrument = createSelector(
  (state) => state,
  ({ symbol }) => symbol
);

const DropDownPanel = () => {
  const { symbolObj, category, setCategory, handleSubScribe } = useContext(DropDownContext);
  const { categories } = useSelector((state) => getInstrument(state));

  const open = useSelector((state) => state?.dropdown?.open ?? false);

  const [position, setPosition] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChangeCategory = async (category) => {
    handleSubScribe(category);
    setCategory(category);
  };

  let isOutOfViewport = function (elem) {
    // Get element's bounding
    let bounding = elem.getBoundingClientRect();

    // Check if it's out of the viewport on each side
    let out = {};
    out.top = bounding.top < 0;
    out.left = bounding.left < 0;
    out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
    out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
    out.any = out.top || out.left || out.bottom || out.right;
    out.all = out.top && out.left && out.bottom && out.right;

    return out;
  };

  useEffect(() => {
    let elem = document.querySelector('#dropdownCard');
    let isOut = isOutOfViewport(elem);
    if (open) {
      setTimeout(() => {
        setLoading(true);
      }, 100);
      if (isOut.right) {
        setPosition(true);
      } else {
        setPosition(false);
      }
    }
  }, [open]);

  return (
    <div
      id="dropdownCard"
      className={`dropdown-symbol ${position && 'fixed right-[1px]'}`}
      style={{
        display: open ? 'block' : 'none',
        top: '64px'
      }}
    >
      <div className="bg-dropdown">
        <div
          style={{
            transformOrigin: 'top',
            transform: loading
              ? 'perspective(65em) rotateX(360deg)'
              : 'perspective(56em) rotateX(348deg)',
            transition: loading ? `all 0.3s ease-in-out` : `all 0.3s ease-in-out`
          }}
        >
          {categories ? (
            Object.values(categories).map((e, i) => (
              <div
                key={e}
                onClick={() => {
                  handleChangeCategory(e);
                }}
              >
                <div
                  className={`dropdow-item ${e === category ? 'active' : ''} flex justify-between`}
                  style={{
                    borderBottom:
                      Object.values(categories).length - 1 !== i
                        ? e !== category
                          ? '0px solid rgb(131 138 161 / 15%)'
                          : '0px solid rgb(54 59 76)'
                        : '0px solid rgb(54 59 76)',
                    minWidth: '200px',
                    opacity: loading ? 1 : 0,
                    transformOrigin: 'top',
                    transform: loading
                      ? 'perspective(75em) rotateX(360deg)'
                      : 'perspective(26em) rotateX(300deg)',
                    transition: loading ? `transform ${100 * i}ms ease-in-out` : 'none'
                  }}
                >
                  <div className="flex items-center">
                    <div>{svgEle[e]}</div>
                    <div className="text-[#c1c4d1] ml-2">{e}</div>
                  </div>
                  <div className="text-[#c1c4d1] flex items-center">
                    {symbolObj ? (symbolObj[e]?.length > 0 ? symbolObj[e]?.length : '') : ''}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div />
          )}
        </div>
        <DropDownItem />
      </div>
    </div>
  );
};

export default DropDownPanel;
