import { serverApiConfig } from '../config';
import { useAppDispatch } from '../redux/app.js';
import { setStat } from '../redux/reducer/stat.js';

const { SymbolClient } = require('../grpc/generated/symbol_grpc_web_pb.js');

const StatsData = () => {
  const dispatch = useAppDispatch();
  const subscribeStat = async (request, metadata) => {
    try {
      let symbolClient = new SymbolClient(
        serverApiConfig(localStorage.getItem('trade.server')),
        null,
        null
      );
      await symbolClient.lastTick(request, metadata, (err, response_) => {
        if (response_) {
          let allTickData = [];
          let allStatData = [];
          response_.array[0].forEach((item) => {
            let tick = {
              symbol: item[9],
              bid: item[1],
              ask: item[2]
            };
            allTickData.push(tick);
          });
          response_.array[1].forEach((item) => {
            let stat = {
              symbol: item[0],
              priceOpen: item[25],
              priceClose: item[26]
            };
            allStatData.push(stat);
          });
          const a = { tick: allTickData, stat: allStatData };
          // console.log('stat', a.stat[57])
          // console.log('stat', a.tick[57])
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  return { subscribeStat };
};

export default StatsData;
