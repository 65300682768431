import { createSlice } from '@reduxjs/toolkit';

const initialState = { positions: [] };
const positionSlice = createSlice({
  name: 'positions',
  initialState,
  reducers: {
    setPositions(state, action) {
      state.positions = action.payload;
    }
  }
});

export const { setPositions } = positionSlice.actions;

const positionReducer = positionSlice.reducer;
export default positionReducer;
