import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  type: ''
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    setTradeDialog(state, action) {
      state.isOpen = action.payload.isOpen;
      state.type = action.payload.type;
    }
  }
});

export const { setTradeDialog } = dialogSlice.actions;

const dialogReducer = dialogSlice.reducer;
export default dialogReducer;
