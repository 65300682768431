import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Navbar = ({ handleChangeLang }) => {
  const lang = localStorage.getItem('i18nextLng');

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <img className="h-[82px]" src="/images/iuxtrade/IUXTrade-main-logo.png" alt="iux.com" />
      </div>
      <div>
        <Popover key={'lang'} className="relative mr-4">
          {({ open, close }) => (
            <>
              <Popover.Button
                className={classNames(
                  open ? 'text-white' : 'text-white',
                  'text-base group inline-flex items-center rounded-md text-body-4 font-medium focus:outline-none 2xl:text-body-3'
                )}>
                <div className="mr-1">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.99984 0.666504C12.0499 0.666504 15.3332 3.94975 15.3332 7.99984C15.3332 12.0499 12.0499 15.3332 7.99984 15.3332C3.94975 15.3332 0.666504 12.0499 0.666504 7.99984C0.666504 3.94975 3.94975 0.666504 7.99984 0.666504ZM2.0436 7.27144C2.23796 7.5202 2.77838 7.83849 3.60534 8.09818C3.93096 8.20043 4.28757 8.29032 4.67021 8.36665C4.66774 8.24509 4.6665 8.12279 4.6665 7.99984C4.6665 5.78141 5.07021 3.77804 5.76954 2.42804C3.77592 3.22678 2.31069 5.0645 2.0436 7.27144ZM4.75259 9.74039C3.68206 9.55431 2.76413 9.27269 2.06848 8.91036C2.39208 11.036 3.83102 12.795 5.76954 13.5716C5.25895 12.586 4.90595 11.252 4.75259 9.74039ZM6.11911 9.91806C6.71718 9.97193 7.34709 9.99984 7.99984 9.99984C8.64205 9.99984 9.2748 9.96344 9.88336 9.89593C9.58264 12.2943 8.75117 13.9998 7.99984 13.9998C7.25082 13.9998 6.42215 12.3048 6.11911 9.91806ZM9.9907 8.53618C9.35766 8.61816 8.6852 8.6665 7.99984 8.6665C7.30369 8.6665 6.63544 8.63203 6.01005 8.56665C6.00331 8.38021 5.99984 8.19115 5.99984 7.99984C5.99984 4.65583 7.06224 1.99984 7.99984 1.99984C8.93744 1.99984 9.99984 4.65583 9.99984 7.99984C9.99984 8.18073 9.99673 8.35962 9.9907 8.53618ZM11.2527 9.68443C11.1024 11.2191 10.747 12.5738 10.2301 13.5716C12.2029 12.7813 13.6582 10.9736 13.9473 8.79745C13.2405 9.16507 12.3096 9.47248 11.2527 9.68443ZM13.9522 7.23992C13.6385 7.59205 12.649 8.01033 11.3307 8.30259C11.3323 8.20215 11.3332 8.10122 11.3332 7.99984C11.3332 5.78141 10.9295 3.77804 10.2301 2.42804C12.2143 3.22298 13.675 5.04701 13.9522 7.23992Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <span className="uppercase">{lang === 'en' ? 'EN' : '日本語'}</span>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1">
                <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen  max-w-[200px] transform sm:px-0 right-0 lg:ml-0 lg:-translate-x-1">
                  <div className="overflow-hidden rounded-lg bg-white px-[8px] py-[8px] text-Color/background shadow-lg ring-opacity-5">
                    <div className="flex flex-col gap-2">
                      <div
                        onClick={() => handleChangeLang('ja')}
                        className="flex items-center gap-[10px] cursor-pointer w-full hover:bg-[#1CB8941A] text-[#6B6B6B] hover:text-black rounded-lg px-2 h-[32px]">
                        <div>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                              fill="#F0F0F0"
                            />
                            <path
                              d="M8.00022 11.4785C9.92121 11.4785 11.4785 9.92121 11.4785 8.00022C11.4785 6.07924 9.92121 4.52197 8.00022 4.52197C6.07924 4.52197 4.52197 6.07924 4.52197 8.00022C4.52197 9.92121 6.07924 11.4785 8.00022 11.4785Z"
                              fill="#D80027"
                            />
                          </svg>
                        </div>
                        <div>日本語</div>
                      </div>
                      <div
                        onClick={() => handleChangeLang('en')}
                        className="flex items-center gap-[10px] cursor-pointer w-full hover:bg-[#1CB8941A] text-[#6B6B6B] hover:text-black rounded-lg px-2 h-[32px]">
                        <div>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                              fill="#F0F0F0"
                            />
                            <path
                              d="M1.65355 3.12939C1.02514 3.94699 0.551297 4.88933 0.275391 5.91305H4.4372L1.65355 3.12939Z"
                              fill="#0052B4"
                            />
                            <path
                              d="M15.7242 5.91302C15.4483 4.88933 14.9745 3.94699 14.3461 3.12939L11.5625 5.91302H15.7242Z"
                              fill="#0052B4"
                            />
                            <path
                              d="M0.275391 10.0869C0.551328 11.1106 1.02517 12.0529 1.65355 12.8705L4.43711 10.0869H0.275391Z"
                              fill="#0052B4"
                            />
                            <path
                              d="M12.8705 1.65358C12.0529 1.02517 11.1106 0.551328 10.0869 0.275391V4.43717L12.8705 1.65358Z"
                              fill="#0052B4"
                            />
                            <path
                              d="M3.12939 14.3461C3.94699 14.9745 4.88933 15.4483 5.91302 15.7242V11.5625L3.12939 14.3461Z"
                              fill="#0052B4"
                            />
                            <path
                              d="M5.91299 0.275391C4.8893 0.551328 3.94696 1.02517 3.12939 1.65355L5.91299 4.43714V0.275391Z"
                              fill="#0052B4"
                            />
                            <path
                              d="M10.0869 15.7242C11.1106 15.4483 12.0529 14.9745 12.8705 14.3461L10.0869 11.5625V15.7242Z"
                              fill="#0052B4"
                            />
                            <path
                              d="M11.5625 10.0869L14.3461 12.8705C14.9745 12.053 15.4483 11.1106 15.7242 10.0869H11.5625Z"
                              fill="#0052B4"
                            />
                            <path
                              d="M15.9323 6.95653H9.04353H9.0435V0.0677188C8.70191 0.02325 8.35366 0 8 0C7.64628 0 7.29809 0.02325 6.95653 0.0677188V6.95647V6.9565H0.0677188C0.02325 7.29809 0 7.64634 0 8C0 8.35372 0.02325 8.70191 0.0677188 9.04347H6.95647H6.9565V15.9323C7.29809 15.9768 7.64628 16 8 16C8.35366 16 8.70191 15.9768 9.04347 15.9323V9.04353V9.0435H15.9323C15.9768 8.70191 16 8.35372 16 8C16 7.64634 15.9768 7.29809 15.9323 6.95653Z"
                              fill="#D80027"
                            />
                            <path
                              d="M10.0869 10.0869L13.6568 13.6568C13.821 13.4927 13.9776 13.3211 14.127 13.1432L11.0707 10.0869H10.0869V10.0869Z"
                              fill="#D80027"
                            />
                            <path
                              d="M5.91317 10.0869H5.91311L2.34326 13.6568C2.50739 13.8209 2.67898 13.9776 2.85686 14.127L5.91317 11.0706V10.0869Z"
                              fill="#D80027"
                            />
                            <path
                              d="M5.91317 5.91323V5.91317L2.3433 2.34326C2.17911 2.50739 2.02248 2.67898 1.87305 2.85686L4.92939 5.9132H5.91317V5.91323Z"
                              fill="#D80027"
                            />
                            <path
                              d="M10.0869 5.9132L13.6568 2.34327C13.4927 2.17908 13.3211 2.02245 13.1432 1.87305L10.0869 4.92939V5.9132Z"
                              fill="#D80027"
                            />
                          </svg>
                        </div>
                        <div>English</div>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
};

export default Navbar;
