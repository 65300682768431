// source: src/grpc/iuxprotos/symbol.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.symbol.LastTickReply', null, global);
goog.exportSymbol('proto.symbol.LastTickRequest', null, global);
goog.exportSymbol('proto.symbol.StatData', null, global);
goog.exportSymbol('proto.symbol.SubscribeReply', null, global);
goog.exportSymbol('proto.symbol.SymbolData', null, global);
goog.exportSymbol('proto.symbol.SymbolDataNew', null, global);
goog.exportSymbol('proto.symbol.SymbolGroupReply', null, global);
goog.exportSymbol('proto.symbol.SymbolName', null, global);
goog.exportSymbol('proto.symbol.SymbolReply', null, global);
goog.exportSymbol('proto.symbol.SymbolReplyNew', null, global);
goog.exportSymbol('proto.symbol.SymbolSession', null, global);
goog.exportSymbol('proto.symbol.SymbolSessionDay', null, global);
goog.exportSymbol('proto.symbol.SymbolSessionReply', null, global);
goog.exportSymbol('proto.symbol.SymbolSessionRequest', null, global);
goog.exportSymbol('proto.symbol.TickData', null, global);
goog.exportSymbol('proto.symbol.TickRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.symbol.SymbolName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.symbol.SymbolName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.symbol.SymbolName.displayName = 'proto.symbol.SymbolName';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.symbol.SymbolReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.symbol.SymbolReply.repeatedFields_, null);
};
goog.inherits(proto.symbol.SymbolReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.symbol.SymbolReply.displayName = 'proto.symbol.SymbolReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.symbol.SymbolReplyNew = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.symbol.SymbolReplyNew.repeatedFields_, null);
};
goog.inherits(proto.symbol.SymbolReplyNew, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.symbol.SymbolReplyNew.displayName = 'proto.symbol.SymbolReplyNew';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.symbol.SubscribeReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.symbol.SubscribeReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.symbol.SubscribeReply.displayName = 'proto.symbol.SubscribeReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.symbol.LastTickRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.symbol.LastTickRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.symbol.LastTickRequest.displayName = 'proto.symbol.LastTickRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.symbol.LastTickReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.symbol.LastTickReply.repeatedFields_, null);
};
goog.inherits(proto.symbol.LastTickReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.symbol.LastTickReply.displayName = 'proto.symbol.LastTickReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.symbol.TickRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.symbol.TickRequest.repeatedFields_, null);
};
goog.inherits(proto.symbol.TickRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.symbol.TickRequest.displayName = 'proto.symbol.TickRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.symbol.SymbolSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.symbol.SymbolSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.symbol.SymbolSessionRequest.displayName = 'proto.symbol.SymbolSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.symbol.SymbolSessionReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.symbol.SymbolSessionReply.repeatedFields_, null);
};
goog.inherits(proto.symbol.SymbolSessionReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.symbol.SymbolSessionReply.displayName = 'proto.symbol.SymbolSessionReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.symbol.SymbolSessionDay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.symbol.SymbolSessionDay.repeatedFields_, null);
};
goog.inherits(proto.symbol.SymbolSessionDay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.symbol.SymbolSessionDay.displayName = 'proto.symbol.SymbolSessionDay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.symbol.SymbolSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.symbol.SymbolSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.symbol.SymbolSession.displayName = 'proto.symbol.SymbolSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.symbol.SymbolGroupReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.symbol.SymbolGroupReply.repeatedFields_, null);
};
goog.inherits(proto.symbol.SymbolGroupReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.symbol.SymbolGroupReply.displayName = 'proto.symbol.SymbolGroupReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.symbol.SymbolData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.symbol.SymbolData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.symbol.SymbolData.displayName = 'proto.symbol.SymbolData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.symbol.SymbolDataNew = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.symbol.SymbolDataNew, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.symbol.SymbolDataNew.displayName = 'proto.symbol.SymbolDataNew';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.symbol.TickData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.symbol.TickData.repeatedFields_, null);
};
goog.inherits(proto.symbol.TickData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.symbol.TickData.displayName = 'proto.symbol.TickData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.symbol.StatData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.symbol.StatData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.symbol.StatData.displayName = 'proto.symbol.StatData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.symbol.SymbolName.prototype.toObject = function(opt_includeInstance) {
  return proto.symbol.SymbolName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.symbol.SymbolName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolName.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.symbol.SymbolName}
 */
proto.symbol.SymbolName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.symbol.SymbolName;
  return proto.symbol.SymbolName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.symbol.SymbolName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.symbol.SymbolName}
 */
proto.symbol.SymbolName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.symbol.SymbolName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.symbol.SymbolName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.symbol.SymbolName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.symbol.SymbolName.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolName} returns this
 */
proto.symbol.SymbolName.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.symbol.SymbolReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.symbol.SymbolReply.prototype.toObject = function(opt_includeInstance) {
  return proto.symbol.SymbolReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.symbol.SymbolReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolsList: jspb.Message.toObjectList(msg.getSymbolsList(),
    proto.symbol.SymbolData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.symbol.SymbolReply}
 */
proto.symbol.SymbolReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.symbol.SymbolReply;
  return proto.symbol.SymbolReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.symbol.SymbolReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.symbol.SymbolReply}
 */
proto.symbol.SymbolReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.symbol.SymbolData;
      reader.readMessage(value,proto.symbol.SymbolData.deserializeBinaryFromReader);
      msg.addSymbols(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.symbol.SymbolReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.symbol.SymbolReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.symbol.SymbolReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.symbol.SymbolData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SymbolData symbols = 1;
 * @return {!Array<!proto.symbol.SymbolData>}
 */
proto.symbol.SymbolReply.prototype.getSymbolsList = function() {
  return /** @type{!Array<!proto.symbol.SymbolData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.symbol.SymbolData, 1));
};


/**
 * @param {!Array<!proto.symbol.SymbolData>} value
 * @return {!proto.symbol.SymbolReply} returns this
*/
proto.symbol.SymbolReply.prototype.setSymbolsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.symbol.SymbolData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.symbol.SymbolData}
 */
proto.symbol.SymbolReply.prototype.addSymbols = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.symbol.SymbolData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.symbol.SymbolReply} returns this
 */
proto.symbol.SymbolReply.prototype.clearSymbolsList = function() {
  return this.setSymbolsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.symbol.SymbolReplyNew.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.symbol.SymbolReplyNew.prototype.toObject = function(opt_includeInstance) {
  return proto.symbol.SymbolReplyNew.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.symbol.SymbolReplyNew} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolReplyNew.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolsList: jspb.Message.toObjectList(msg.getSymbolsList(),
    proto.symbol.SymbolDataNew.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.symbol.SymbolReplyNew}
 */
proto.symbol.SymbolReplyNew.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.symbol.SymbolReplyNew;
  return proto.symbol.SymbolReplyNew.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.symbol.SymbolReplyNew} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.symbol.SymbolReplyNew}
 */
proto.symbol.SymbolReplyNew.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.symbol.SymbolDataNew;
      reader.readMessage(value,proto.symbol.SymbolDataNew.deserializeBinaryFromReader);
      msg.addSymbols(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.symbol.SymbolReplyNew.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.symbol.SymbolReplyNew.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.symbol.SymbolReplyNew} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolReplyNew.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.symbol.SymbolDataNew.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SymbolDataNew symbols = 1;
 * @return {!Array<!proto.symbol.SymbolDataNew>}
 */
proto.symbol.SymbolReplyNew.prototype.getSymbolsList = function() {
  return /** @type{!Array<!proto.symbol.SymbolDataNew>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.symbol.SymbolDataNew, 1));
};


/**
 * @param {!Array<!proto.symbol.SymbolDataNew>} value
 * @return {!proto.symbol.SymbolReplyNew} returns this
*/
proto.symbol.SymbolReplyNew.prototype.setSymbolsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.symbol.SymbolDataNew=} opt_value
 * @param {number=} opt_index
 * @return {!proto.symbol.SymbolDataNew}
 */
proto.symbol.SymbolReplyNew.prototype.addSymbols = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.symbol.SymbolDataNew, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.symbol.SymbolReplyNew} returns this
 */
proto.symbol.SymbolReplyNew.prototype.clearSymbolsList = function() {
  return this.setSymbolsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.symbol.SubscribeReply.prototype.toObject = function(opt_includeInstance) {
  return proto.symbol.SubscribeReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.symbol.SubscribeReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SubscribeReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.symbol.SubscribeReply}
 */
proto.symbol.SubscribeReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.symbol.SubscribeReply;
  return proto.symbol.SubscribeReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.symbol.SubscribeReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.symbol.SubscribeReply}
 */
proto.symbol.SubscribeReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.symbol.SubscribeReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.symbol.SubscribeReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.symbol.SubscribeReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SubscribeReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.symbol.SubscribeReply.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SubscribeReply} returns this
 */
proto.symbol.SubscribeReply.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.symbol.SubscribeReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SubscribeReply} returns this
 */
proto.symbol.SubscribeReply.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.symbol.LastTickRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.symbol.LastTickRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.symbol.LastTickRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.LastTickRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.symbol.LastTickRequest}
 */
proto.symbol.LastTickRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.symbol.LastTickRequest;
  return proto.symbol.LastTickRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.symbol.LastTickRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.symbol.LastTickRequest}
 */
proto.symbol.LastTickRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.symbol.LastTickRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.symbol.LastTickRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.symbol.LastTickRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.LastTickRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.symbol.LastTickRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.LastTickRequest} returns this
 */
proto.symbol.LastTickRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.symbol.LastTickReply.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.symbol.LastTickReply.prototype.toObject = function(opt_includeInstance) {
  return proto.symbol.LastTickReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.symbol.LastTickReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.LastTickReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    ticksList: jspb.Message.toObjectList(msg.getTicksList(),
    proto.symbol.TickData.toObject, includeInstance),
    statsList: jspb.Message.toObjectList(msg.getStatsList(),
    proto.symbol.StatData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.symbol.LastTickReply}
 */
proto.symbol.LastTickReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.symbol.LastTickReply;
  return proto.symbol.LastTickReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.symbol.LastTickReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.symbol.LastTickReply}
 */
proto.symbol.LastTickReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.symbol.TickData;
      reader.readMessage(value,proto.symbol.TickData.deserializeBinaryFromReader);
      msg.addTicks(value);
      break;
    case 2:
      var value = new proto.symbol.StatData;
      reader.readMessage(value,proto.symbol.StatData.deserializeBinaryFromReader);
      msg.addStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.symbol.LastTickReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.symbol.LastTickReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.symbol.LastTickReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.LastTickReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTicksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.symbol.TickData.serializeBinaryToWriter
    );
  }
  f = message.getStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.symbol.StatData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TickData ticks = 1;
 * @return {!Array<!proto.symbol.TickData>}
 */
proto.symbol.LastTickReply.prototype.getTicksList = function() {
  return /** @type{!Array<!proto.symbol.TickData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.symbol.TickData, 1));
};


/**
 * @param {!Array<!proto.symbol.TickData>} value
 * @return {!proto.symbol.LastTickReply} returns this
*/
proto.symbol.LastTickReply.prototype.setTicksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.symbol.TickData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.symbol.TickData}
 */
proto.symbol.LastTickReply.prototype.addTicks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.symbol.TickData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.symbol.LastTickReply} returns this
 */
proto.symbol.LastTickReply.prototype.clearTicksList = function() {
  return this.setTicksList([]);
};


/**
 * repeated StatData stats = 2;
 * @return {!Array<!proto.symbol.StatData>}
 */
proto.symbol.LastTickReply.prototype.getStatsList = function() {
  return /** @type{!Array<!proto.symbol.StatData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.symbol.StatData, 2));
};


/**
 * @param {!Array<!proto.symbol.StatData>} value
 * @return {!proto.symbol.LastTickReply} returns this
*/
proto.symbol.LastTickReply.prototype.setStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.symbol.StatData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.symbol.StatData}
 */
proto.symbol.LastTickReply.prototype.addStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.symbol.StatData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.symbol.LastTickReply} returns this
 */
proto.symbol.LastTickReply.prototype.clearStatsList = function() {
  return this.setStatsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.symbol.TickRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.symbol.TickRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.symbol.TickRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.symbol.TickRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.TickRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolsList: jspb.Message.toObjectList(msg.getSymbolsList(),
    proto.symbol.SymbolName.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.symbol.TickRequest}
 */
proto.symbol.TickRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.symbol.TickRequest;
  return proto.symbol.TickRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.symbol.TickRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.symbol.TickRequest}
 */
proto.symbol.TickRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.symbol.SymbolName;
      reader.readMessage(value,proto.symbol.SymbolName.deserializeBinaryFromReader);
      msg.addSymbols(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.symbol.TickRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.symbol.TickRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.symbol.TickRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.TickRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.symbol.SymbolName.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SymbolName symbols = 1;
 * @return {!Array<!proto.symbol.SymbolName>}
 */
proto.symbol.TickRequest.prototype.getSymbolsList = function() {
  return /** @type{!Array<!proto.symbol.SymbolName>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.symbol.SymbolName, 1));
};


/**
 * @param {!Array<!proto.symbol.SymbolName>} value
 * @return {!proto.symbol.TickRequest} returns this
*/
proto.symbol.TickRequest.prototype.setSymbolsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.symbol.SymbolName=} opt_value
 * @param {number=} opt_index
 * @return {!proto.symbol.SymbolName}
 */
proto.symbol.TickRequest.prototype.addSymbols = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.symbol.SymbolName, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.symbol.TickRequest} returns this
 */
proto.symbol.TickRequest.prototype.clearSymbolsList = function() {
  return this.setSymbolsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.symbol.SymbolSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.symbol.SymbolSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.symbol.SymbolSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.symbol.SymbolSessionRequest}
 */
proto.symbol.SymbolSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.symbol.SymbolSessionRequest;
  return proto.symbol.SymbolSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.symbol.SymbolSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.symbol.SymbolSessionRequest}
 */
proto.symbol.SymbolSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.symbol.SymbolSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.symbol.SymbolSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.symbol.SymbolSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.symbol.SymbolSessionRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolSessionRequest} returns this
 */
proto.symbol.SymbolSessionRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.symbol.SymbolSessionReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.symbol.SymbolSessionReply.prototype.toObject = function(opt_includeInstance) {
  return proto.symbol.SymbolSessionReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.symbol.SymbolSessionReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolSessionReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    weekList: jspb.Message.toObjectList(msg.getWeekList(),
    proto.symbol.SymbolSessionDay.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.symbol.SymbolSessionReply}
 */
proto.symbol.SymbolSessionReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.symbol.SymbolSessionReply;
  return proto.symbol.SymbolSessionReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.symbol.SymbolSessionReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.symbol.SymbolSessionReply}
 */
proto.symbol.SymbolSessionReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.symbol.SymbolSessionDay;
      reader.readMessage(value,proto.symbol.SymbolSessionDay.deserializeBinaryFromReader);
      msg.addWeek(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.symbol.SymbolSessionReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.symbol.SymbolSessionReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.symbol.SymbolSessionReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolSessionReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWeekList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.symbol.SymbolSessionDay.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SymbolSessionDay week = 1;
 * @return {!Array<!proto.symbol.SymbolSessionDay>}
 */
proto.symbol.SymbolSessionReply.prototype.getWeekList = function() {
  return /** @type{!Array<!proto.symbol.SymbolSessionDay>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.symbol.SymbolSessionDay, 1));
};


/**
 * @param {!Array<!proto.symbol.SymbolSessionDay>} value
 * @return {!proto.symbol.SymbolSessionReply} returns this
*/
proto.symbol.SymbolSessionReply.prototype.setWeekList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.symbol.SymbolSessionDay=} opt_value
 * @param {number=} opt_index
 * @return {!proto.symbol.SymbolSessionDay}
 */
proto.symbol.SymbolSessionReply.prototype.addWeek = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.symbol.SymbolSessionDay, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.symbol.SymbolSessionReply} returns this
 */
proto.symbol.SymbolSessionReply.prototype.clearWeekList = function() {
  return this.setWeekList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.symbol.SymbolSessionDay.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.symbol.SymbolSessionDay.prototype.toObject = function(opt_includeInstance) {
  return proto.symbol.SymbolSessionDay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.symbol.SymbolSessionDay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolSessionDay.toObject = function(includeInstance, msg) {
  var f, obj = {
    wday: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sessionList: jspb.Message.toObjectList(msg.getSessionList(),
    proto.symbol.SymbolSession.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.symbol.SymbolSessionDay}
 */
proto.symbol.SymbolSessionDay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.symbol.SymbolSessionDay;
  return proto.symbol.SymbolSessionDay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.symbol.SymbolSessionDay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.symbol.SymbolSessionDay}
 */
proto.symbol.SymbolSessionDay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWday(value);
      break;
    case 2:
      var value = new proto.symbol.SymbolSession;
      reader.readMessage(value,proto.symbol.SymbolSession.deserializeBinaryFromReader);
      msg.addSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.symbol.SymbolSessionDay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.symbol.SymbolSessionDay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.symbol.SymbolSessionDay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolSessionDay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWday();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSessionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.symbol.SymbolSession.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 wday = 1;
 * @return {number}
 */
proto.symbol.SymbolSessionDay.prototype.getWday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolSessionDay} returns this
 */
proto.symbol.SymbolSessionDay.prototype.setWday = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated SymbolSession session = 2;
 * @return {!Array<!proto.symbol.SymbolSession>}
 */
proto.symbol.SymbolSessionDay.prototype.getSessionList = function() {
  return /** @type{!Array<!proto.symbol.SymbolSession>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.symbol.SymbolSession, 2));
};


/**
 * @param {!Array<!proto.symbol.SymbolSession>} value
 * @return {!proto.symbol.SymbolSessionDay} returns this
*/
proto.symbol.SymbolSessionDay.prototype.setSessionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.symbol.SymbolSession=} opt_value
 * @param {number=} opt_index
 * @return {!proto.symbol.SymbolSession}
 */
proto.symbol.SymbolSessionDay.prototype.addSession = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.symbol.SymbolSession, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.symbol.SymbolSessionDay} returns this
 */
proto.symbol.SymbolSessionDay.prototype.clearSessionList = function() {
  return this.setSessionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.symbol.SymbolSession.prototype.toObject = function(opt_includeInstance) {
  return proto.symbol.SymbolSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.symbol.SymbolSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    openHours: jspb.Message.getFieldWithDefault(msg, 1, 0),
    openMinutes: jspb.Message.getFieldWithDefault(msg, 2, 0),
    closeHours: jspb.Message.getFieldWithDefault(msg, 3, 0),
    closeMinutes: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.symbol.SymbolSession}
 */
proto.symbol.SymbolSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.symbol.SymbolSession;
  return proto.symbol.SymbolSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.symbol.SymbolSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.symbol.SymbolSession}
 */
proto.symbol.SymbolSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOpenHours(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOpenMinutes(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCloseHours(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCloseMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.symbol.SymbolSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.symbol.SymbolSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.symbol.SymbolSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpenHours();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getOpenMinutes();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCloseHours();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getCloseMinutes();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 open_hours = 1;
 * @return {number}
 */
proto.symbol.SymbolSession.prototype.getOpenHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolSession} returns this
 */
proto.symbol.SymbolSession.prototype.setOpenHours = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 open_minutes = 2;
 * @return {number}
 */
proto.symbol.SymbolSession.prototype.getOpenMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolSession} returns this
 */
proto.symbol.SymbolSession.prototype.setOpenMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 close_hours = 3;
 * @return {number}
 */
proto.symbol.SymbolSession.prototype.getCloseHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolSession} returns this
 */
proto.symbol.SymbolSession.prototype.setCloseHours = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 close_minutes = 4;
 * @return {number}
 */
proto.symbol.SymbolSession.prototype.getCloseMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolSession} returns this
 */
proto.symbol.SymbolSession.prototype.setCloseMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.symbol.SymbolGroupReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.symbol.SymbolGroupReply.prototype.toObject = function(opt_includeInstance) {
  return proto.symbol.SymbolGroupReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.symbol.SymbolGroupReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolGroupReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    nameList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.symbol.SymbolGroupReply}
 */
proto.symbol.SymbolGroupReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.symbol.SymbolGroupReply;
  return proto.symbol.SymbolGroupReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.symbol.SymbolGroupReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.symbol.SymbolGroupReply}
 */
proto.symbol.SymbolGroupReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.symbol.SymbolGroupReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.symbol.SymbolGroupReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.symbol.SymbolGroupReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolGroupReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNameList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string name = 1;
 * @return {!Array<string>}
 */
proto.symbol.SymbolGroupReply.prototype.getNameList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.symbol.SymbolGroupReply} returns this
 */
proto.symbol.SymbolGroupReply.prototype.setNameList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.symbol.SymbolGroupReply} returns this
 */
proto.symbol.SymbolGroupReply.prototype.addName = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.symbol.SymbolGroupReply} returns this
 */
proto.symbol.SymbolGroupReply.prototype.clearNameList = function() {
  return this.setNameList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.symbol.SymbolData.prototype.toObject = function(opt_includeInstance) {
  return proto.symbol.SymbolData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.symbol.SymbolData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolData.toObject = function(includeInstance, msg) {
  var f, obj = {
    accruedInterest: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    basis: jspb.Message.getFieldWithDefault(msg, 2, ""),
    calcMode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    category: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cfi: jspb.Message.getFieldWithDefault(msg, 5, ""),
    chartMode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    color: jspb.Message.getFieldWithDefault(msg, 7, 0),
    colorBackground: jspb.Message.getFieldWithDefault(msg, 8, 0),
    contractSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    country: jspb.Message.getFieldWithDefault(msg, 10, ""),
    currencyBase: jspb.Message.getFieldWithDefault(msg, 11, ""),
    currencyBaseDigits: jspb.Message.getFieldWithDefault(msg, 12, 0),
    currencyMargin: jspb.Message.getFieldWithDefault(msg, 13, ""),
    currencyMarginDigits: jspb.Message.getFieldWithDefault(msg, 14, 0),
    currencyProfit: jspb.Message.getFieldWithDefault(msg, 15, ""),
    currencyProfitDigits: jspb.Message.getFieldWithDefault(msg, 16, 0),
    description: jspb.Message.getFieldWithDefault(msg, 17, ""),
    digits: jspb.Message.getFieldWithDefault(msg, 18, 0),
    exchange: jspb.Message.getFieldWithDefault(msg, 19, ""),
    execMode: jspb.Message.getFieldWithDefault(msg, 20, ""),
    expirFlags: jspb.Message.getFieldWithDefault(msg, 21, ""),
    faceValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    fillFlags: jspb.Message.getFieldWithDefault(msg, 23, ""),
    filterDiscard: jspb.Message.getFieldWithDefault(msg, 24, 0),
    filterGap: jspb.Message.getFieldWithDefault(msg, 25, 0),
    filterGapTicks: jspb.Message.getFieldWithDefault(msg, 26, 0),
    filterHard: jspb.Message.getFieldWithDefault(msg, 27, 0),
    filterHardTicks: jspb.Message.getFieldWithDefault(msg, 28, 0),
    filterSoft: jspb.Message.getFieldWithDefault(msg, 29, 0),
    filterSoftTicks: jspb.Message.getFieldWithDefault(msg, 30, 0),
    filterSpreadMax: jspb.Message.getFieldWithDefault(msg, 31, 0),
    filterSpreadMin: jspb.Message.getFieldWithDefault(msg, 32, 0),
    freezeLevel: jspb.Message.getFieldWithDefault(msg, 33, 0),
    gtcMode: jspb.Message.getFieldWithDefault(msg, 34, ""),
    ieCheckMode: jspb.Message.getFieldWithDefault(msg, 35, 0),
    ieFlags: jspb.Message.getFieldWithDefault(msg, 36, 0),
    ieSlipLosing: jspb.Message.getFieldWithDefault(msg, 37, 0),
    ieSlipProfit: jspb.Message.getFieldWithDefault(msg, 38, 0),
    ieTimeout: jspb.Message.getFieldWithDefault(msg, 39, 0),
    ieVolumeMax: jspb.Message.getFieldWithDefault(msg, 40, 0),
    ieVolumeMaxExt: jspb.Message.getFieldWithDefault(msg, 41, 0),
    industry: jspb.Message.getFieldWithDefault(msg, 42, ""),
    international: jspb.Message.getFieldWithDefault(msg, 43, ""),
    isin: jspb.Message.getFieldWithDefault(msg, 44, ""),
    marginFlags: jspb.Message.getFieldWithDefault(msg, 45, ""),
    marginHedged: jspb.Message.getFloatingPointFieldWithDefault(msg, 46, 0.0),
    marginInitial: jspb.Message.getFloatingPointFieldWithDefault(msg, 47, 0.0),
    marginLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 48, 0.0),
    marginLong: jspb.Message.getFloatingPointFieldWithDefault(msg, 49, 0.0),
    marginMaintenance: jspb.Message.getFloatingPointFieldWithDefault(msg, 50, 0.0),
    marginRateCurrency: jspb.Message.getFloatingPointFieldWithDefault(msg, 51, 0.0),
    marginRateLiqudity: jspb.Message.getFloatingPointFieldWithDefault(msg, 52, 0.0),
    marginShort: jspb.Message.getFloatingPointFieldWithDefault(msg, 53, 0.0),
    marginStop: jspb.Message.getFloatingPointFieldWithDefault(msg, 54, 0.0),
    marginStopLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 55, 0.0),
    multiply: jspb.Message.getFloatingPointFieldWithDefault(msg, 56, 0.0),
    openPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 102, 0.0),
    optionsMode: jspb.Message.getFieldWithDefault(msg, 57, ""),
    orderFlags: jspb.Message.getFieldWithDefault(msg, 58, ""),
    page: jspb.Message.getFieldWithDefault(msg, 59, ""),
    path: jspb.Message.getFieldWithDefault(msg, 60, ""),
    point: jspb.Message.getFloatingPointFieldWithDefault(msg, 61, 0.0),
    priceLimitMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 62, 0.0),
    priceLimitMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 63, 0.0),
    priceSettle: jspb.Message.getFloatingPointFieldWithDefault(msg, 64, 0.0),
    priceStrike: jspb.Message.getFloatingPointFieldWithDefault(msg, 65, 0.0),
    quotesTimeout: jspb.Message.getFieldWithDefault(msg, 66, 0),
    reFlags: jspb.Message.getFieldWithDefault(msg, 67, 0),
    reTimeout: jspb.Message.getFieldWithDefault(msg, 68, 0),
    sector: jspb.Message.getFieldWithDefault(msg, 69, ""),
    source: jspb.Message.getFieldWithDefault(msg, 70, ""),
    spliceTimeDays: jspb.Message.getFieldWithDefault(msg, 71, 0),
    spliceTimeType: jspb.Message.getFieldWithDefault(msg, 72, ""),
    spliceType: jspb.Message.getFieldWithDefault(msg, 73, ""),
    spread: jspb.Message.getFieldWithDefault(msg, 74, 0),
    spreadBalance: jspb.Message.getFieldWithDefault(msg, 75, 0),
    spreadDiff: jspb.Message.getFieldWithDefault(msg, 76, 0),
    spreadDiffbalance: jspb.Message.getFieldWithDefault(msg, 77, 0),
    stopsLevel: jspb.Message.getFieldWithDefault(msg, 78, 0),
    subscriptionsDelay: jspb.Message.getFieldWithDefault(msg, 79, 0),
    swap3day: jspb.Message.getFieldWithDefault(msg, 80, 0),
    swapLong: jspb.Message.getFloatingPointFieldWithDefault(msg, 81, 0.0),
    swapMode: jspb.Message.getFieldWithDefault(msg, 82, 0),
    swapShort: jspb.Message.getFloatingPointFieldWithDefault(msg, 83, 0.0),
    swapYearDays: jspb.Message.getFieldWithDefault(msg, 84, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 85, ""),
    tickBookDepth: jspb.Message.getFieldWithDefault(msg, 86, 0),
    tickFlags: jspb.Message.getFieldWithDefault(msg, 87, ""),
    tickSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 88, 0.0),
    tickValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 89, 0.0),
    timeExpiration: jspb.Message.getFieldWithDefault(msg, 90, 0),
    timeStart: jspb.Message.getFieldWithDefault(msg, 91, 0),
    tradeFlags: jspb.Message.getFieldWithDefault(msg, 92, ""),
    tradeMode: jspb.Message.getFieldWithDefault(msg, 93, ""),
    volumeLimit: jspb.Message.getFieldWithDefault(msg, 94, 0),
    volumeLimitExt: jspb.Message.getFieldWithDefault(msg, 95, 0),
    volumeMax: jspb.Message.getFieldWithDefault(msg, 96, 0),
    volumeMaxExt: jspb.Message.getFieldWithDefault(msg, 97, 0),
    volumeMin: jspb.Message.getFieldWithDefault(msg, 98, 0),
    volumeMinExt: jspb.Message.getFieldWithDefault(msg, 99, 0),
    volumeStep: jspb.Message.getFieldWithDefault(msg, 100, 0),
    volumeStepExt: jspb.Message.getFieldWithDefault(msg, 101, 0),
    stat: (f = msg.getStat()) && proto.symbol.StatData.toObject(includeInstance, f),
    logo: jspb.Message.getFieldWithDefault(msg, 104, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.symbol.SymbolData}
 */
proto.symbol.SymbolData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.symbol.SymbolData;
  return proto.symbol.SymbolData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.symbol.SymbolData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.symbol.SymbolData}
 */
proto.symbol.SymbolData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAccruedInterest(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBasis(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalcMode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCfi(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setChartMode(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setColor(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setColorBackground(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setContractSize(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyBase(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrencyBaseDigits(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyMargin(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrencyMarginDigits(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyProfit(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrencyProfitDigits(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDigits(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setExchange(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecMode(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirFlags(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFaceValue(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setFillFlags(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterDiscard(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterGap(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterGapTicks(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterHard(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterHardTicks(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterSoft(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterSoftTicks(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterSpreadMax(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterSpreadMin(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFreezeLevel(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setGtcMode(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIeCheckMode(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIeFlags(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIeSlipLosing(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIeSlipProfit(value);
      break;
    case 39:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIeTimeout(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setIeVolumeMax(value);
      break;
    case 41:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setIeVolumeMaxExt(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndustry(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternational(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsin(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginFlags(value);
      break;
    case 46:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginHedged(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginInitial(value);
      break;
    case 48:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginLimit(value);
      break;
    case 49:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginLong(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginMaintenance(value);
      break;
    case 51:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginRateCurrency(value);
      break;
    case 52:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginRateLiqudity(value);
      break;
    case 53:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginShort(value);
      break;
    case 54:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginStop(value);
      break;
    case 55:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginStopLimit(value);
      break;
    case 56:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMultiply(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenPrice(value);
      break;
    case 57:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionsMode(value);
      break;
    case 58:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderFlags(value);
      break;
    case 59:
      var value = /** @type {string} */ (reader.readString());
      msg.setPage(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 61:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPoint(value);
      break;
    case 62:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceLimitMax(value);
      break;
    case 63:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceLimitMin(value);
      break;
    case 64:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceSettle(value);
      break;
    case 65:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceStrike(value);
      break;
    case 66:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuotesTimeout(value);
      break;
    case 67:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReFlags(value);
      break;
    case 68:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReTimeout(value);
      break;
    case 69:
      var value = /** @type {string} */ (reader.readString());
      msg.setSector(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 71:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSpliceTimeDays(value);
      break;
    case 72:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpliceTimeType(value);
      break;
    case 73:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpliceType(value);
      break;
    case 74:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSpread(value);
      break;
    case 75:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpreadBalance(value);
      break;
    case 76:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpreadDiff(value);
      break;
    case 77:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpreadDiffbalance(value);
      break;
    case 78:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStopsLevel(value);
      break;
    case 79:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSubscriptionsDelay(value);
      break;
    case 80:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSwap3day(value);
      break;
    case 81:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSwapLong(value);
      break;
    case 82:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSwapMode(value);
      break;
    case 83:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSwapShort(value);
      break;
    case 84:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSwapYearDays(value);
      break;
    case 85:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 86:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTickBookDepth(value);
      break;
    case 87:
      var value = /** @type {string} */ (reader.readString());
      msg.setTickFlags(value);
      break;
    case 88:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTickSize(value);
      break;
    case 89:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTickValue(value);
      break;
    case 90:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeExpiration(value);
      break;
    case 91:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeStart(value);
      break;
    case 92:
      var value = /** @type {string} */ (reader.readString());
      msg.setTradeFlags(value);
      break;
    case 93:
      var value = /** @type {string} */ (reader.readString());
      msg.setTradeMode(value);
      break;
    case 94:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeLimit(value);
      break;
    case 95:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeLimitExt(value);
      break;
    case 96:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeMax(value);
      break;
    case 97:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeMaxExt(value);
      break;
    case 98:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeMin(value);
      break;
    case 99:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeMinExt(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeStep(value);
      break;
    case 101:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeStepExt(value);
      break;
    case 103:
      var value = new proto.symbol.StatData;
      reader.readMessage(value,proto.symbol.StatData.deserializeBinaryFromReader);
      msg.setStat(value);
      break;
    case 104:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.symbol.SymbolData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.symbol.SymbolData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.symbol.SymbolData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccruedInterest();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getBasis();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCalcMode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCfi();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getChartMode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getColor();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getColorBackground();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getContractSize();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCurrencyBase();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCurrencyBaseDigits();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getCurrencyMargin();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCurrencyMarginDigits();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getCurrencyProfit();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCurrencyProfitDigits();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getDigits();
  if (f !== 0) {
    writer.writeUint32(
      18,
      f
    );
  }
  f = message.getExchange();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getExecMode();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getExpirFlags();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getFaceValue();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getFillFlags();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getFilterDiscard();
  if (f !== 0) {
    writer.writeUint32(
      24,
      f
    );
  }
  f = message.getFilterGap();
  if (f !== 0) {
    writer.writeUint32(
      25,
      f
    );
  }
  f = message.getFilterGapTicks();
  if (f !== 0) {
    writer.writeUint32(
      26,
      f
    );
  }
  f = message.getFilterHard();
  if (f !== 0) {
    writer.writeUint32(
      27,
      f
    );
  }
  f = message.getFilterHardTicks();
  if (f !== 0) {
    writer.writeUint32(
      28,
      f
    );
  }
  f = message.getFilterSoft();
  if (f !== 0) {
    writer.writeUint32(
      29,
      f
    );
  }
  f = message.getFilterSoftTicks();
  if (f !== 0) {
    writer.writeUint32(
      30,
      f
    );
  }
  f = message.getFilterSpreadMax();
  if (f !== 0) {
    writer.writeUint32(
      31,
      f
    );
  }
  f = message.getFilterSpreadMin();
  if (f !== 0) {
    writer.writeUint32(
      32,
      f
    );
  }
  f = message.getFreezeLevel();
  if (f !== 0) {
    writer.writeInt32(
      33,
      f
    );
  }
  f = message.getGtcMode();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getIeCheckMode();
  if (f !== 0) {
    writer.writeUint32(
      35,
      f
    );
  }
  f = message.getIeFlags();
  if (f !== 0) {
    writer.writeUint32(
      36,
      f
    );
  }
  f = message.getIeSlipLosing();
  if (f !== 0) {
    writer.writeUint32(
      37,
      f
    );
  }
  f = message.getIeSlipProfit();
  if (f !== 0) {
    writer.writeUint32(
      38,
      f
    );
  }
  f = message.getIeTimeout();
  if (f !== 0) {
    writer.writeUint32(
      39,
      f
    );
  }
  f = message.getIeVolumeMax();
  if (f !== 0) {
    writer.writeUint64(
      40,
      f
    );
  }
  f = message.getIeVolumeMaxExt();
  if (f !== 0) {
    writer.writeUint64(
      41,
      f
    );
  }
  f = message.getIndustry();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getInternational();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getIsin();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getMarginFlags();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getMarginHedged();
  if (f !== 0.0) {
    writer.writeDouble(
      46,
      f
    );
  }
  f = message.getMarginInitial();
  if (f !== 0.0) {
    writer.writeDouble(
      47,
      f
    );
  }
  f = message.getMarginLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      48,
      f
    );
  }
  f = message.getMarginLong();
  if (f !== 0.0) {
    writer.writeDouble(
      49,
      f
    );
  }
  f = message.getMarginMaintenance();
  if (f !== 0.0) {
    writer.writeDouble(
      50,
      f
    );
  }
  f = message.getMarginRateCurrency();
  if (f !== 0.0) {
    writer.writeDouble(
      51,
      f
    );
  }
  f = message.getMarginRateLiqudity();
  if (f !== 0.0) {
    writer.writeDouble(
      52,
      f
    );
  }
  f = message.getMarginShort();
  if (f !== 0.0) {
    writer.writeDouble(
      53,
      f
    );
  }
  f = message.getMarginStop();
  if (f !== 0.0) {
    writer.writeDouble(
      54,
      f
    );
  }
  f = message.getMarginStopLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      55,
      f
    );
  }
  f = message.getMultiply();
  if (f !== 0.0) {
    writer.writeDouble(
      56,
      f
    );
  }
  f = message.getOpenPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      102,
      f
    );
  }
  f = message.getOptionsMode();
  if (f.length > 0) {
    writer.writeString(
      57,
      f
    );
  }
  f = message.getOrderFlags();
  if (f.length > 0) {
    writer.writeString(
      58,
      f
    );
  }
  f = message.getPage();
  if (f.length > 0) {
    writer.writeString(
      59,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getPoint();
  if (f !== 0.0) {
    writer.writeDouble(
      61,
      f
    );
  }
  f = message.getPriceLimitMax();
  if (f !== 0.0) {
    writer.writeDouble(
      62,
      f
    );
  }
  f = message.getPriceLimitMin();
  if (f !== 0.0) {
    writer.writeDouble(
      63,
      f
    );
  }
  f = message.getPriceSettle();
  if (f !== 0.0) {
    writer.writeDouble(
      64,
      f
    );
  }
  f = message.getPriceStrike();
  if (f !== 0.0) {
    writer.writeDouble(
      65,
      f
    );
  }
  f = message.getQuotesTimeout();
  if (f !== 0) {
    writer.writeUint32(
      66,
      f
    );
  }
  f = message.getReFlags();
  if (f !== 0) {
    writer.writeUint32(
      67,
      f
    );
  }
  f = message.getReTimeout();
  if (f !== 0) {
    writer.writeUint32(
      68,
      f
    );
  }
  f = message.getSector();
  if (f.length > 0) {
    writer.writeString(
      69,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      70,
      f
    );
  }
  f = message.getSpliceTimeDays();
  if (f !== 0) {
    writer.writeUint32(
      71,
      f
    );
  }
  f = message.getSpliceTimeType();
  if (f.length > 0) {
    writer.writeString(
      72,
      f
    );
  }
  f = message.getSpliceType();
  if (f.length > 0) {
    writer.writeString(
      73,
      f
    );
  }
  f = message.getSpread();
  if (f !== 0) {
    writer.writeUint32(
      74,
      f
    );
  }
  f = message.getSpreadBalance();
  if (f !== 0) {
    writer.writeInt32(
      75,
      f
    );
  }
  f = message.getSpreadDiff();
  if (f !== 0) {
    writer.writeInt32(
      76,
      f
    );
  }
  f = message.getSpreadDiffbalance();
  if (f !== 0) {
    writer.writeInt32(
      77,
      f
    );
  }
  f = message.getStopsLevel();
  if (f !== 0) {
    writer.writeInt32(
      78,
      f
    );
  }
  f = message.getSubscriptionsDelay();
  if (f !== 0) {
    writer.writeUint32(
      79,
      f
    );
  }
  f = message.getSwap3day();
  if (f !== 0) {
    writer.writeUint32(
      80,
      f
    );
  }
  f = message.getSwapLong();
  if (f !== 0.0) {
    writer.writeDouble(
      81,
      f
    );
  }
  f = message.getSwapMode();
  if (f !== 0) {
    writer.writeUint32(
      82,
      f
    );
  }
  f = message.getSwapShort();
  if (f !== 0.0) {
    writer.writeDouble(
      83,
      f
    );
  }
  f = message.getSwapYearDays();
  if (f !== 0) {
    writer.writeUint32(
      84,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      85,
      f
    );
  }
  f = message.getTickBookDepth();
  if (f !== 0) {
    writer.writeUint32(
      86,
      f
    );
  }
  f = message.getTickFlags();
  if (f.length > 0) {
    writer.writeString(
      87,
      f
    );
  }
  f = message.getTickSize();
  if (f !== 0.0) {
    writer.writeDouble(
      88,
      f
    );
  }
  f = message.getTickValue();
  if (f !== 0.0) {
    writer.writeDouble(
      89,
      f
    );
  }
  f = message.getTimeExpiration();
  if (f !== 0) {
    writer.writeInt64(
      90,
      f
    );
  }
  f = message.getTimeStart();
  if (f !== 0) {
    writer.writeInt64(
      91,
      f
    );
  }
  f = message.getTradeFlags();
  if (f.length > 0) {
    writer.writeString(
      92,
      f
    );
  }
  f = message.getTradeMode();
  if (f.length > 0) {
    writer.writeString(
      93,
      f
    );
  }
  f = message.getVolumeLimit();
  if (f !== 0) {
    writer.writeUint64(
      94,
      f
    );
  }
  f = message.getVolumeLimitExt();
  if (f !== 0) {
    writer.writeUint64(
      95,
      f
    );
  }
  f = message.getVolumeMax();
  if (f !== 0) {
    writer.writeUint64(
      96,
      f
    );
  }
  f = message.getVolumeMaxExt();
  if (f !== 0) {
    writer.writeUint64(
      97,
      f
    );
  }
  f = message.getVolumeMin();
  if (f !== 0) {
    writer.writeUint64(
      98,
      f
    );
  }
  f = message.getVolumeMinExt();
  if (f !== 0) {
    writer.writeUint64(
      99,
      f
    );
  }
  f = message.getVolumeStep();
  if (f !== 0) {
    writer.writeUint64(
      100,
      f
    );
  }
  f = message.getVolumeStepExt();
  if (f !== 0) {
    writer.writeUint64(
      101,
      f
    );
  }
  f = message.getStat();
  if (f != null) {
    writer.writeMessage(
      103,
      f,
      proto.symbol.StatData.serializeBinaryToWriter
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      104,
      f
    );
  }
};


/**
 * optional double accrued_interest = 1;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getAccruedInterest = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setAccruedInterest = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string basis = 2;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string calc_mode = 3;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getCalcMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setCalcMode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string category = 4;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string cfi = 5;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getCfi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setCfi = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string chart_mode = 6;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getChartMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setChartMode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 color = 7;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getColor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setColor = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 color_background = 8;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getColorBackground = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setColorBackground = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional double contract_size = 9;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getContractSize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setContractSize = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string country = 10;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string currency_base = 11;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getCurrencyBase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setCurrencyBase = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional uint32 currency_base_digits = 12;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getCurrencyBaseDigits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setCurrencyBaseDigits = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string currency_margin = 13;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getCurrencyMargin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setCurrencyMargin = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional uint32 currency_margin_digits = 14;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getCurrencyMarginDigits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setCurrencyMarginDigits = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string currency_profit = 15;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getCurrencyProfit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setCurrencyProfit = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional uint32 currency_profit_digits = 16;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getCurrencyProfitDigits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setCurrencyProfitDigits = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string description = 17;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional uint32 digits = 18;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getDigits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setDigits = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string exchange = 19;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getExchange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setExchange = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string exec_mode = 20;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getExecMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setExecMode = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string expir_flags = 21;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getExpirFlags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setExpirFlags = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional double face_value = 22;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getFaceValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setFaceValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional string fill_flags = 23;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getFillFlags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setFillFlags = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional uint32 filter_discard = 24;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getFilterDiscard = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setFilterDiscard = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional uint32 filter_gap = 25;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getFilterGap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setFilterGap = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional uint32 filter_gap_ticks = 26;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getFilterGapTicks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setFilterGapTicks = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional uint32 filter_hard = 27;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getFilterHard = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setFilterHard = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional uint32 filter_hard_ticks = 28;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getFilterHardTicks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setFilterHardTicks = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional uint32 filter_soft = 29;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getFilterSoft = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setFilterSoft = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};


/**
 * optional uint32 filter_soft_ticks = 30;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getFilterSoftTicks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setFilterSoftTicks = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional uint32 filter_spread_max = 31;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getFilterSpreadMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setFilterSpreadMax = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};


/**
 * optional uint32 filter_spread_min = 32;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getFilterSpreadMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setFilterSpreadMin = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional int32 freeze_level = 33;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getFreezeLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setFreezeLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 33, value);
};


/**
 * optional string gtc_mode = 34;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getGtcMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setGtcMode = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional uint32 ie_check_mode = 35;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getIeCheckMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setIeCheckMode = function(value) {
  return jspb.Message.setProto3IntField(this, 35, value);
};


/**
 * optional uint32 ie_flags = 36;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getIeFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 36, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setIeFlags = function(value) {
  return jspb.Message.setProto3IntField(this, 36, value);
};


/**
 * optional uint32 ie_slip_losing = 37;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getIeSlipLosing = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setIeSlipLosing = function(value) {
  return jspb.Message.setProto3IntField(this, 37, value);
};


/**
 * optional uint32 ie_slip_profit = 38;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getIeSlipProfit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 38, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setIeSlipProfit = function(value) {
  return jspb.Message.setProto3IntField(this, 38, value);
};


/**
 * optional uint32 ie_timeout = 39;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getIeTimeout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 39, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setIeTimeout = function(value) {
  return jspb.Message.setProto3IntField(this, 39, value);
};


/**
 * optional uint64 ie_volume_max = 40;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getIeVolumeMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setIeVolumeMax = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * optional uint64 ie_volume_max_ext = 41;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getIeVolumeMaxExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 41, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setIeVolumeMaxExt = function(value) {
  return jspb.Message.setProto3IntField(this, 41, value);
};


/**
 * optional string industry = 42;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getIndustry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setIndustry = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string international = 43;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getInternational = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setInternational = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string isin = 44;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getIsin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setIsin = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional string margin_flags = 45;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getMarginFlags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setMarginFlags = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional double margin_hedged = 46;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getMarginHedged = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 46, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setMarginHedged = function(value) {
  return jspb.Message.setProto3FloatField(this, 46, value);
};


/**
 * optional double margin_initial = 47;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getMarginInitial = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 47, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setMarginInitial = function(value) {
  return jspb.Message.setProto3FloatField(this, 47, value);
};


/**
 * optional double margin_limit = 48;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getMarginLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 48, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setMarginLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 48, value);
};


/**
 * optional double margin_long = 49;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getMarginLong = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 49, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setMarginLong = function(value) {
  return jspb.Message.setProto3FloatField(this, 49, value);
};


/**
 * optional double margin_maintenance = 50;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getMarginMaintenance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 50, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setMarginMaintenance = function(value) {
  return jspb.Message.setProto3FloatField(this, 50, value);
};


/**
 * optional double margin_rate_currency = 51;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getMarginRateCurrency = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 51, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setMarginRateCurrency = function(value) {
  return jspb.Message.setProto3FloatField(this, 51, value);
};


/**
 * optional double margin_rate_liqudity = 52;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getMarginRateLiqudity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 52, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setMarginRateLiqudity = function(value) {
  return jspb.Message.setProto3FloatField(this, 52, value);
};


/**
 * optional double margin_short = 53;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getMarginShort = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 53, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setMarginShort = function(value) {
  return jspb.Message.setProto3FloatField(this, 53, value);
};


/**
 * optional double margin_stop = 54;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getMarginStop = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 54, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setMarginStop = function(value) {
  return jspb.Message.setProto3FloatField(this, 54, value);
};


/**
 * optional double margin_stop_limit = 55;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getMarginStopLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 55, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setMarginStopLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 55, value);
};


/**
 * optional double multiply = 56;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getMultiply = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 56, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setMultiply = function(value) {
  return jspb.Message.setProto3FloatField(this, 56, value);
};


/**
 * optional double open_price = 102;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getOpenPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 102, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setOpenPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 102, value);
};


/**
 * optional string options_mode = 57;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getOptionsMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 57, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setOptionsMode = function(value) {
  return jspb.Message.setProto3StringField(this, 57, value);
};


/**
 * optional string order_flags = 58;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getOrderFlags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 58, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setOrderFlags = function(value) {
  return jspb.Message.setProto3StringField(this, 58, value);
};


/**
 * optional string page = 59;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 59, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setPage = function(value) {
  return jspb.Message.setProto3StringField(this, 59, value);
};


/**
 * optional string path = 60;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional double point = 61;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getPoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 61, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setPoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 61, value);
};


/**
 * optional double price_limit_max = 62;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getPriceLimitMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 62, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setPriceLimitMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 62, value);
};


/**
 * optional double price_limit_min = 63;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getPriceLimitMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 63, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setPriceLimitMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 63, value);
};


/**
 * optional double price_settle = 64;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getPriceSettle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 64, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setPriceSettle = function(value) {
  return jspb.Message.setProto3FloatField(this, 64, value);
};


/**
 * optional double price_strike = 65;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getPriceStrike = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 65, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setPriceStrike = function(value) {
  return jspb.Message.setProto3FloatField(this, 65, value);
};


/**
 * optional uint32 quotes_timeout = 66;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getQuotesTimeout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 66, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setQuotesTimeout = function(value) {
  return jspb.Message.setProto3IntField(this, 66, value);
};


/**
 * optional uint32 re_flags = 67;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getReFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 67, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setReFlags = function(value) {
  return jspb.Message.setProto3IntField(this, 67, value);
};


/**
 * optional uint32 re_timeout = 68;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getReTimeout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 68, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setReTimeout = function(value) {
  return jspb.Message.setProto3IntField(this, 68, value);
};


/**
 * optional string sector = 69;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getSector = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 69, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setSector = function(value) {
  return jspb.Message.setProto3StringField(this, 69, value);
};


/**
 * optional string source = 70;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 70, value);
};


/**
 * optional uint32 splice_time_days = 71;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getSpliceTimeDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 71, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setSpliceTimeDays = function(value) {
  return jspb.Message.setProto3IntField(this, 71, value);
};


/**
 * optional string splice_time_type = 72;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getSpliceTimeType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 72, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setSpliceTimeType = function(value) {
  return jspb.Message.setProto3StringField(this, 72, value);
};


/**
 * optional string splice_type = 73;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getSpliceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 73, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setSpliceType = function(value) {
  return jspb.Message.setProto3StringField(this, 73, value);
};


/**
 * optional uint32 spread = 74;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getSpread = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 74, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setSpread = function(value) {
  return jspb.Message.setProto3IntField(this, 74, value);
};


/**
 * optional int32 spread_balance = 75;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getSpreadBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 75, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setSpreadBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 75, value);
};


/**
 * optional int32 spread_diff = 76;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getSpreadDiff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 76, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setSpreadDiff = function(value) {
  return jspb.Message.setProto3IntField(this, 76, value);
};


/**
 * optional int32 spread_diffBalance = 77;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getSpreadDiffbalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 77, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setSpreadDiffbalance = function(value) {
  return jspb.Message.setProto3IntField(this, 77, value);
};


/**
 * optional int32 stops_level = 78;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getStopsLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 78, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setStopsLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 78, value);
};


/**
 * optional uint32 subscriptions_delay = 79;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getSubscriptionsDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 79, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setSubscriptionsDelay = function(value) {
  return jspb.Message.setProto3IntField(this, 79, value);
};


/**
 * optional uint32 swap_3day = 80;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getSwap3day = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 80, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setSwap3day = function(value) {
  return jspb.Message.setProto3IntField(this, 80, value);
};


/**
 * optional double swap_long = 81;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getSwapLong = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 81, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setSwapLong = function(value) {
  return jspb.Message.setProto3FloatField(this, 81, value);
};


/**
 * optional uint32 swap_mode = 82;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getSwapMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 82, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setSwapMode = function(value) {
  return jspb.Message.setProto3IntField(this, 82, value);
};


/**
 * optional double swap_short = 83;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getSwapShort = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 83, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setSwapShort = function(value) {
  return jspb.Message.setProto3FloatField(this, 83, value);
};


/**
 * optional uint32 swap_year_days = 84;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getSwapYearDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 84, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setSwapYearDays = function(value) {
  return jspb.Message.setProto3IntField(this, 84, value);
};


/**
 * optional string symbol = 85;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 85, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 85, value);
};


/**
 * optional uint32 tick_book_depth = 86;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getTickBookDepth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 86, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setTickBookDepth = function(value) {
  return jspb.Message.setProto3IntField(this, 86, value);
};


/**
 * optional string tick_flags = 87;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getTickFlags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 87, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setTickFlags = function(value) {
  return jspb.Message.setProto3StringField(this, 87, value);
};


/**
 * optional double tick_size = 88;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getTickSize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 88, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setTickSize = function(value) {
  return jspb.Message.setProto3FloatField(this, 88, value);
};


/**
 * optional double tick_value = 89;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getTickValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 89, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setTickValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 89, value);
};


/**
 * optional int64 time_expiration = 90;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getTimeExpiration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 90, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setTimeExpiration = function(value) {
  return jspb.Message.setProto3IntField(this, 90, value);
};


/**
 * optional int64 time_start = 91;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getTimeStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 91, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setTimeStart = function(value) {
  return jspb.Message.setProto3IntField(this, 91, value);
};


/**
 * optional string trade_flags = 92;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getTradeFlags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 92, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setTradeFlags = function(value) {
  return jspb.Message.setProto3StringField(this, 92, value);
};


/**
 * optional string trade_mode = 93;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getTradeMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 93, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setTradeMode = function(value) {
  return jspb.Message.setProto3StringField(this, 93, value);
};


/**
 * optional uint64 volume_limit = 94;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getVolumeLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 94, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setVolumeLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 94, value);
};


/**
 * optional uint64 volume_limit_ext = 95;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getVolumeLimitExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 95, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setVolumeLimitExt = function(value) {
  return jspb.Message.setProto3IntField(this, 95, value);
};


/**
 * optional uint64 volume_max = 96;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getVolumeMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 96, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setVolumeMax = function(value) {
  return jspb.Message.setProto3IntField(this, 96, value);
};


/**
 * optional uint64 volume_max_ext = 97;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getVolumeMaxExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 97, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setVolumeMaxExt = function(value) {
  return jspb.Message.setProto3IntField(this, 97, value);
};


/**
 * optional uint64 volume_min = 98;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getVolumeMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 98, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setVolumeMin = function(value) {
  return jspb.Message.setProto3IntField(this, 98, value);
};


/**
 * optional uint64 volume_min_ext = 99;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getVolumeMinExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 99, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setVolumeMinExt = function(value) {
  return jspb.Message.setProto3IntField(this, 99, value);
};


/**
 * optional uint64 volume_step = 100;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getVolumeStep = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setVolumeStep = function(value) {
  return jspb.Message.setProto3IntField(this, 100, value);
};


/**
 * optional uint64 volume_step_ext = 101;
 * @return {number}
 */
proto.symbol.SymbolData.prototype.getVolumeStepExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setVolumeStepExt = function(value) {
  return jspb.Message.setProto3IntField(this, 101, value);
};


/**
 * optional StatData stat = 103;
 * @return {?proto.symbol.StatData}
 */
proto.symbol.SymbolData.prototype.getStat = function() {
  return /** @type{?proto.symbol.StatData} */ (
    jspb.Message.getWrapperField(this, proto.symbol.StatData, 103));
};


/**
 * @param {?proto.symbol.StatData|undefined} value
 * @return {!proto.symbol.SymbolData} returns this
*/
proto.symbol.SymbolData.prototype.setStat = function(value) {
  return jspb.Message.setWrapperField(this, 103, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.clearStat = function() {
  return this.setStat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.symbol.SymbolData.prototype.hasStat = function() {
  return jspb.Message.getField(this, 103) != null;
};


/**
 * optional string logo = 104;
 * @return {string}
 */
proto.symbol.SymbolData.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 104, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolData} returns this
 */
proto.symbol.SymbolData.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 104, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.symbol.SymbolDataNew.prototype.toObject = function(opt_includeInstance) {
  return proto.symbol.SymbolDataNew.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.symbol.SymbolDataNew} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolDataNew.toObject = function(includeInstance, msg) {
  var f, obj = {
    accruedInterest: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    basis: jspb.Message.getFieldWithDefault(msg, 2, ""),
    calcMode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    category: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cfi: jspb.Message.getFieldWithDefault(msg, 5, ""),
    chartMode: jspb.Message.getFieldWithDefault(msg, 6, 0),
    color: jspb.Message.getFieldWithDefault(msg, 7, 0),
    colorBackground: jspb.Message.getFieldWithDefault(msg, 8, 0),
    contractSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    country: jspb.Message.getFieldWithDefault(msg, 10, ""),
    currencyBase: jspb.Message.getFieldWithDefault(msg, 11, ""),
    currencyBaseDigits: jspb.Message.getFieldWithDefault(msg, 12, 0),
    currencyMargin: jspb.Message.getFieldWithDefault(msg, 13, ""),
    currencyMarginDigits: jspb.Message.getFieldWithDefault(msg, 14, 0),
    currencyProfit: jspb.Message.getFieldWithDefault(msg, 15, ""),
    currencyProfitDigits: jspb.Message.getFieldWithDefault(msg, 16, 0),
    description: jspb.Message.getFieldWithDefault(msg, 17, ""),
    digits: jspb.Message.getFieldWithDefault(msg, 18, 0),
    exchange: jspb.Message.getFieldWithDefault(msg, 19, ""),
    execMode: jspb.Message.getFieldWithDefault(msg, 20, 0),
    expirFlags: jspb.Message.getFieldWithDefault(msg, 21, 0),
    faceValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    fillFlags: jspb.Message.getFieldWithDefault(msg, 23, 0),
    filterDiscard: jspb.Message.getFieldWithDefault(msg, 24, 0),
    filterGap: jspb.Message.getFieldWithDefault(msg, 25, 0),
    filterGapTicks: jspb.Message.getFieldWithDefault(msg, 26, 0),
    filterHard: jspb.Message.getFieldWithDefault(msg, 27, 0),
    filterHardTicks: jspb.Message.getFieldWithDefault(msg, 28, 0),
    filterSoft: jspb.Message.getFieldWithDefault(msg, 29, 0),
    filterSoftTicks: jspb.Message.getFieldWithDefault(msg, 30, 0),
    filterSpreadMax: jspb.Message.getFieldWithDefault(msg, 31, 0),
    filterSpreadMin: jspb.Message.getFieldWithDefault(msg, 32, 0),
    freezeLevel: jspb.Message.getFieldWithDefault(msg, 33, 0),
    gtcMode: jspb.Message.getFieldWithDefault(msg, 34, 0),
    ieCheckMode: jspb.Message.getFieldWithDefault(msg, 35, 0),
    ieFlags: jspb.Message.getFieldWithDefault(msg, 36, 0),
    ieSlipLosing: jspb.Message.getFieldWithDefault(msg, 37, 0),
    ieSlipProfit: jspb.Message.getFieldWithDefault(msg, 38, 0),
    ieTimeout: jspb.Message.getFieldWithDefault(msg, 39, 0),
    ieVolumeMax: jspb.Message.getFieldWithDefault(msg, 40, 0),
    ieVolumeMaxExt: jspb.Message.getFieldWithDefault(msg, 41, 0),
    industry: jspb.Message.getFieldWithDefault(msg, 42, 0),
    international: jspb.Message.getFieldWithDefault(msg, 43, ""),
    isin: jspb.Message.getFieldWithDefault(msg, 44, ""),
    marginFlags: jspb.Message.getFieldWithDefault(msg, 45, 0),
    marginHedged: jspb.Message.getFloatingPointFieldWithDefault(msg, 46, 0.0),
    marginInitial: jspb.Message.getFloatingPointFieldWithDefault(msg, 47, 0.0),
    marginLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 48, 0.0),
    marginLong: jspb.Message.getFloatingPointFieldWithDefault(msg, 49, 0.0),
    marginMaintenance: jspb.Message.getFloatingPointFieldWithDefault(msg, 50, 0.0),
    marginRateCurrency: jspb.Message.getFloatingPointFieldWithDefault(msg, 51, 0.0),
    marginRateLiqudity: jspb.Message.getFloatingPointFieldWithDefault(msg, 52, 0.0),
    marginShort: jspb.Message.getFloatingPointFieldWithDefault(msg, 53, 0.0),
    marginStop: jspb.Message.getFloatingPointFieldWithDefault(msg, 54, 0.0),
    marginStopLimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 55, 0.0),
    multiply: jspb.Message.getFloatingPointFieldWithDefault(msg, 56, 0.0),
    openPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 102, 0.0),
    optionsMode: jspb.Message.getFieldWithDefault(msg, 57, 0),
    orderFlags: jspb.Message.getFieldWithDefault(msg, 58, 0),
    page: jspb.Message.getFieldWithDefault(msg, 59, ""),
    path: jspb.Message.getFieldWithDefault(msg, 60, ""),
    point: jspb.Message.getFloatingPointFieldWithDefault(msg, 61, 0.0),
    priceLimitMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 62, 0.0),
    priceLimitMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 63, 0.0),
    priceSettle: jspb.Message.getFloatingPointFieldWithDefault(msg, 64, 0.0),
    priceStrike: jspb.Message.getFloatingPointFieldWithDefault(msg, 65, 0.0),
    quotesTimeout: jspb.Message.getFieldWithDefault(msg, 66, 0),
    reFlags: jspb.Message.getFieldWithDefault(msg, 67, 0),
    reTimeout: jspb.Message.getFieldWithDefault(msg, 68, 0),
    sector: jspb.Message.getFieldWithDefault(msg, 69, 0),
    source: jspb.Message.getFieldWithDefault(msg, 70, ""),
    spliceTimeDays: jspb.Message.getFieldWithDefault(msg, 71, 0),
    spliceTimeType: jspb.Message.getFieldWithDefault(msg, 72, 0),
    spliceType: jspb.Message.getFieldWithDefault(msg, 73, 0),
    spread: jspb.Message.getFieldWithDefault(msg, 74, 0),
    spreadBalance: jspb.Message.getFieldWithDefault(msg, 75, 0),
    spreadDiff: jspb.Message.getFieldWithDefault(msg, 76, 0),
    spreadDiffbalance: jspb.Message.getFieldWithDefault(msg, 77, 0),
    stopsLevel: jspb.Message.getFieldWithDefault(msg, 78, 0),
    subscriptionsDelay: jspb.Message.getFieldWithDefault(msg, 79, 0),
    swap3day: jspb.Message.getFieldWithDefault(msg, 80, 0),
    swapLong: jspb.Message.getFloatingPointFieldWithDefault(msg, 81, 0.0),
    swapMode: jspb.Message.getFieldWithDefault(msg, 82, 0),
    swapShort: jspb.Message.getFloatingPointFieldWithDefault(msg, 83, 0.0),
    swapYearDays: jspb.Message.getFieldWithDefault(msg, 84, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 85, ""),
    tickBookDepth: jspb.Message.getFieldWithDefault(msg, 86, 0),
    tickFlags: jspb.Message.getFieldWithDefault(msg, 87, 0),
    tickSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 88, 0.0),
    tickValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 89, 0.0),
    timeExpiration: jspb.Message.getFieldWithDefault(msg, 90, 0),
    timeStart: jspb.Message.getFieldWithDefault(msg, 91, 0),
    tradeFlags: jspb.Message.getFieldWithDefault(msg, 92, 0),
    tradeMode: jspb.Message.getFieldWithDefault(msg, 93, 0),
    volumeLimit: jspb.Message.getFieldWithDefault(msg, 94, 0),
    volumeLimitExt: jspb.Message.getFieldWithDefault(msg, 95, 0),
    volumeMax: jspb.Message.getFieldWithDefault(msg, 96, 0),
    volumeMaxExt: jspb.Message.getFieldWithDefault(msg, 97, 0),
    volumeMin: jspb.Message.getFieldWithDefault(msg, 98, 0),
    volumeMinExt: jspb.Message.getFieldWithDefault(msg, 99, 0),
    volumeStep: jspb.Message.getFieldWithDefault(msg, 100, 0),
    volumeStepExt: jspb.Message.getFieldWithDefault(msg, 101, 0),
    stat: (f = msg.getStat()) && proto.symbol.StatData.toObject(includeInstance, f),
    logo: jspb.Message.getFieldWithDefault(msg, 104, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.symbol.SymbolDataNew}
 */
proto.symbol.SymbolDataNew.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.symbol.SymbolDataNew;
  return proto.symbol.SymbolDataNew.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.symbol.SymbolDataNew} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.symbol.SymbolDataNew}
 */
proto.symbol.SymbolDataNew.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAccruedInterest(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBasis(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCalcMode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCfi(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChartMode(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setColor(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setColorBackground(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setContractSize(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyBase(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrencyBaseDigits(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyMargin(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrencyMarginDigits(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyProfit(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrencyProfitDigits(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDigits(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setExchange(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExecMode(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExpirFlags(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFaceValue(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFillFlags(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterDiscard(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterGap(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterGapTicks(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterHard(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterHardTicks(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterSoft(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterSoftTicks(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterSpreadMax(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilterSpreadMin(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFreezeLevel(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGtcMode(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIeCheckMode(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIeFlags(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIeSlipLosing(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIeSlipProfit(value);
      break;
    case 39:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIeTimeout(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setIeVolumeMax(value);
      break;
    case 41:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setIeVolumeMaxExt(value);
      break;
    case 42:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIndustry(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternational(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsin(value);
      break;
    case 45:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMarginFlags(value);
      break;
    case 46:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginHedged(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginInitial(value);
      break;
    case 48:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginLimit(value);
      break;
    case 49:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginLong(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginMaintenance(value);
      break;
    case 51:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginRateCurrency(value);
      break;
    case 52:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginRateLiqudity(value);
      break;
    case 53:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginShort(value);
      break;
    case 54:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginStop(value);
      break;
    case 55:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMarginStopLimit(value);
      break;
    case 56:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMultiply(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOpenPrice(value);
      break;
    case 57:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOptionsMode(value);
      break;
    case 58:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrderFlags(value);
      break;
    case 59:
      var value = /** @type {string} */ (reader.readString());
      msg.setPage(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 61:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPoint(value);
      break;
    case 62:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceLimitMax(value);
      break;
    case 63:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceLimitMin(value);
      break;
    case 64:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceSettle(value);
      break;
    case 65:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceStrike(value);
      break;
    case 66:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuotesTimeout(value);
      break;
    case 67:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReFlags(value);
      break;
    case 68:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReTimeout(value);
      break;
    case 69:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSector(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 71:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSpliceTimeDays(value);
      break;
    case 72:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSpliceTimeType(value);
      break;
    case 73:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSpliceType(value);
      break;
    case 74:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSpread(value);
      break;
    case 75:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpreadBalance(value);
      break;
    case 76:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpreadDiff(value);
      break;
    case 77:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpreadDiffbalance(value);
      break;
    case 78:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStopsLevel(value);
      break;
    case 79:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSubscriptionsDelay(value);
      break;
    case 80:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSwap3day(value);
      break;
    case 81:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSwapLong(value);
      break;
    case 82:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSwapMode(value);
      break;
    case 83:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSwapShort(value);
      break;
    case 84:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSwapYearDays(value);
      break;
    case 85:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 86:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTickBookDepth(value);
      break;
    case 87:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTickFlags(value);
      break;
    case 88:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTickSize(value);
      break;
    case 89:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTickValue(value);
      break;
    case 90:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeExpiration(value);
      break;
    case 91:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeStart(value);
      break;
    case 92:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTradeFlags(value);
      break;
    case 93:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTradeMode(value);
      break;
    case 94:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeLimit(value);
      break;
    case 95:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeLimitExt(value);
      break;
    case 96:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeMax(value);
      break;
    case 97:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeMaxExt(value);
      break;
    case 98:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeMin(value);
      break;
    case 99:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeMinExt(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeStep(value);
      break;
    case 101:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeStepExt(value);
      break;
    case 103:
      var value = new proto.symbol.StatData;
      reader.readMessage(value,proto.symbol.StatData.deserializeBinaryFromReader);
      msg.setStat(value);
      break;
    case 104:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.symbol.SymbolDataNew.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.symbol.SymbolDataNew.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.symbol.SymbolDataNew} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.SymbolDataNew.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccruedInterest();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getBasis();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCalcMode();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCfi();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getChartMode();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getColor();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getColorBackground();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getContractSize();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCurrencyBase();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCurrencyBaseDigits();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getCurrencyMargin();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCurrencyMarginDigits();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getCurrencyProfit();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCurrencyProfitDigits();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getDigits();
  if (f !== 0) {
    writer.writeUint32(
      18,
      f
    );
  }
  f = message.getExchange();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getExecMode();
  if (f !== 0) {
    writer.writeUint32(
      20,
      f
    );
  }
  f = message.getExpirFlags();
  if (f !== 0) {
    writer.writeUint32(
      21,
      f
    );
  }
  f = message.getFaceValue();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getFillFlags();
  if (f !== 0) {
    writer.writeUint32(
      23,
      f
    );
  }
  f = message.getFilterDiscard();
  if (f !== 0) {
    writer.writeUint32(
      24,
      f
    );
  }
  f = message.getFilterGap();
  if (f !== 0) {
    writer.writeUint32(
      25,
      f
    );
  }
  f = message.getFilterGapTicks();
  if (f !== 0) {
    writer.writeUint32(
      26,
      f
    );
  }
  f = message.getFilterHard();
  if (f !== 0) {
    writer.writeUint32(
      27,
      f
    );
  }
  f = message.getFilterHardTicks();
  if (f !== 0) {
    writer.writeUint32(
      28,
      f
    );
  }
  f = message.getFilterSoft();
  if (f !== 0) {
    writer.writeUint32(
      29,
      f
    );
  }
  f = message.getFilterSoftTicks();
  if (f !== 0) {
    writer.writeUint32(
      30,
      f
    );
  }
  f = message.getFilterSpreadMax();
  if (f !== 0) {
    writer.writeUint32(
      31,
      f
    );
  }
  f = message.getFilterSpreadMin();
  if (f !== 0) {
    writer.writeUint32(
      32,
      f
    );
  }
  f = message.getFreezeLevel();
  if (f !== 0) {
    writer.writeInt32(
      33,
      f
    );
  }
  f = message.getGtcMode();
  if (f !== 0) {
    writer.writeUint32(
      34,
      f
    );
  }
  f = message.getIeCheckMode();
  if (f !== 0) {
    writer.writeUint32(
      35,
      f
    );
  }
  f = message.getIeFlags();
  if (f !== 0) {
    writer.writeUint32(
      36,
      f
    );
  }
  f = message.getIeSlipLosing();
  if (f !== 0) {
    writer.writeUint32(
      37,
      f
    );
  }
  f = message.getIeSlipProfit();
  if (f !== 0) {
    writer.writeUint32(
      38,
      f
    );
  }
  f = message.getIeTimeout();
  if (f !== 0) {
    writer.writeUint32(
      39,
      f
    );
  }
  f = message.getIeVolumeMax();
  if (f !== 0) {
    writer.writeUint64(
      40,
      f
    );
  }
  f = message.getIeVolumeMaxExt();
  if (f !== 0) {
    writer.writeUint64(
      41,
      f
    );
  }
  f = message.getIndustry();
  if (f !== 0) {
    writer.writeUint32(
      42,
      f
    );
  }
  f = message.getInternational();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getIsin();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getMarginFlags();
  if (f !== 0) {
    writer.writeUint32(
      45,
      f
    );
  }
  f = message.getMarginHedged();
  if (f !== 0.0) {
    writer.writeDouble(
      46,
      f
    );
  }
  f = message.getMarginInitial();
  if (f !== 0.0) {
    writer.writeDouble(
      47,
      f
    );
  }
  f = message.getMarginLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      48,
      f
    );
  }
  f = message.getMarginLong();
  if (f !== 0.0) {
    writer.writeDouble(
      49,
      f
    );
  }
  f = message.getMarginMaintenance();
  if (f !== 0.0) {
    writer.writeDouble(
      50,
      f
    );
  }
  f = message.getMarginRateCurrency();
  if (f !== 0.0) {
    writer.writeDouble(
      51,
      f
    );
  }
  f = message.getMarginRateLiqudity();
  if (f !== 0.0) {
    writer.writeDouble(
      52,
      f
    );
  }
  f = message.getMarginShort();
  if (f !== 0.0) {
    writer.writeDouble(
      53,
      f
    );
  }
  f = message.getMarginStop();
  if (f !== 0.0) {
    writer.writeDouble(
      54,
      f
    );
  }
  f = message.getMarginStopLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      55,
      f
    );
  }
  f = message.getMultiply();
  if (f !== 0.0) {
    writer.writeDouble(
      56,
      f
    );
  }
  f = message.getOpenPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      102,
      f
    );
  }
  f = message.getOptionsMode();
  if (f !== 0) {
    writer.writeUint32(
      57,
      f
    );
  }
  f = message.getOrderFlags();
  if (f !== 0) {
    writer.writeUint32(
      58,
      f
    );
  }
  f = message.getPage();
  if (f.length > 0) {
    writer.writeString(
      59,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getPoint();
  if (f !== 0.0) {
    writer.writeDouble(
      61,
      f
    );
  }
  f = message.getPriceLimitMax();
  if (f !== 0.0) {
    writer.writeDouble(
      62,
      f
    );
  }
  f = message.getPriceLimitMin();
  if (f !== 0.0) {
    writer.writeDouble(
      63,
      f
    );
  }
  f = message.getPriceSettle();
  if (f !== 0.0) {
    writer.writeDouble(
      64,
      f
    );
  }
  f = message.getPriceStrike();
  if (f !== 0.0) {
    writer.writeDouble(
      65,
      f
    );
  }
  f = message.getQuotesTimeout();
  if (f !== 0) {
    writer.writeUint32(
      66,
      f
    );
  }
  f = message.getReFlags();
  if (f !== 0) {
    writer.writeUint32(
      67,
      f
    );
  }
  f = message.getReTimeout();
  if (f !== 0) {
    writer.writeUint32(
      68,
      f
    );
  }
  f = message.getSector();
  if (f !== 0) {
    writer.writeUint32(
      69,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      70,
      f
    );
  }
  f = message.getSpliceTimeDays();
  if (f !== 0) {
    writer.writeUint32(
      71,
      f
    );
  }
  f = message.getSpliceTimeType();
  if (f !== 0) {
    writer.writeUint32(
      72,
      f
    );
  }
  f = message.getSpliceType();
  if (f !== 0) {
    writer.writeUint32(
      73,
      f
    );
  }
  f = message.getSpread();
  if (f !== 0) {
    writer.writeUint32(
      74,
      f
    );
  }
  f = message.getSpreadBalance();
  if (f !== 0) {
    writer.writeInt32(
      75,
      f
    );
  }
  f = message.getSpreadDiff();
  if (f !== 0) {
    writer.writeInt32(
      76,
      f
    );
  }
  f = message.getSpreadDiffbalance();
  if (f !== 0) {
    writer.writeInt32(
      77,
      f
    );
  }
  f = message.getStopsLevel();
  if (f !== 0) {
    writer.writeInt32(
      78,
      f
    );
  }
  f = message.getSubscriptionsDelay();
  if (f !== 0) {
    writer.writeUint32(
      79,
      f
    );
  }
  f = message.getSwap3day();
  if (f !== 0) {
    writer.writeUint32(
      80,
      f
    );
  }
  f = message.getSwapLong();
  if (f !== 0.0) {
    writer.writeDouble(
      81,
      f
    );
  }
  f = message.getSwapMode();
  if (f !== 0) {
    writer.writeUint32(
      82,
      f
    );
  }
  f = message.getSwapShort();
  if (f !== 0.0) {
    writer.writeDouble(
      83,
      f
    );
  }
  f = message.getSwapYearDays();
  if (f !== 0) {
    writer.writeUint32(
      84,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      85,
      f
    );
  }
  f = message.getTickBookDepth();
  if (f !== 0) {
    writer.writeUint32(
      86,
      f
    );
  }
  f = message.getTickFlags();
  if (f !== 0) {
    writer.writeUint32(
      87,
      f
    );
  }
  f = message.getTickSize();
  if (f !== 0.0) {
    writer.writeDouble(
      88,
      f
    );
  }
  f = message.getTickValue();
  if (f !== 0.0) {
    writer.writeDouble(
      89,
      f
    );
  }
  f = message.getTimeExpiration();
  if (f !== 0) {
    writer.writeInt64(
      90,
      f
    );
  }
  f = message.getTimeStart();
  if (f !== 0) {
    writer.writeInt64(
      91,
      f
    );
  }
  f = message.getTradeFlags();
  if (f !== 0) {
    writer.writeUint32(
      92,
      f
    );
  }
  f = message.getTradeMode();
  if (f !== 0) {
    writer.writeUint32(
      93,
      f
    );
  }
  f = message.getVolumeLimit();
  if (f !== 0) {
    writer.writeUint64(
      94,
      f
    );
  }
  f = message.getVolumeLimitExt();
  if (f !== 0) {
    writer.writeUint64(
      95,
      f
    );
  }
  f = message.getVolumeMax();
  if (f !== 0) {
    writer.writeUint64(
      96,
      f
    );
  }
  f = message.getVolumeMaxExt();
  if (f !== 0) {
    writer.writeUint64(
      97,
      f
    );
  }
  f = message.getVolumeMin();
  if (f !== 0) {
    writer.writeUint64(
      98,
      f
    );
  }
  f = message.getVolumeMinExt();
  if (f !== 0) {
    writer.writeUint64(
      99,
      f
    );
  }
  f = message.getVolumeStep();
  if (f !== 0) {
    writer.writeUint64(
      100,
      f
    );
  }
  f = message.getVolumeStepExt();
  if (f !== 0) {
    writer.writeUint64(
      101,
      f
    );
  }
  f = message.getStat();
  if (f != null) {
    writer.writeMessage(
      103,
      f,
      proto.symbol.StatData.serializeBinaryToWriter
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      104,
      f
    );
  }
};


/**
 * optional double accrued_interest = 1;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getAccruedInterest = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setAccruedInterest = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string basis = 2;
 * @return {string}
 */
proto.symbol.SymbolDataNew.prototype.getBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 calc_mode = 3;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getCalcMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setCalcMode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string category = 4;
 * @return {string}
 */
proto.symbol.SymbolDataNew.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string cfi = 5;
 * @return {string}
 */
proto.symbol.SymbolDataNew.prototype.getCfi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setCfi = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 chart_mode = 6;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getChartMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setChartMode = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 color = 7;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getColor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setColor = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 color_background = 8;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getColorBackground = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setColorBackground = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional double contract_size = 9;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getContractSize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setContractSize = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string country = 10;
 * @return {string}
 */
proto.symbol.SymbolDataNew.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string currency_base = 11;
 * @return {string}
 */
proto.symbol.SymbolDataNew.prototype.getCurrencyBase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setCurrencyBase = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional uint32 currency_base_digits = 12;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getCurrencyBaseDigits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setCurrencyBaseDigits = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string currency_margin = 13;
 * @return {string}
 */
proto.symbol.SymbolDataNew.prototype.getCurrencyMargin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setCurrencyMargin = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional uint32 currency_margin_digits = 14;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getCurrencyMarginDigits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setCurrencyMarginDigits = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string currency_profit = 15;
 * @return {string}
 */
proto.symbol.SymbolDataNew.prototype.getCurrencyProfit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setCurrencyProfit = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional uint32 currency_profit_digits = 16;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getCurrencyProfitDigits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setCurrencyProfitDigits = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string description = 17;
 * @return {string}
 */
proto.symbol.SymbolDataNew.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional uint32 digits = 18;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getDigits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setDigits = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string exchange = 19;
 * @return {string}
 */
proto.symbol.SymbolDataNew.prototype.getExchange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setExchange = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional uint32 exec_mode = 20;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getExecMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setExecMode = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional uint32 expir_flags = 21;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getExpirFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setExpirFlags = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional double face_value = 22;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getFaceValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setFaceValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional uint32 fill_flags = 23;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getFillFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setFillFlags = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional uint32 filter_discard = 24;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getFilterDiscard = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setFilterDiscard = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional uint32 filter_gap = 25;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getFilterGap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setFilterGap = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional uint32 filter_gap_ticks = 26;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getFilterGapTicks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setFilterGapTicks = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional uint32 filter_hard = 27;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getFilterHard = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setFilterHard = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional uint32 filter_hard_ticks = 28;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getFilterHardTicks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setFilterHardTicks = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional uint32 filter_soft = 29;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getFilterSoft = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setFilterSoft = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};


/**
 * optional uint32 filter_soft_ticks = 30;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getFilterSoftTicks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setFilterSoftTicks = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional uint32 filter_spread_max = 31;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getFilterSpreadMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setFilterSpreadMax = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};


/**
 * optional uint32 filter_spread_min = 32;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getFilterSpreadMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setFilterSpreadMin = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional int32 freeze_level = 33;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getFreezeLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setFreezeLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 33, value);
};


/**
 * optional uint32 gtc_mode = 34;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getGtcMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setGtcMode = function(value) {
  return jspb.Message.setProto3IntField(this, 34, value);
};


/**
 * optional uint32 ie_check_mode = 35;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getIeCheckMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setIeCheckMode = function(value) {
  return jspb.Message.setProto3IntField(this, 35, value);
};


/**
 * optional uint32 ie_flags = 36;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getIeFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 36, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setIeFlags = function(value) {
  return jspb.Message.setProto3IntField(this, 36, value);
};


/**
 * optional uint32 ie_slip_losing = 37;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getIeSlipLosing = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setIeSlipLosing = function(value) {
  return jspb.Message.setProto3IntField(this, 37, value);
};


/**
 * optional uint32 ie_slip_profit = 38;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getIeSlipProfit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 38, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setIeSlipProfit = function(value) {
  return jspb.Message.setProto3IntField(this, 38, value);
};


/**
 * optional uint32 ie_timeout = 39;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getIeTimeout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 39, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setIeTimeout = function(value) {
  return jspb.Message.setProto3IntField(this, 39, value);
};


/**
 * optional uint64 ie_volume_max = 40;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getIeVolumeMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setIeVolumeMax = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * optional uint64 ie_volume_max_ext = 41;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getIeVolumeMaxExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 41, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setIeVolumeMaxExt = function(value) {
  return jspb.Message.setProto3IntField(this, 41, value);
};


/**
 * optional uint32 industry = 42;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getIndustry = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setIndustry = function(value) {
  return jspb.Message.setProto3IntField(this, 42, value);
};


/**
 * optional string international = 43;
 * @return {string}
 */
proto.symbol.SymbolDataNew.prototype.getInternational = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setInternational = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string isin = 44;
 * @return {string}
 */
proto.symbol.SymbolDataNew.prototype.getIsin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setIsin = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional uint32 margin_flags = 45;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getMarginFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 45, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setMarginFlags = function(value) {
  return jspb.Message.setProto3IntField(this, 45, value);
};


/**
 * optional double margin_hedged = 46;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getMarginHedged = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 46, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setMarginHedged = function(value) {
  return jspb.Message.setProto3FloatField(this, 46, value);
};


/**
 * optional double margin_initial = 47;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getMarginInitial = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 47, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setMarginInitial = function(value) {
  return jspb.Message.setProto3FloatField(this, 47, value);
};


/**
 * optional double margin_limit = 48;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getMarginLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 48, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setMarginLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 48, value);
};


/**
 * optional double margin_long = 49;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getMarginLong = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 49, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setMarginLong = function(value) {
  return jspb.Message.setProto3FloatField(this, 49, value);
};


/**
 * optional double margin_maintenance = 50;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getMarginMaintenance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 50, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setMarginMaintenance = function(value) {
  return jspb.Message.setProto3FloatField(this, 50, value);
};


/**
 * optional double margin_rate_currency = 51;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getMarginRateCurrency = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 51, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setMarginRateCurrency = function(value) {
  return jspb.Message.setProto3FloatField(this, 51, value);
};


/**
 * optional double margin_rate_liqudity = 52;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getMarginRateLiqudity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 52, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setMarginRateLiqudity = function(value) {
  return jspb.Message.setProto3FloatField(this, 52, value);
};


/**
 * optional double margin_short = 53;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getMarginShort = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 53, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setMarginShort = function(value) {
  return jspb.Message.setProto3FloatField(this, 53, value);
};


/**
 * optional double margin_stop = 54;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getMarginStop = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 54, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setMarginStop = function(value) {
  return jspb.Message.setProto3FloatField(this, 54, value);
};


/**
 * optional double margin_stop_limit = 55;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getMarginStopLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 55, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setMarginStopLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 55, value);
};


/**
 * optional double multiply = 56;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getMultiply = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 56, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setMultiply = function(value) {
  return jspb.Message.setProto3FloatField(this, 56, value);
};


/**
 * optional double open_price = 102;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getOpenPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 102, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setOpenPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 102, value);
};


/**
 * optional uint32 options_mode = 57;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getOptionsMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 57, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setOptionsMode = function(value) {
  return jspb.Message.setProto3IntField(this, 57, value);
};


/**
 * optional uint32 order_flags = 58;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getOrderFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 58, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setOrderFlags = function(value) {
  return jspb.Message.setProto3IntField(this, 58, value);
};


/**
 * optional string page = 59;
 * @return {string}
 */
proto.symbol.SymbolDataNew.prototype.getPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 59, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setPage = function(value) {
  return jspb.Message.setProto3StringField(this, 59, value);
};


/**
 * optional string path = 60;
 * @return {string}
 */
proto.symbol.SymbolDataNew.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional double point = 61;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getPoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 61, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setPoint = function(value) {
  return jspb.Message.setProto3FloatField(this, 61, value);
};


/**
 * optional double price_limit_max = 62;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getPriceLimitMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 62, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setPriceLimitMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 62, value);
};


/**
 * optional double price_limit_min = 63;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getPriceLimitMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 63, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setPriceLimitMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 63, value);
};


/**
 * optional double price_settle = 64;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getPriceSettle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 64, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setPriceSettle = function(value) {
  return jspb.Message.setProto3FloatField(this, 64, value);
};


/**
 * optional double price_strike = 65;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getPriceStrike = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 65, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setPriceStrike = function(value) {
  return jspb.Message.setProto3FloatField(this, 65, value);
};


/**
 * optional uint32 quotes_timeout = 66;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getQuotesTimeout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 66, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setQuotesTimeout = function(value) {
  return jspb.Message.setProto3IntField(this, 66, value);
};


/**
 * optional uint32 re_flags = 67;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getReFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 67, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setReFlags = function(value) {
  return jspb.Message.setProto3IntField(this, 67, value);
};


/**
 * optional uint32 re_timeout = 68;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getReTimeout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 68, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setReTimeout = function(value) {
  return jspb.Message.setProto3IntField(this, 68, value);
};


/**
 * optional uint32 sector = 69;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getSector = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 69, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setSector = function(value) {
  return jspb.Message.setProto3IntField(this, 69, value);
};


/**
 * optional string source = 70;
 * @return {string}
 */
proto.symbol.SymbolDataNew.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 70, value);
};


/**
 * optional uint32 splice_time_days = 71;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getSpliceTimeDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 71, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setSpliceTimeDays = function(value) {
  return jspb.Message.setProto3IntField(this, 71, value);
};


/**
 * optional uint32 splice_time_type = 72;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getSpliceTimeType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 72, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setSpliceTimeType = function(value) {
  return jspb.Message.setProto3IntField(this, 72, value);
};


/**
 * optional uint32 splice_type = 73;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getSpliceType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 73, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setSpliceType = function(value) {
  return jspb.Message.setProto3IntField(this, 73, value);
};


/**
 * optional uint32 spread = 74;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getSpread = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 74, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setSpread = function(value) {
  return jspb.Message.setProto3IntField(this, 74, value);
};


/**
 * optional int32 spread_balance = 75;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getSpreadBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 75, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setSpreadBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 75, value);
};


/**
 * optional int32 spread_diff = 76;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getSpreadDiff = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 76, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setSpreadDiff = function(value) {
  return jspb.Message.setProto3IntField(this, 76, value);
};


/**
 * optional int32 spread_diffBalance = 77;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getSpreadDiffbalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 77, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setSpreadDiffbalance = function(value) {
  return jspb.Message.setProto3IntField(this, 77, value);
};


/**
 * optional int32 stops_level = 78;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getStopsLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 78, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setStopsLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 78, value);
};


/**
 * optional uint32 subscriptions_delay = 79;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getSubscriptionsDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 79, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setSubscriptionsDelay = function(value) {
  return jspb.Message.setProto3IntField(this, 79, value);
};


/**
 * optional uint32 swap_3day = 80;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getSwap3day = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 80, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setSwap3day = function(value) {
  return jspb.Message.setProto3IntField(this, 80, value);
};


/**
 * optional double swap_long = 81;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getSwapLong = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 81, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setSwapLong = function(value) {
  return jspb.Message.setProto3FloatField(this, 81, value);
};


/**
 * optional uint32 swap_mode = 82;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getSwapMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 82, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setSwapMode = function(value) {
  return jspb.Message.setProto3IntField(this, 82, value);
};


/**
 * optional double swap_short = 83;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getSwapShort = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 83, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setSwapShort = function(value) {
  return jspb.Message.setProto3FloatField(this, 83, value);
};


/**
 * optional uint32 swap_year_days = 84;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getSwapYearDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 84, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setSwapYearDays = function(value) {
  return jspb.Message.setProto3IntField(this, 84, value);
};


/**
 * optional string symbol = 85;
 * @return {string}
 */
proto.symbol.SymbolDataNew.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 85, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 85, value);
};


/**
 * optional uint32 tick_book_depth = 86;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getTickBookDepth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 86, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setTickBookDepth = function(value) {
  return jspb.Message.setProto3IntField(this, 86, value);
};


/**
 * optional uint32 tick_flags = 87;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getTickFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 87, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setTickFlags = function(value) {
  return jspb.Message.setProto3IntField(this, 87, value);
};


/**
 * optional double tick_size = 88;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getTickSize = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 88, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setTickSize = function(value) {
  return jspb.Message.setProto3FloatField(this, 88, value);
};


/**
 * optional double tick_value = 89;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getTickValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 89, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setTickValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 89, value);
};


/**
 * optional int64 time_expiration = 90;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getTimeExpiration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 90, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setTimeExpiration = function(value) {
  return jspb.Message.setProto3IntField(this, 90, value);
};


/**
 * optional int64 time_start = 91;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getTimeStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 91, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setTimeStart = function(value) {
  return jspb.Message.setProto3IntField(this, 91, value);
};


/**
 * optional uint32 trade_flags = 92;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getTradeFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 92, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setTradeFlags = function(value) {
  return jspb.Message.setProto3IntField(this, 92, value);
};


/**
 * optional uint32 trade_mode = 93;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getTradeMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 93, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setTradeMode = function(value) {
  return jspb.Message.setProto3IntField(this, 93, value);
};


/**
 * optional uint64 volume_limit = 94;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getVolumeLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 94, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setVolumeLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 94, value);
};


/**
 * optional uint64 volume_limit_ext = 95;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getVolumeLimitExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 95, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setVolumeLimitExt = function(value) {
  return jspb.Message.setProto3IntField(this, 95, value);
};


/**
 * optional uint64 volume_max = 96;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getVolumeMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 96, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setVolumeMax = function(value) {
  return jspb.Message.setProto3IntField(this, 96, value);
};


/**
 * optional uint64 volume_max_ext = 97;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getVolumeMaxExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 97, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setVolumeMaxExt = function(value) {
  return jspb.Message.setProto3IntField(this, 97, value);
};


/**
 * optional uint64 volume_min = 98;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getVolumeMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 98, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setVolumeMin = function(value) {
  return jspb.Message.setProto3IntField(this, 98, value);
};


/**
 * optional uint64 volume_min_ext = 99;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getVolumeMinExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 99, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setVolumeMinExt = function(value) {
  return jspb.Message.setProto3IntField(this, 99, value);
};


/**
 * optional uint64 volume_step = 100;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getVolumeStep = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setVolumeStep = function(value) {
  return jspb.Message.setProto3IntField(this, 100, value);
};


/**
 * optional uint64 volume_step_ext = 101;
 * @return {number}
 */
proto.symbol.SymbolDataNew.prototype.getVolumeStepExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setVolumeStepExt = function(value) {
  return jspb.Message.setProto3IntField(this, 101, value);
};


/**
 * optional StatData stat = 103;
 * @return {?proto.symbol.StatData}
 */
proto.symbol.SymbolDataNew.prototype.getStat = function() {
  return /** @type{?proto.symbol.StatData} */ (
    jspb.Message.getWrapperField(this, proto.symbol.StatData, 103));
};


/**
 * @param {?proto.symbol.StatData|undefined} value
 * @return {!proto.symbol.SymbolDataNew} returns this
*/
proto.symbol.SymbolDataNew.prototype.setStat = function(value) {
  return jspb.Message.setWrapperField(this, 103, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.clearStat = function() {
  return this.setStat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.symbol.SymbolDataNew.prototype.hasStat = function() {
  return jspb.Message.getField(this, 103) != null;
};


/**
 * optional string logo = 104;
 * @return {string}
 */
proto.symbol.SymbolDataNew.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 104, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.SymbolDataNew} returns this
 */
proto.symbol.SymbolDataNew.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 104, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.symbol.TickData.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.symbol.TickData.prototype.toObject = function(opt_includeInstance) {
  return proto.symbol.TickData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.symbol.TickData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.TickData.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 10, ""),
    datetime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bid: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    ask: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    last: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    volume: jspb.Message.getFieldWithDefault(msg, 5, 0),
    datetimeMsc: jspb.Message.getFieldWithDefault(msg, 6, 0),
    flags: jspb.Message.getFieldWithDefault(msg, 7, 0),
    volumeExt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    reservedList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    stat: (f = msg.getStat()) && proto.symbol.StatData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.symbol.TickData}
 */
proto.symbol.TickData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.symbol.TickData;
  return proto.symbol.TickData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.symbol.TickData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.symbol.TickData}
 */
proto.symbol.TickData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDatetime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAsk(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLast(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolume(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDatetimeMsc(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFlags(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolumeExt(value);
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addReserved(values[i]);
      }
      break;
    case 11:
      var value = new proto.symbol.StatData;
      reader.readMessage(value,proto.symbol.StatData.deserializeBinaryFromReader);
      msg.setStat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.symbol.TickData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.symbol.TickData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.symbol.TickData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.TickData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDatetime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBid();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAsk();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLast();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getVolume();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getDatetimeMsc();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getFlags();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getVolumeExt();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getReservedList();
  if (f.length > 0) {
    writer.writePackedUint32(
      9,
      f
    );
  }
  f = message.getStat();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.symbol.StatData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string symbol = 10;
 * @return {string}
 */
proto.symbol.TickData.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.TickData} returns this
 */
proto.symbol.TickData.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 datetime = 1;
 * @return {number}
 */
proto.symbol.TickData.prototype.getDatetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.TickData} returns this
 */
proto.symbol.TickData.prototype.setDatetime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double bid = 2;
 * @return {number}
 */
proto.symbol.TickData.prototype.getBid = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.TickData} returns this
 */
proto.symbol.TickData.prototype.setBid = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double ask = 3;
 * @return {number}
 */
proto.symbol.TickData.prototype.getAsk = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.TickData} returns this
 */
proto.symbol.TickData.prototype.setAsk = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double last = 4;
 * @return {number}
 */
proto.symbol.TickData.prototype.getLast = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.TickData} returns this
 */
proto.symbol.TickData.prototype.setLast = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional uint64 volume = 5;
 * @return {number}
 */
proto.symbol.TickData.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.TickData} returns this
 */
proto.symbol.TickData.prototype.setVolume = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 datetime_msc = 6;
 * @return {number}
 */
proto.symbol.TickData.prototype.getDatetimeMsc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.TickData} returns this
 */
proto.symbol.TickData.prototype.setDatetimeMsc = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 flags = 7;
 * @return {number}
 */
proto.symbol.TickData.prototype.getFlags = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.TickData} returns this
 */
proto.symbol.TickData.prototype.setFlags = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 volume_ext = 8;
 * @return {number}
 */
proto.symbol.TickData.prototype.getVolumeExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.TickData} returns this
 */
proto.symbol.TickData.prototype.setVolumeExt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated uint32 reserved = 9;
 * @return {!Array<number>}
 */
proto.symbol.TickData.prototype.getReservedList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.symbol.TickData} returns this
 */
proto.symbol.TickData.prototype.setReservedList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.symbol.TickData} returns this
 */
proto.symbol.TickData.prototype.addReserved = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.symbol.TickData} returns this
 */
proto.symbol.TickData.prototype.clearReservedList = function() {
  return this.setReservedList([]);
};


/**
 * optional StatData stat = 11;
 * @return {?proto.symbol.StatData}
 */
proto.symbol.TickData.prototype.getStat = function() {
  return /** @type{?proto.symbol.StatData} */ (
    jspb.Message.getWrapperField(this, proto.symbol.StatData, 11));
};


/**
 * @param {?proto.symbol.StatData|undefined} value
 * @return {!proto.symbol.TickData} returns this
*/
proto.symbol.TickData.prototype.setStat = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.symbol.TickData} returns this
 */
proto.symbol.TickData.prototype.clearStat = function() {
  return this.setStat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.symbol.TickData.prototype.hasStat = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.symbol.StatData.prototype.toObject = function(opt_includeInstance) {
  return proto.symbol.StatData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.symbol.StatData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.StatData.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    datetime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bidHigh: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    bidLow: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    askHigh: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    askLow: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    lastHigh: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    lastLow: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    volHigh: jspb.Message.getFieldWithDefault(msg, 9, 0),
    volLow: jspb.Message.getFieldWithDefault(msg, 10, 0),
    tradeDeals: jspb.Message.getFieldWithDefault(msg, 11, 0),
    tradeVolume: jspb.Message.getFieldWithDefault(msg, 12, 0),
    tradeTurnover: jspb.Message.getFieldWithDefault(msg, 13, 0),
    tradeInterest: jspb.Message.getFieldWithDefault(msg, 14, 0),
    tradeBuyOrders: jspb.Message.getFieldWithDefault(msg, 15, 0),
    tradeBuyVolume: jspb.Message.getFieldWithDefault(msg, 16, 0),
    tradeSellOrders: jspb.Message.getFieldWithDefault(msg, 17, 0),
    tradeSellVolume: jspb.Message.getFieldWithDefault(msg, 18, 0),
    tradeVolumeExt: jspb.Message.getFieldWithDefault(msg, 19, 0),
    tradeBuyVolumeExt: jspb.Message.getFieldWithDefault(msg, 20, 0),
    tradeSellVolumeExt: jspb.Message.getFieldWithDefault(msg, 21, 0),
    volHighExt: jspb.Message.getFieldWithDefault(msg, 22, 0),
    volLowExt: jspb.Message.getFieldWithDefault(msg, 23, 0),
    tradeReserved: jspb.Message.getFieldWithDefault(msg, 24, 0),
    datetimeMsc: jspb.Message.getFieldWithDefault(msg, 25, 0),
    priceOpen: jspb.Message.getFloatingPointFieldWithDefault(msg, 26, 0.0),
    priceClose: jspb.Message.getFloatingPointFieldWithDefault(msg, 27, 0.0),
    priceAw: jspb.Message.getFloatingPointFieldWithDefault(msg, 28, 0.0),
    priceObsolete: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
    priceVolatility: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    priceTheortical: jspb.Message.getFloatingPointFieldWithDefault(msg, 31, 0.0),
    priceGreeksDelta: jspb.Message.getFloatingPointFieldWithDefault(msg, 32, 0.0),
    priceGreeksTheta: jspb.Message.getFloatingPointFieldWithDefault(msg, 33, 0.0),
    priceGreeksGamma: jspb.Message.getFloatingPointFieldWithDefault(msg, 34, 0.0),
    priceGreeksVega: jspb.Message.getFloatingPointFieldWithDefault(msg, 35, 0.0),
    priceGreeksRho: jspb.Message.getFloatingPointFieldWithDefault(msg, 36, 0.0),
    priceGreeksOmega: jspb.Message.getFloatingPointFieldWithDefault(msg, 37, 0.0),
    priceSensitivity: jspb.Message.getFloatingPointFieldWithDefault(msg, 38, 0.0),
    priceReserved: jspb.Message.getFieldWithDefault(msg, 39, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.symbol.StatData}
 */
proto.symbol.StatData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.symbol.StatData;
  return proto.symbol.StatData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.symbol.StatData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.symbol.StatData}
 */
proto.symbol.StatData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDatetime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBidHigh(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBidLow(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAskHigh(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAskLow(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastHigh(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastLow(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolHigh(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolLow(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTradeDeals(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTradeVolume(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTradeTurnover(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTradeInterest(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTradeBuyOrders(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTradeBuyVolume(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTradeSellOrders(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTradeSellVolume(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTradeVolumeExt(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTradeBuyVolumeExt(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTradeSellVolumeExt(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolHighExt(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVolLowExt(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTradeReserved(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDatetimeMsc(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceOpen(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceClose(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceAw(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceObsolete(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceVolatility(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceTheortical(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceGreeksDelta(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceGreeksTheta(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceGreeksGamma(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceGreeksVega(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceGreeksRho(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceGreeksOmega(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceSensitivity(value);
      break;
    case 39:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceReserved(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.symbol.StatData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.symbol.StatData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.symbol.StatData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.symbol.StatData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDatetime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBidHigh();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getBidLow();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getAskHigh();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getAskLow();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getLastHigh();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getLastLow();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getVolHigh();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getVolLow();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getTradeDeals();
  if (f !== 0) {
    writer.writeUint64(
      11,
      f
    );
  }
  f = message.getTradeVolume();
  if (f !== 0) {
    writer.writeUint64(
      12,
      f
    );
  }
  f = message.getTradeTurnover();
  if (f !== 0) {
    writer.writeUint64(
      13,
      f
    );
  }
  f = message.getTradeInterest();
  if (f !== 0) {
    writer.writeUint64(
      14,
      f
    );
  }
  f = message.getTradeBuyOrders();
  if (f !== 0) {
    writer.writeUint64(
      15,
      f
    );
  }
  f = message.getTradeBuyVolume();
  if (f !== 0) {
    writer.writeUint64(
      16,
      f
    );
  }
  f = message.getTradeSellOrders();
  if (f !== 0) {
    writer.writeUint64(
      17,
      f
    );
  }
  f = message.getTradeSellVolume();
  if (f !== 0) {
    writer.writeUint64(
      18,
      f
    );
  }
  f = message.getTradeVolumeExt();
  if (f !== 0) {
    writer.writeUint64(
      19,
      f
    );
  }
  f = message.getTradeBuyVolumeExt();
  if (f !== 0) {
    writer.writeUint64(
      20,
      f
    );
  }
  f = message.getTradeSellVolumeExt();
  if (f !== 0) {
    writer.writeUint64(
      21,
      f
    );
  }
  f = message.getVolHighExt();
  if (f !== 0) {
    writer.writeUint64(
      22,
      f
    );
  }
  f = message.getVolLowExt();
  if (f !== 0) {
    writer.writeUint64(
      23,
      f
    );
  }
  f = message.getTradeReserved();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getDatetimeMsc();
  if (f !== 0) {
    writer.writeInt64(
      25,
      f
    );
  }
  f = message.getPriceOpen();
  if (f !== 0.0) {
    writer.writeDouble(
      26,
      f
    );
  }
  f = message.getPriceClose();
  if (f !== 0.0) {
    writer.writeDouble(
      27,
      f
    );
  }
  f = message.getPriceAw();
  if (f !== 0.0) {
    writer.writeDouble(
      28,
      f
    );
  }
  f = message.getPriceObsolete();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
  f = message.getPriceVolatility();
  if (f !== 0.0) {
    writer.writeDouble(
      30,
      f
    );
  }
  f = message.getPriceTheortical();
  if (f !== 0.0) {
    writer.writeDouble(
      31,
      f
    );
  }
  f = message.getPriceGreeksDelta();
  if (f !== 0.0) {
    writer.writeDouble(
      32,
      f
    );
  }
  f = message.getPriceGreeksTheta();
  if (f !== 0.0) {
    writer.writeDouble(
      33,
      f
    );
  }
  f = message.getPriceGreeksGamma();
  if (f !== 0.0) {
    writer.writeDouble(
      34,
      f
    );
  }
  f = message.getPriceGreeksVega();
  if (f !== 0.0) {
    writer.writeDouble(
      35,
      f
    );
  }
  f = message.getPriceGreeksRho();
  if (f !== 0.0) {
    writer.writeDouble(
      36,
      f
    );
  }
  f = message.getPriceGreeksOmega();
  if (f !== 0.0) {
    writer.writeDouble(
      37,
      f
    );
  }
  f = message.getPriceSensitivity();
  if (f !== 0.0) {
    writer.writeDouble(
      38,
      f
    );
  }
  f = message.getPriceReserved();
  if (f !== 0) {
    writer.writeInt32(
      39,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.symbol.StatData.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 datetime = 2;
 * @return {number}
 */
proto.symbol.StatData.prototype.getDatetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setDatetime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double bid_high = 3;
 * @return {number}
 */
proto.symbol.StatData.prototype.getBidHigh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setBidHigh = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double bid_low = 4;
 * @return {number}
 */
proto.symbol.StatData.prototype.getBidLow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setBidLow = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double ask_high = 5;
 * @return {number}
 */
proto.symbol.StatData.prototype.getAskHigh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setAskHigh = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double ask_low = 6;
 * @return {number}
 */
proto.symbol.StatData.prototype.getAskLow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setAskLow = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double last_high = 7;
 * @return {number}
 */
proto.symbol.StatData.prototype.getLastHigh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setLastHigh = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double last_low = 8;
 * @return {number}
 */
proto.symbol.StatData.prototype.getLastLow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setLastLow = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional uint64 vol_high = 9;
 * @return {number}
 */
proto.symbol.StatData.prototype.getVolHigh = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setVolHigh = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint64 vol_low = 10;
 * @return {number}
 */
proto.symbol.StatData.prototype.getVolLow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setVolLow = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint64 trade_deals = 11;
 * @return {number}
 */
proto.symbol.StatData.prototype.getTradeDeals = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setTradeDeals = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint64 trade_volume = 12;
 * @return {number}
 */
proto.symbol.StatData.prototype.getTradeVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setTradeVolume = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint64 trade_turnover = 13;
 * @return {number}
 */
proto.symbol.StatData.prototype.getTradeTurnover = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setTradeTurnover = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint64 trade_interest = 14;
 * @return {number}
 */
proto.symbol.StatData.prototype.getTradeInterest = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setTradeInterest = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint64 trade_buy_orders = 15;
 * @return {number}
 */
proto.symbol.StatData.prototype.getTradeBuyOrders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setTradeBuyOrders = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint64 trade_buy_volume = 16;
 * @return {number}
 */
proto.symbol.StatData.prototype.getTradeBuyVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setTradeBuyVolume = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint64 trade_sell_orders = 17;
 * @return {number}
 */
proto.symbol.StatData.prototype.getTradeSellOrders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setTradeSellOrders = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional uint64 trade_sell_volume = 18;
 * @return {number}
 */
proto.symbol.StatData.prototype.getTradeSellVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setTradeSellVolume = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional uint64 trade_volume_ext = 19;
 * @return {number}
 */
proto.symbol.StatData.prototype.getTradeVolumeExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setTradeVolumeExt = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional uint64 trade_buy_volume_ext = 20;
 * @return {number}
 */
proto.symbol.StatData.prototype.getTradeBuyVolumeExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setTradeBuyVolumeExt = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional uint64 trade_sell_volume_ext = 21;
 * @return {number}
 */
proto.symbol.StatData.prototype.getTradeSellVolumeExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setTradeSellVolumeExt = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional uint64 vol_high_ext = 22;
 * @return {number}
 */
proto.symbol.StatData.prototype.getVolHighExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setVolHighExt = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional uint64 vol_low_ext = 23;
 * @return {number}
 */
proto.symbol.StatData.prototype.getVolLowExt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setVolLowExt = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 trade_reserved = 24;
 * @return {number}
 */
proto.symbol.StatData.prototype.getTradeReserved = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setTradeReserved = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int64 datetime_msc = 25;
 * @return {number}
 */
proto.symbol.StatData.prototype.getDatetimeMsc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setDatetimeMsc = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional double price_open = 26;
 * @return {number}
 */
proto.symbol.StatData.prototype.getPriceOpen = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 26, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setPriceOpen = function(value) {
  return jspb.Message.setProto3FloatField(this, 26, value);
};


/**
 * optional double price_close = 27;
 * @return {number}
 */
proto.symbol.StatData.prototype.getPriceClose = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 27, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setPriceClose = function(value) {
  return jspb.Message.setProto3FloatField(this, 27, value);
};


/**
 * optional double price_aw = 28;
 * @return {number}
 */
proto.symbol.StatData.prototype.getPriceAw = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 28, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setPriceAw = function(value) {
  return jspb.Message.setProto3FloatField(this, 28, value);
};


/**
 * optional double price_obsolete = 29;
 * @return {number}
 */
proto.symbol.StatData.prototype.getPriceObsolete = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setPriceObsolete = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional double price_volatility = 30;
 * @return {number}
 */
proto.symbol.StatData.prototype.getPriceVolatility = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setPriceVolatility = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional double price_theortical = 31;
 * @return {number}
 */
proto.symbol.StatData.prototype.getPriceTheortical = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 31, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setPriceTheortical = function(value) {
  return jspb.Message.setProto3FloatField(this, 31, value);
};


/**
 * optional double price_greeks_delta = 32;
 * @return {number}
 */
proto.symbol.StatData.prototype.getPriceGreeksDelta = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 32, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setPriceGreeksDelta = function(value) {
  return jspb.Message.setProto3FloatField(this, 32, value);
};


/**
 * optional double price_greeks_theta = 33;
 * @return {number}
 */
proto.symbol.StatData.prototype.getPriceGreeksTheta = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 33, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setPriceGreeksTheta = function(value) {
  return jspb.Message.setProto3FloatField(this, 33, value);
};


/**
 * optional double price_greeks_gamma = 34;
 * @return {number}
 */
proto.symbol.StatData.prototype.getPriceGreeksGamma = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 34, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setPriceGreeksGamma = function(value) {
  return jspb.Message.setProto3FloatField(this, 34, value);
};


/**
 * optional double price_greeks_vega = 35;
 * @return {number}
 */
proto.symbol.StatData.prototype.getPriceGreeksVega = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 35, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setPriceGreeksVega = function(value) {
  return jspb.Message.setProto3FloatField(this, 35, value);
};


/**
 * optional double price_greeks_rho = 36;
 * @return {number}
 */
proto.symbol.StatData.prototype.getPriceGreeksRho = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 36, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setPriceGreeksRho = function(value) {
  return jspb.Message.setProto3FloatField(this, 36, value);
};


/**
 * optional double price_greeks_omega = 37;
 * @return {number}
 */
proto.symbol.StatData.prototype.getPriceGreeksOmega = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 37, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setPriceGreeksOmega = function(value) {
  return jspb.Message.setProto3FloatField(this, 37, value);
};


/**
 * optional double price_sensitivity = 38;
 * @return {number}
 */
proto.symbol.StatData.prototype.getPriceSensitivity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 38, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setPriceSensitivity = function(value) {
  return jspb.Message.setProto3FloatField(this, 38, value);
};


/**
 * optional int32 price_reserved = 39;
 * @return {number}
 */
proto.symbol.StatData.prototype.getPriceReserved = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 39, 0));
};


/**
 * @param {number} value
 * @return {!proto.symbol.StatData} returns this
 */
proto.symbol.StatData.prototype.setPriceReserved = function(value) {
  return jspb.Message.setProto3IntField(this, 39, value);
};


goog.object.extend(exports, proto.symbol);
