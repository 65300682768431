import React, { memo, useContext, useEffect, useState } from 'react';
import Spread from './spread';
import { useAppDispatch } from '../../redux/app';
import useAuth from '../../hooks/useAuth';
import { setMenuSymbol, setSymbolActive } from '../../redux/reducer/instruments';
import { setDropdown } from '../../redux/reducer/dropdown';
import emitter from '../../utilities/emitter';
import { DropDownContext } from './provider';

const List = ({ index, item, active }) => {
  // const { symbolObj, category } = useContext(DropDownContext);
  const menu = localStorage.getItem('symbol_menu_list');
  const { activeAccount } = useAuth();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [tickData, setTickData] = useState({});

  const newWindowsOpenSymbol = (e) => {
    if (JSON.parse(menu)[activeAccount]?.menu?.find((item) => item.symbol === e.symbol)) {
      dispatch(setSymbolActive(e.symbol));
    } else {
      const obj = { symbol: e.symbol, logo: e.logo };
      dispatch(setMenuSymbol({ type: 'new', data: obj }));
      dispatch(setSymbolActive(e.symbol));
    }
    dispatch(setDropdown(false));
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(active);
    }, 200);
  }, [active]);


  return (
    <div
      id={`items_transition`}
      key={index}
      onClick={() => {
        newWindowsOpenSymbol(item);
      }}
      style={{
        opacity: loading ? 1 : 0,
        transformOrigin: 'top',
        transform: loading
          ? 'perspective(75em) rotateX(360deg)'
          : 'perspective(26em) rotateX(300deg)',
        transition: loading ? `transform ${100 * index}ms ease-in-out` : 'none'
      }}
      className="dropdow-item flex items-center dropdown-item">
      <div className="flex w-100">
        <div className="flex flex-[0.65] items-center">
          <div>
            <img
              width="35px"
              height="35px"
              src={`https://storage.googleapis.com/iux-storage/symbol/${item.symbol}.png`}
              onError={({ currentTarget }) => {
                currentTarget.src = 'globe.svg';
              }}
              className="rounded-[50%]"
              alt="..."
            />
          </div>
          <div style={{ marginLeft: 25 }}>
            <div className="font-[600] text-[13px]">{item.symbol}</div>
            <div className="text-[10px] font-[400] text-[#c2c4d1] line-clamp-1">
              {item.company_name}
            </div>
          </div>
        </div>
        <Spread item={{ symbol: item.symbol, digit: item.digit }} />
      </div>
    </div>
  );
};

export default List;
